
export default {
    demo: {
        demo: 'demo',
    },
        COMMON: {
            EMAIL: "Correo electrónico",
            BATCH_SENDER: "Envío por lotes",
            YES: "Sí",
            NO: "No",
            HAS: "Sí",
            HAVENT: "No",
            BLOCKCHAIN: "Blockchain",
            MULTIPLE_CHOICE: "(Opción múltiple)",
            IS_SUPPORT_TP_CONNECT: "¿Admite la conexión de TokenPocket? (Extensión y aplicación móvil)",
            SUPPORT_BOTH: "Se aceptan ambos",
            SUPPORT_EXTENSION: "Sólo la extensión",
            SUPPORT_MOBILE: "Sólo móvil",
            SUPPORT_NONE: "Ninguno",
            blockchainWallet: "Billetera Blockchain",
            iostWallet: "Billetera IOST",
            tronWallet: "Billetera TRON",
            platformInfo: {
                eos: "EOS",
                moac: "MOAC",
                eth: "ETH",
                jt: "Jingtum ",
                enu: "ENU",
                bos: "BOS",
                iost: "IOST",
                cosmos: "COSMOS",
                bnb: "BNB",
                trx: "TRON",
                bsc: "BSC",
                heco: "HECO",
                okex: "OKExChain",
                dot: "Polkadot",
                hsc: "HSC",
                matic: "Polygon",
                klaytn: "Klaytn",
                arb: "Arbitrum One",
                arbn: "Arbirtum Nova",
                ftm: "Fantom",
                etc: "Ethereum Classic",
                cfx: "Conflux eSpace",
                solana: "Solana",
                avax: "AVAX",
                oe: "Optimistic Ethereum",
                xdai: "Gnosis Chain",
                harmony: "Harmony",
                wax: "WAX",
                aurora: "Aurora",
                ksm: "Kusama",
                mb: "Moonbeam",
                sbg: "SubGame",
                kcc: "KCC Mainnet",
                lat: "PlatON",
                bttc: "BTTC",
                gt: "GateChain",
                halo: "HALO",
                aptos: "Aptos"
            },
            submit: "Envía",
            symbol: "Nombre del Token",
            bl_symbol: "Símbolo BL",
            precision: "Precision del Token",
            decimal: "Token Decimal",
            totalSupply: "Emisión total",
            contract: "Contrato del Token",
            website: "Sitio web oficial",
            introduction: "Presentación del token",
            example: "Ejemplo",
            submitTokenInfoAndLogo: "Actualiza el logotipo del token y otra información",
            toGithubSubmit: "Ve a Github",
            nftType: "Estándar de protocolo del NFT",
            LAYOUT: {
                features: "Características",
                buyCrypto: "Compra cripto",
                mobileWallet: "Billetera móvil",
                hardwareWallet: "Billetera de hardware",
                extensionWallet: "Extensión de la Billetera",
                desktop: "Cartera de escritorio",
                fiveDegrees: "5Degrees",
                approvalDetector: "Detector de aprobación",
                tokenSecurity: "Seguridad de Token",
                keyGenerator: "Generador de Claves",
                information: "Información",
                blockchainGuide: "Guía de cadena de bloques",
                tronWallet: "Guía de TRON",
                iostWallet: "Guía de IOST",
                tpMan: "TP Man",
                developers: "Desarrolladores",
                github: "Github (TP-Lab)",
                devCenter: "Centro de Desarrollo",
                subToken: "Enviar token",
                subDApp: "Enviar DApp",
                subNFT: "Enviar NFT",
                subChain: "Cadena",
                company: "Compañía",
                about: "Acerca de",
                careers: "Empleos",
                pressKit: "Kit de Prensa",
                swagShop: "Tienda de artículos de regalo",
                support: "Soporte",
                helpCenter: "Centro de Asistencia",
                contactUs: "Contáctanos",
                legal: "Legal",
                privacyPolicy: "Política de Privacidad",
                terms: "Términos de Uso",
                toHome: "Página Principal",
                defiWallet: "Billetera DeFi",
                ETHWallet: "Ethereum na Wallet",
                ethWallet: "Billetera ETH"
            }
        },
        HOME: {
            download: "Descargar",
            downloadNow: "Descarga ahora",
            HEADER: {
                title: "Su billetera cripto segura para explorar la blockchain",
                desc_1: "Fácil y segura para comprar, almacenar, enviar, intercambiar tokens y recolectar NFT. Con la confianza de más de 20 millones de usuarios de más de 200 países y regiones."
            },
            INTRODUCTION: {
                title: "Los usuarios globales confían en TokenPocket",
                desc_1: "Brindamos un servicio de billetera cripto, fácil de usar entre más de 200 países y regiones de todo el mundo",
                desc_2: "Atención al usuario",
                desc_3: "Transacciones Diarias",
                desc_4: "Países y regiones que apoyan"
            },
            SECURITY: {
                title: "Seguridad como debe ser",
                desc_1: "TokenPocket genera y almacena claves y contraseñas solo en su dispositivo, solo usted puede acceder a su cuenta y activos.",
                desc_2: "TokenPocket también desarrolla una billetera hardware y una función de billetera de múltiples firmas, para mejorar la seguridad según lo necesite.",
                desc_3: "Compatible con BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, Cosmos, Polkadot, EOS, IOST, etc."
            },
            EXCHANGE: {
                title: "Intercambia y realiza transacciones fácilmente",
                desc_1: "Puede intercambiar tus criptos en cualquier momento y en cualquier lugar dentro de TokenPocket.",
                desc_2: "Compre criptomonedas con tarjetas de crédito. Almacene, envíe, intercambie entre diferentes cadenas con facilidad.",
                desc_3: "Swap",
                desc_4: "Al instante y fácilmente",
                desc_5: "Bridge",
                desc_6: "Entre diferentes cadenas",
                desc_7: "Compra cripto",
                desc_8: "En 5 minutos"
            },
            DAPPSTORE: {
                title: "Una tienda DApp",
                desc_1: "Puede encontrar sus aplicaciones descentralizadas favoritas, descubrir las más populares y recientes y como si fuera poco sin salir de la billetera.",
                desc_2: "Navegador DApp integrado, siempre puede acceder a las DApps con sus enlaces.",
                desc_3: "DeFi",
                desc_4: "NFT",
                desc_5: "DApp",
                desc_6: "Soportado"
            },
            COMMUNITY: {
                title: "Comunidad",
                desc_1: "Insistimos en una comunidad de tecnología abierta (Open Source), y damos la bienvenida a todos los desarrolladores para que construyamos entre todos un mundo blockchain más conveniente, seguro y próspero",
                desc_2: "TP-Lab",
                desc_3: "Comunidad",
                desc_4: "Dev Docs"
            },
            DOWNLOAD: {
                title: "¡Obten la billetera TokenPocket ahora!",
                desc_1: "Su billetera cripto segura y confiable para explorar la blockchain"
            },
            FOLLOW: {
                title: "Síganos",
                desc1: "TokenPocket Staffs no te enviará mensajes privados!",
                desc2: "¡Precaución! Está ingresando a la comunidad TokenPocket, ¡podría haber alguien haciéndose pasar por nosotros para enviarle mensajes privados! ¡Tenga en cuenta que cualquier persona que envíe mensajes privados podría ser un estafador! ¡Nunca te contactaremos primero!",
                desc3: "Entendido, entra"
            },
            EXTENSIONMODAL: {
                title: "¡La extensión ya está disponible!",
                desc1: "Tus Cripto y DeFi y GameFi",
                desc2: "billetera en la computadora",
                btnText: "Úsalo ahora",
                btnTextm: "Copiar el enlace",
                tips: "Copie el enlace, vaya a la computadora para abrir"
            }
        },
        DAPP: {
            RULES: {
                platform: "Se requiere plataforma",
                title: "Se requiere el nombre de la DApp",
                address: "Se requiere contrato de la DApp",
                url: "Se requiere el sitio web de la DApp",
                desc: "Se requiere Desc+",
                icon_url: "Se requiere el logotipo de la DApp",
                rakeBackAccount: "Se requiere cuenta del contrato",
                email: "Se requiere de un correo electrónico",
                others: "Se requiere otra información de contacto",
                tp_connect: "Este elemento no puede estar en vacío"
            },
            title: "Nombre de la DApp",
            address: "Contrato inteligente",
            url: "Sitio web de la DApp",
            desc: "Breve descripción",
            icon: "Logotipo de la DApp (debe ser de 200 x 200; admite JPG, PNG).",
            referral: "Referidol",
            hasReferral: "Tiene un programa de referidos?",
            referralReward: "Distribución de la recompensa por referidos",
            reward_1: "Distribuir automáticamente por el contrato inteligente (Live)",
            reward_2: "Necesita reclamarlo en la DApp manualmente",
            hasInviteReward: "¿El remitente necesita hacer una transacción en la DApp para activar el enlace de referencia?",
            inviteAccount: "El contrato inteligente de distribución por referidos",
            DAppRequirement: "Requisito de la DApp",
            requirement_1: "1. La DApp debe ser compatible tanto con la versión móvil como la extensión de TokenPocket.",
            requirement_2: "2. El sitio web proporcionado es accesible y estable.",
            requirement_3: "3. Los contratos inteligentes se implementaron en la red principal y la parte sensible debe ser de código abierto.",
            requirement_4: "4. Los contratos sensibles requieren informes de auditoría por parte de agencias de seguridad certificadas por terceros.",
            requirement_5: "5. La lógica de interacción es clara y se ha adaptado a la interfaz de usuario móvil.",
            requirement_6: "6. Obedecer las leyes y reglamentos pertinentes, sin hechos de fraude ni infracción.",
            requirement_7: "7. Si viola las leyes y regulaciones pertinentes, asumirá voluntariamente las responsabilidades legales correspondientes.",
            dappInfo: "Información de la aplicación DApp:",
            necessary: "requerido",
            language: "Idioma de la DApp",
            languageDesc: "(Envíe por separado para varios idiomas)",
            en: "English",
            zh: "中文简体",
            zhTW: "中文繁体",
            descDesc: "Una breve descripción del proyecto en una frase, que aparecerá en el subtítulo de la DApp",
            auditInfo: "Información de auditoría:",
            hasAudit: "Si se ha realizado la auditoría del contrato.",
            auditUrl: "URL del informe de auditoría",
            auditUrlExample: "Por ejemplo: https://auditlink.com",
            auditReport: "Informe de auditoría",
            auditUpload: "Subir",
            contact: "Detalles de contacto",
            contactDesc: "Asegúrese de dejar la información de contacto del Servicio de Atención al Cliente que no sea el buzón, de lo contrario no pasará la revisión.",
            emailAddr: "Correo electrónico",
            emailExample: "Por ejemplo: servicio@tudominio.com",
            others: "Otro",
            othersExample: "Por ejemplo: Telegram: @123456789",
            auditOptional: "Complete al menos un elemento de la información de auditoría",
            oversize: " ha excedido los caracteres permitidos",
            select: "Seleccione",
            tutorial_url: "https://help.tokenpocket.pro/developer-en/",
            tutorial: "Ver tutorial>>"
        },
        DOWNLOAD: {
            TITLE: "Descargar TokenPocket",
            TITLE_Android: "TokenPocket para Android",
            TITLE_IOS: "TokenPocket para iOS",
            TITLE_Chrome: "TokenPocket para tu navegador",
            TEXT: "TokenPocket es una billetera criptográfica multi-cadena, fácil y segura de usar en la que confían millones de personas.",
            TEXT_Chrome: "TokenPocket Extension es una billetera criptográfica multi-cadena, es compatible con todas las cadenas EVM. Fácil y segura de usar en el que confían millones de personas.",
            TEXT_PC: "TokenPocket Desktop es la billetera multi-blockchain más grande basada en BTC ETH EOS ETH BOS TRON , nos esforzamos por brindarle a los usuarios una gestión, sólida y segura de sus activos digitales ",
            scanCode: "Escanear para descargar",
            installTutorial: "Tutorial de instalación",
            desc_1: "Descarga la aplicación desde el sitio web oficial y verifique su certificación SSL",
            desc_2: "Proteja su Frase de Recuperación (mnemotécnica) y Clave Privada de robos, nunca las comparta con otros",
            desc_3: "Más información sobre consejos de seguridad",
            verifyText: "Última versión de APK:",
            verifyText1: "Cómo verificar la seguridad de una aplicación",
            verifyText2: "Última versión:",
            verifyText3: "Última versión de Google Play:",
            footerDesc_1: "Compatible con BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST, etc.",
            footerDesc_2: "Protecciones de seguridad multicapa",
            footerDesc_3: "Compatible con DeFi, DApp, GameFi y NFT",
            coming_soon: "Coming Soon",
            desc_tp_wallet: "TP Global Ltd ha sido designado oficialmente como el único editor de aplicaciones para iOS de TokenPocket",
            tp_wallet_version: "TP Wallet Versión:",
            token_pocket_version: "Token Pocket Versión:",
            delisted: "Excluido"
        },
        TOKEN: {
            RULES: {
                email: "Se requiere de un correo electrónico",
                address: "Se requiere de una dirección válida",
                owner_address: "Se requiere la dirección del propietario",
                symbol: "Se requiere del símbolo",
                bl_symbol: "Se requiere el símbolo BL",
                total_supply: "Se requiere de la emisión total",
                decimal: "Se requiere el Decimal",
                precision: "Se requiere de la Precisión",
                gas: "Se requiere GAS",
                website: "Se requiere sitio web",
                companyName: "Se requiere el nombre de la empresa o del responsable",
                contact: "Se requiere Información de Contacto",
                name: "Se requiere presentación del proyecto",
                icon_url: "Se requiere del logotipo"
            },
            icon: "Logotipo de token (200 * 200 píxeles, compatible con jpg, jpeg, png, formato de nombre de archivo de imagen: nombre de token, el logotipo no necesita esquinas redondeadas)",
            handleText: "Procesaremos su solicitud en 2 días hábiles"
        },
        RECRUITING: {
            title: " Programa de Reclutamiento de TP Man",
            text: "Únase a la comunidad de TokenPocket",
            text1: "Intégrate en el mundo blockchain y aporta tu grano de arena",
            text2: "Construimos juntos un mundo Web3.0",
            joinUs: "Únete a nosotros",
            aboutTitle: "Acerca de TP Man",
            aboutText: "TP Man es una parte importante de la comunidad TokenPocket, e invitamos cordialmente a unirse a nosotros!",
            aboutText1: "Eres un entusiasta de blockchain y respaldas el valor de la industria.",
            aboutText2: "Disfrute de la comodidad que le brinda la billetera TokenPocket cuando explore el mundo de la cadena de bloques.",
            missionTitle: "La Misión del TP Man",
            missionText: "Contribuya junto a TokenPocket a proporcionar a más usuarios, los servicios de la blockchain en todo el mundo. Esperamos que cumpla con dos de los siguientes requisitos para aplicar.",
            missionText1: "Expandir y promover la cooperación de TokenPocket con empresas o proyectos tendencia en su país, a través de varios canales",
            missionText2: "Planificar actividades de marketing que satisfagan las necesidades de los usuarios locales.",
            missionText3: "Tener la capacidad de manejar las principales redes sociales como Twitter, Youtube, Telegram y Discord",
            missionText4: "Fluidez en inglés y ser capaz de completar el trabajo de traducción.",
            missionText5: "TokenPocket planea brindar más usos y soporte técnico para los usuarios globales de la blockchain, por lo que esperamos que tenga una cierta comprensión de los mercados de blockchain de no menos de un país y así como también de sus usuarios (India, Estados Unidos, Turquía, Rusia, Corea del Sur, Vietnam, Filipinas, etc.)",
            getTitle: "¿Qué obtendrás?",
            getText: "Una experiencia laboral directamente relacionada con varios campos de la industria Blockchain, y obtendrá oportunidades de interacción con proyectos DApp, Influencers y los principales medios de comunicación de la industria.",
            getText1: "Obtén jugosas recompensas de acuerdo a su trabajo, como la traducción de tweets, la creación de videos, administración de los canales de información y cooperación empresarial.",
            getText2: "Obtén la mejor capacitación profesional en temas de blockchain y explore junto al equipo, el mundo Web3.0.",
            getText3: "Beneficios oficiales de TokenPocket, incluidos material de regalo de TokenPocket y billeteras de hardware.",
            processTitle: "Proceso de reclutamiento",
            processText: "Enviar CV",
            processText1: "Selección rigurosa de CV",
            processText2: "Entrevista en línea",
            processText3: "Resultados de la entrevista",
            processText4: "Bienvenido a bordo",
            applyTitle: "¿Quién puede aplicar?",
            applyText: "Enfréntate al mundo, sin importar el país",
            applyText1: "Ser entusiasta y curioso sobre el mundo de la cadena de bloques",
            applyText2: "Rellena el formulario y adjunta tu currículum, luego nos pondremos en contacto contigo lo antes posible",
            footerTitle: "Acerca de TokenPocket",
            footerText: "sobre",
            footerText1_1: "20M",
            footerText1_2: "usuarios globales",
            footerText2_1: "3.5M",
            footerText2_2: "usuarios activos mensuales",
            footerText3_1: "200",
            footerText3_2: "países y regiones",
            footerText4: "TokenPocket es la billetera auto custodiada y multi-cadenas líder en el mundo",
            footerText5: "Próximamente"
        },
        NAVIGATION: {
            product: {
                title: "Producto",
                selfCustodyWallet: "Billetera Movil",
                selfCustodyWalletDesc: "Billetera móvil Crypto & DeFi en Blockchain.",
                hardwareWallet: "Billetera de hardware",
                hardwareWalletDesc: "Obten tu KeyPal, para proteger tus activos.",
                extensionWallet: "Billetera de Extensión",
                extensionWalletDesc: "Una mejor billetera en su computadora.",
                transit: "Transit",
                transitDesc: "Agregador DEX multicadena y plataforma de mercado para el intercambio de NFT.",
                fiveDegrees: "5Degrees",
                fiveDegreesDesc: "Protocolo de red social en Web3.0."
            },
            assets: {
                title: "Activos"
            },
            collaborations: {
                title: "Colaboraciones"
            },
            community: {
                title: "Comunidad",
                developers: "Desarrolladores",
                recruiting: "TP Man"
            },
            helpCenter: {
                title: "Ayuda"
            }
        },
        ABOUT: {
            title: "Acerca de nosotros",
            desc: "TokenPocket es una billetera descentralizada multi cadenas que proporciona a los usuarios una billetera móvil, una billetera de extensión y una billetera de hardware; admite cadenas públicas, incluidas Bitcoin, Ethereum, BNB Smart Chain, TRON, Polygon, Solana, Aptos, Polkadot, EOS y todas las cadenas compatibles con EVM. Sirviendo a más de 20 millones de usuarios de más de 200 países y regiones. Es una billetera cripto líder en la que los usuarios confían en todo el mundo.",
            philosophy: {
                title: "Nuestra Filosofía",
                desc: "Insistimos en una comunidad de tecnología abierta y damos la bienvenida a todos los desarrolladores para que construyamos juntos un mundo blockchain más conveniente, seguro y próspero.",
                ambition: "Visión",
                ambition_desc: "Hacer que la cadena de bloques tenga presencia en todas partes",
                value: "Valor",
                value_desc: "Que la data regrese a los usuarios y que ésta pertenezca a los verdaderos propietarios",
                attitude: "Actitud",
                attitude_desc: "Colaboración mutua y de mente abierta."
            },
            milestones: {
                title: "Hitos",
                desc_2018_1: "Fundación de TokenPocket",
                desc_2018_2: "Inversiones por parte de Huobi, Hofan, Byte Capital",
                desc_2019_1: "Lanzamiento de la cartera de escritorio compatible con TRON",
                desc_2019_2: "Las descargas de Google Play superan los más de 1.000.000",
                desc_2020_1: "Cartera HD compatible",
                desc_2020_2: "Admite las últimas tendencias BSC y DeFi ",
                desc_2020_3: "Apoyo al Staking de Eth2.0",
                desc_2021_1: "Transit Incubado",
                desc_2021_2: "El número de usuarios superó los 20.000.000",
                desc_2021_3: "Billetera de hardware KeyPal Incubado",
                desc_2022_1: "Adquirió dFox y se procedió a cambiar el nombre a TokenPocket Extension",
                January: "Enero",
                February: "Febrero",
                March: "Marzo",
                April: "Abril",
                May: "Mayo",
                June: "Junio",
                July: "Julio",
                August: "Agostot",
                September: "Septiembre",
                October: "Octubre",
                November: "Noviembre",
                December: "Diciembre"
            },
            contact_us: {
                title: "Contáctanos para:",
                service: "Atención al Cliente",
                service_desc: "service@tokenpocket.pro",
                bd: "Colaboraciones Empresariales y Comerciales",
                bd_desc: "bd@tokenpocket.pro",
                developers: "Desarrolladores",
                developers_desc: "Discord"
            }
        },
        SEO: {
            title: "TokenPocket - Tu billetera digital universal | Cartera TP - Cartera ETH - Cartera BTC - Cartera BSC - Cartera HECO - Cartera OKExChain - Cartera Polkadot - Cartera Kusama - Cartera DeFi - Cartera Layer 2 - Cartera EOS - Cartera TRX",
            description: "TokenPocket es una billetera de moneda digital líder en el mundo que admite cadenas de bloques públicas, incluidas BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS y Layer 2.",
            keywords: "TokenPocket, Token Pocket, billetera TP, billetera Ethereum, Bitcoin, EOS, IOST, COSMOS, heco, bsc, capa 2, DeFi, billetera,cripto, cadena de bloques,web3"
        }

};
