
import i18n from "./lang";

import { createApp } from 'vue'
import App from './App.vue'
import { createRouter, createWebHistory } from "vue-router";

const routes = [
    { path: '/', name: "indexPage", component: () => import('@/view/index.vue') },
    { path: '/download', name: 'downPage', component: () => import('@/view/down.vue') },
]
const router = createRouter({
    history: createWebHistory(),
    routes
});

const appVue = createApp(App);
appVue.use(i18n);
appVue.use(router);
appVue.mount('#app');
