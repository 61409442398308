
export default {
    demo: {
        demo: 'demo',
    },
        COMMON: {
            EMAIL: "メール",
            BATCH_SENDER: "バッチセンダー",
            YES: "Yes",
            NO: "No",
            HAS: "Yes",
            HAVENT: "No",
            BLOCKCHAIN: "ブロックチェーン",
            MULTIPLE_CHOICE: "(複数選択)",
            IS_SUPPORT_TP_CONNECT: "TokenPocketのコネクトに対応していますか？(モバイルアプリと拡張機能)",
            SUPPORT_BOTH: "両方に対応しています。",
            SUPPORT_EXTENSION: "拡張機能にのみ対応しています。",
            SUPPORT_MOBILE: "モバイルにのみ対応しています。",
            SUPPORT_NONE: "対応していません。",
            blockchainWallet: "ブロックチェーンウォレット",
            iostWallet: "IOST Wallet",
            tronWallet: "TRON Wallet",
            platformInfo: {
                eos: "EOS",
                moac: "MOAC",
                eth: "ETH",
                jt: "Jingtum ",
                enu: "ENU",
                bos: "BOS",
                iost: "IOST",
                cosmos: "COSMOS",
                bnb: "BNB",
                trx: "TRON",
                bsc: "BSC",
                heco: "HECO",
                okex: "OKExChain",
                dot: "Polkadot",
                hsc: "HSC",
                matic: "Polygon",
                klaytn: "Klaytn",
                arb: "Arbitrum One",
                arbn: "Arbirtum Nova",
                ftm: "Fantom",
                etc: "Ethereum Classic",
                cfx: "Conflux eSpace",
                solana: "Solana",
                avax: "AVAX",
                oe: "Optimistic Ethereum",
                xdai: "Gnosis Chain",
                harmony: "Harmony",
                wax: "WAX",
                aurora: "Aurora",
                ksm: "Kusama",
                mb: "Moonbeam",
                sbg: "SubGame",
                kcc: "KCC Mainnet",
                lat: "PlatON",
                bttc: "BTTC",
                gt: "GateChain",
                halo: "HALO",
                aptos: "Aptos"
            },
            submit: "完了",
            symbol: "トークンネーム",
            bl_symbol: "シンボル",
            precision: "精度",
            decimal: "小数点の桁数",
            totalSupply: "発行量",
            contract: "ントラクト",
            website: "公式サイト",
            introduction: "説明",
            example: "サンプル",
            submitTokenInfoAndLogo: "トークンのロゴとその他の情報をアップデート",
            toGithubSubmit: "Githubへ移動",
            nftType: "NFTのプロトコル規格",
            LAYOUT: {
                features: "プロダクト",
                buyCrypto: "暗号資産を購入",
                mobileWallet: "モバイルウォレット",
                hardwareWallet: "ハードウェアウォレット",
                extensionWallet: "拡張ウォレット",
                desktop: "デスクトップウォレット",
                fiveDegrees: "5Degrees",
                approvalDetector: "アプルーバルディテクター",
                tokenSecurity: "トークンセキュリティ",
                keyGenerator: "キージェネレーター",
                information: "詳細",
                blockchainGuide: "ブロックチェーンガイド",
                tronWallet: "TRON Guide",
                iostWallet: "IOST Guide",
                tpMan: "TPマン",
                developers: "開発者",
                github: "Github (TP-Lab)",
                devCenter: "開発者センター",
                subToken: "トークンを投稿",
                subDApp: "DAppを投稿",
                subNFT: "NFTを投稿",
                subChain: "チェーン",
                company: "会社",
                about: "TokenPocketについて",
                careers: "キャリア",
                pressKit: "プレスキット",
                swagShop: "スワッグショップ",
                support: "サポート",
                helpCenter: "ヘルプセンター",
                contactUs: "お問い合わせ",
                legal: "法的情報",
                privacyPolicy: "プライバシーポリシー",
                terms: "利用規約",
                toHome: "ホーム",
                defiWallet: "DeFiウォレット",
                ETHWallet: "イーサリアムウォレット",
                ethWallet: "ETHウォレット"
            }
        },
        HOME: {
            download: "ダウンロード",
            downloadNow: "ダウンロードする",
            HEADER: {
                title: "安全にブロックチェーンを探索できる暗号資産ウォレット",
                desc_1: "安全で簡単に、トークンの購入、保管、送受信、スワップ、NFTの収集ができ、200以上の国と地域、2000万人以上のユーザーに信頼されています。"
            },
            INTRODUCTION: {
                title: "TokenPocketは世界中のユーザーから信用されています。",
                desc_1: "200以上の国や地域で、安全で簡単な暗号資産のウォレットサービスを提供しています。",
                desc_2: "ユーザー数",
                desc_3: "1日のトランザクション数",
                desc_4: "提供している国と地域"
            },
            SECURITY: {
                title: "セキュリティのあるべき姿",
                desc_1: "TokenPocketは、ユーザーのデバイスのみにキーとパスワードを生成して保存しており、あなただけがあなたの資産をコントロールできます。",
                desc_2: "また、必要に応じてよりセキュリティを強化できるよう、ハードウェアウォレットのKeyPalや、マルチシグウォレットを開発しています。",
                desc_3: "BTC、ETH、BSC、TRON、Polygon、Solana、Cosmos、Polkadot、EOS、IOSTなど対応。"
            },
            EXCHANGE: {
                title: "簡単な交換・取引",
                desc_1: "TokenPocketから、いつでもどこでも暗号資産を取引することができます。",
                desc_2: "クレジットカードで暗号資産を購入でき、保管、送受信、クロスチェーン、取引を簡単に行うことができます。",
                desc_3: "スワップ",
                desc_4: "瞬時に、そして簡単に",
                desc_5: "ブリッジ",
                desc_6: "異なるチェーンの間で",
                desc_7: "暗号資産の購入",
                desc_8: "5分で"
            },
            DAPPSTORE: {
                title: "DAppストア",
                desc_1: "ウォレットに搭載されたDAppブラウザーでお気に入りの分散型アプリケーションを見つけ、最新でホットなものを発見し、",
                desc_2: "ウォレットから離れることなく使用することができ、いつでもDAppsにアクセスすることができます。",
                desc_3: "DeFi",
                desc_4: "NFT",
                desc_5: "DApp",
                desc_6: "サポート"
            },
            COMMUNITY: {
                title: "コミュニティ",
                desc_1: "オープンな開発者コミュニティにこだわり、より便利に安全で豊かなブロックチェーンの世界を一緒に作ってくれる開発者の方々を歓迎します",
                desc_2: "TP-Lab",
                desc_3: "開発者コミュニティ",
                desc_4: "開発者向けドキュメント"
            },
            DOWNLOAD: {
                title: "TokenPocketを手に入れよう！",
                desc_1: "安全にブロックチェーンを探索できる暗号資産ウォレット"
            },
            FOLLOW: {
                title: "フォローする",
                desc1: "TokenPocket スタッフからプライベート メッセージは送信されません!",
                desc2: "注意！ TokenPocket コミュニティに参加しようとしています。誰かが私たちになりすましてプライベート メッセージを送信している可能性があります。 プライベート メッセージを送信する人は詐欺師である可能性があることに注意してください。 最初に連絡することはありません！",
                desc3: "了解、入力"
            },
            EXTENSIONMODAL: {
                title: "拡張機能のウォレットが利用できます！",
                desc1: "暗号資産&DeFi&GaneFi",
                desc2: "コンピューターで",
                btnText: "利用可能",
                btnTextm: "リンクをコビーする",
                tips: "リンクをコピーして、コンピューターから開いてください。"
            }
        },
        DAPP: {
            RULES: {
                platform: "プラットフォームが必要です",
                title: "DAppの名前が必要です",
                address: "DAppのコントラクトが必要です",
                url: "DAppのウェブサイトが必要です",
                desc: "説明が必要です",
                icon_url: "DAppのロゴが必要です",
                rakeBackAccount: "コントラクトのアカウントが必要です",
                email: "Eメールアドレスが必要です",
                others: "その他の連絡先情報が必要です",
                tp_connect: "このアイテムは空白にすることができません"
            },
            title: "DAppの名前",
            address: "スマートコントラクト",
            url: "DAppのウェブサイト",
            desc: "短い説明",
            icon: "DAppのロゴ(200x200のJPG,PNG形式)",
            referral: "リファラル",
            hasReferral: "リファラルシステムの有無",
            referralReward: "リファラルリワードの分配について",
            reward_1: "スマートコントラクトによる自動配信(Live)",
            reward_2: "DAppに手動でクレームする必要がある",
            hasInviteReward: "リファラルリンクを有効にするためにはDAppで取引をする必要がありますか？",
            inviteAccount: "リファラルを配信するコントラクト",
            DAppRequirement: "DAppの必要条件",
            requirement_1: "1. TokenPocketのモバイル及び拡張ウォレットをサポートしていること",
            requirement_2: "2.ウェブサイトがアクセス可能で安定していること.",
            requirement_3: "3. オープンソースかつ、メインネットでデプロイされていること",
            requirement_4: "4. 機密性の高いコントラクトの場合、第三者のセキュリティエージェントから監査を受けていること",
            requirement_5: "5. インタラクションロジックが明確で、モバイルのUIに適していること",
            requirement_6: "6. 関連する法令を遵守し、不正や侵害を行わないこと",
            requirement_7: "7. 関連する法令に違反した場合、対応する法的責任を負うこと",
            dappInfo: "DAppの情報:",
            necessary: "必須",
            language: "DAppの言語",
            languageDesc: "(多言語に対応されている場合は別途ご提出ください)",
            en: "English",
            zh: "中文简体",
            zhTW: "中文繁体",
            descDesc: "DAppのサブタイトルに表示される、プロジェクトの簡単な説明(1文)",
            auditInfo: "監査に関する情報:",
            hasAudit: "コントラクトの監査の実施有無",
            auditUrl: "監査報告書のURL",
            auditUrlExample: "例: https://auditlink.com",
            auditReport: "監査報告",
            auditUpload: "アップロード",
            contact: "コントラクトの詳細",
            contactDesc: "メール以外のカスタマーサービスの連絡先を必ず記入してください",
            emailAddr: "Eメール",
            emailExample: "例: service@yourdomain.com",
            others: "その他",
            othersExample: "例: Telegram：@123456789",
            auditOptional: "監査情報を1項目以上入力してください",
            oversize: "サイズを超えています",
            select: "選択",
            tutorial_url: "https://help.tokenpocket.pro/developer-en/",
            tutorial: "チュートリアルを見る>>"
        },
        DOWNLOAD: {
            TITLE: "TokenPocketをダウンロード",
            TITLE_Android: "TokenPocket for Android",
            TITLE_IOS: "TokenPocket for iOS",
            TITLE_Chrome: "TokenPocket for browser",
            TEXT: "TokenPocketは、数百万人に信頼されている安全で簡単に使用できるマルチチェーンの暗号資産ウォレットです。",
            TEXT_Chrome: "TokenPocketは、数百万人に信頼されている安全で簡単に使用できるマルチチェーンの暗号資産ウォレットです。",
            TEXT_PC: "TokenPocketのデスクトップウォレットは、EOS,ETH,BOS,TRONをベースとした最大級のマルチブチェーンウォレットで、ユーザーに強力かつ安全な暗号資産資産管理を提供するために努力しています。",
            scanCode: "スキャンしてダウンロード",
            installTutorial: "チュートリアルのインストール",
            desc_1: "SSL認証を確認して、公式サイトからダウンロードしてください",
            desc_2: "ニーモニックフレーズ/秘密鍵は漏洩しないように管理し、絶対に他人に教えないでください。",
            desc_3: "セキュリティについてもっと見る",
            verifyText: "最新のAndroidバージョン:",
            verifyText1: "アプリのセキュリティを確認する方法",
            verifyText2: "最新のバージョン:",
            verifyText3: "最新のGoogle Playバージョン:",
            footerTitle: "ファーストインプレッションを高める",
            footerDesc_1: "BTC, ETH, BSC, TRON,Polygon, Aptos, Solana, EOS, Polkadot, IOSTなどをサポート",
            footerDesc_2: "マルチレイヤーのセキュリティプロテクション",
            footerDesc_3: "DeFi, DApp, GameFi&NFT をサポート",
            coming_soon: "Coming Soon",
            desc_tp_wallet: "TP Global Ltd は、TokenPocket の唯一の iOS アプリ発行者として正式に任命されました。",
            tp_wallet_version: "TP Wallet バージョン:",
            token_pocket_version: "Token Pocket バージョン:",
            delisted: "上場廃止"
        },
        TOKEN: {
            RULES: {
                email: "メールが必要です",
                address: "アドレスが必要です",
                owner_address: "オーナーのアドレスが必要です",
                symbol: "シンボルが必要です",
                bl_symbol: "BL シンボルが必要です",
                total_supply: "総発行量が必要です",
                decimal: "小数点が必要です",
                precision: "精度が必要です",
                gas: "ガスが必要です",
                website: "ウェブサイトが必要です",
                companyName: "会社名または個人名が必要です",
                contact: "連絡先が必要です",
                name: "プロジェクトの説明が必要です",
                icon_url: "ロゴが必要です"
            },
            icon: "トークンのロゴ(200 * 200ピクセルのJPG,JPEG,PNG形式にのみ対応、画像ファイルの名前はトークン名にしてください。ロゴに角丸は必要ありません。)",
            handleText: "2営業日以内に処理します。"
        },
        RECRUITING: {
            title: "TP Man Recruitment Plan",
            text: "Join the TokenPocket community",
            text1: "Committed to the blockchain world and contribute your part",
            text2: "We build a Web3.0 world together",
            joinUs: "Join Us",
            aboutTitle: "About TP Man",
            aboutText: "TP Man is an important part of the TokenPocket community, and we sincerely invite you to join us!",
            aboutText1: "You are a blockchain enthusiast and endorse the industry value.",
            aboutText2: "Enjoy the convenience brought by TokenPocket wallet when you explore the blockchain world.",
            missionTitle: "The Mission of TP Man",
            missionText: "Help TokenPocket to serve more blockchain users around the world. We hope you, meet two of the following requirements to apply.",
            missionText1: "Expand and promote TokenPocket cooperation with companies or hot projects in your country through various channels",
            missionText2: "Plan marketing activities that meet the needs of local users",
            missionText3: "Have the ability to operate mainstream social medias such as Twitter, Youtube, Telegram, and Discord",
            missionText4: "Fluent in English, and be able to complete translation work",
            missionText5: "TokenPocket plans to provide more usage and technical support for global blockchain users, so we hope that you have a certain understanding of the blockchain markets of no less than one country and their users (India, the United States, Turkey, Russia, South Korea, Vietnam, the Philippines, etc.)",
            getTitle: "What will you get?",
            getText: "A work experience directly involved with various fields of the blockchain industry, and you will get but not limited to communication opportunities with DApp projects, Influencers, and mainstream media in the industry.",
            getText1: "Get rich rewards from your work such as tweets translation, making video, community operation and business cooperation.",
            getText2: "Get the most professional blockchain knowledge training and explore the Web3.0 world with the team together.",
            getText3: "TokenPocket official benefits, including TokenPocket Swag and hardware wallets.",
            processTitle: "Recruitment process",
            processText: "Submit CV",
            processText1: "CV screening",
            processText2: "Online interview ",
            processText3: "Interview results",
            processText4: "Welcome aboard",
            applyTitle: "Who can apply",
            applyText: "Face the world, regardless of country",
            applyText1: "Be keen and curious about the blockchain world",
            applyText2: "Fill out the form and attach your resume, then we will contact you as soon as possible",
            footerTitle: "About TokenPocket",
            footerText: "over",
            footerText1_1: "20M",
            footerText1_2: "global users",
            footerText2_1: "3.5M",
            footerText2_2: "monthly active users",
            footerText3_1: "200",
            footerText3_2: "countries and regions",
            footerText4: "TokenPocket is the world's leading multi-chain self-custodial wallet",
            footerText5: "Coming soon"
        },
        NAVIGATION: {
            product: {
                title: "製品",
                selfCustodyWallet: "モバイルウォレット",
                selfCustodyWalletDesc: "ブロックチェーンの暗号資産&DeFiモバイルウォレット",
                hardwareWallet: "ハードウェアウォレット",
                hardwareWalletDesc: "あなたの資産を守る、KeyPalをゲットしよう。",
                extensionWallet: "拡張ウォレット",
                extensionWalletDesc: "デスクトップでより良いウォレットを。",
                transit: "Transit",
                transitDesc: "マルチチェーンのDEXアグリゲーター&NFTマーケットプレイス",
                fiveDegrees: "5Degrees",
                fiveDegreesDesc: "Web3.0のソーシャルネットワークプロトコル"
            },
            assets: {
                title: "アセット"
            },
            collaborations: {
                title: "コラボレーション"
            },
            community: {
                title: "コミュニティ",
                developers: "開発者",
                recruiting: "TPマン(採用)"
            },
            helpCenter: {
                title: "ヘルプセンター"
            }
        },
        ABOUT: {
            title: "TokenPocketについて",
            desc: "TokenPocketは、BTC、ETH、BNB、TRON、Polygon、Solana、HECO、Klaytn、Avalanche、OKC、HSC、Fantom、Polkadot、Kusama、IOSTなど主流のパブリックチェーンをデフォルトでサポートし、すべてのEVM互換、Polkadot互換、EOS互換のパブリックチェーンに対応する世界有数のマルチチェーンセルフカストディウォレットで、現在までに、全世界200カ国以上、2000万人以上のユーザーに信頼性の高いサービスを提供し、月間アクティブユーザー数は350万人を超えています。三位一体となる、モバイルウォレット(iOS、iPadOS、Android)、Chrome拡張ウォレット、ハードウェアウォレット(KeyPal)を提供しており、あらゆるデバイスからご利用いただけます。",
            philosophy: {
                title: "私たちの理念",
                desc: "私たちはオープンな技術コミュニティにこだわり、より便利で安全で豊かなブロックチェーンの世界を一緒に作ってくれる開発者の方々を歓迎します",
                ambition: "目的",
                ambition_desc: "ブロックチェーンをあらゆる場所で実現すること",
                value: "価値",
                value_desc: "データをユーザーの手に戻し、価値を真の所有者に帰属させる。",
                attitude: "姿勢",
                attitude_desc: "オープンマインドで、互いに協力し合う"
            },
            milestones: {
                title: "マイルストーン",
                desc_2018_1: "TokenPocketの設立",
                desc_2018_2: "Huobi、Hofan、Byte Capitalから投資を受ける",
                desc_2019_1: "デスクトップウォレットをリリース、TRONをサポート",
                desc_2019_2: "Google Playでのダウンロード数が100万を突破",
                desc_2020_1: "HDウォレットをサポート",
                desc_2020_2: "BSCとDeFuをサポート",
                desc_2020_3: "ETH2.0のステーキングをサポート",
                desc_2021_1: "TransitFinanceを提供開始",
                desc_2021_2: "ユーザー数が2000万人を突破",
                desc_2021_3: "KeyPal ハードウェアウォレットを販売開始",
                desc_2022_1: "dFoxを買収し、Chrome拡張ウォレットを提供開始",
                January: "1月",
                February: "2月",
                March: "3月",
                April: "4月",
                May: "5月",
                June: "6月",
                July: "7月",
                August: "8月",
                September: "9月",
                October: "10月",
                November: "11月",
                December: "12月"
            },
            contact_us: {
                title: "お問い合わせ",
                service: "カスタマーサービス",
                service_desc: "service@tokenpocket.pro",
                bd: "ビジネスコラボレーション",
                bd_desc: "bd@tokenpocket.pro",
                developers: "開発者",
                developers_desc: "Discord"
            }
        },
        SEO: {
            title: "TokenPocket - ユニバーサルデザインウォレット｜TPウォレット - ETHウォレット - BTCウォレット - BSCウォレット - HECOウォレット - OKExChainウォレット - Polkadotウォレット - Kusamaウォレット - DeFiウォレット - レイヤー2ウォレット - EOSウォレット - TRXウォレット",
            description: "TokenPocketは、BTC、ETH、BSC、HECO、TRON、OKExChain、Polkadot、Kusama、EOS、Layer 2などのパブリックブロックチェーンをサポートする世界トップレベルの暗号資産ウォレットです。",
            keywords: "TokenPocket,Token Pocket,TP ウォレット,トークンポケット,仮想通貨,暗号資産,ウォレット,イーサリアムウォレット,ビットコイン,EOS,IOST,COSMOS,heco,bsc,レイヤー2,DeFi,ウォレット"
        }

};
