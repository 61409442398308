export default {
  demo: {
    demo: '示例',
  },
  COMMON: {
    EMAIL: "Email",
    BATCH_SENDER: "批量轉賬",
    YES: "是",
    NO: "否",
    HAS: "有",
    HAVENT: "没有",
    BLOCKCHAIN: "項目網絡",
    MULTIPLE_CHOICE: "(可多選)",
    IS_SUPPORT_TP_CONNECT: "是否支持TokenPocket連接（包括移動端和插件端）",
    SUPPORT_BOTH: "均已支持",
    SUPPORT_EXTENSION: "僅支持插件端",
    SUPPORT_MOBILE: "僅支持移動端",
    SUPPORT_NONE: "均未支持",
    blockchainWallet: "區塊鏈錢包",
    iostWallet: "IOST錢包",
    tronWallet: "TRON錢包",
    platformInfo: {
      eos: "EOS",
      moac: "墨客",
      eth: "以太坊",
      jt: "井通",
      enu: "ENU",
      bos: "BOS",
      iost: "IOST",
      cosmos: "COSMOS",
      bnb: "BNB",
      trx: "TRON",
      bsc: "幣安智能鏈",
      heco: "火幣生態鏈",
      okex: "OKExChain",
      dot: "Polkadot",
      hsc: "虎符智能鏈",
      matic: "Polygon",
      klaytn: "Klaytn",
      arb: "Arbitrum One",
      arbn: "Arbirtum Nova",
      ftm: "Fantom",
      etc: "Ethereum Classic",
      cfx: "Conflux eSpace",
      solana: "Solana",
      avax: "AVAX",
      oe: "Optimistic Ethereum",
      xdai: "Gnosis Chain",
      harmony: "Harmony",
      wax: "WAX",
      aurora: "Aurora",
      ksm: "Kusama",
      mb: "Moonbeam",
      sbg: "SubGame",
      kcc: "KCC Mainnet",
      lat: "PlatON",
      bttc: "BTTC",
      gt: "GateChain",
      halo: "HALO",
      aptos: "Aptos"
    },
    submit: "提交",
    symbol: "代幣符號（如：AAA）",
    bl_symbol: "網絡代碼",
    precision: "代幣小數位",
    decimal: "精度（如：18）",
    totalSupply: "代幣數量",
    contract: "合約地址（如：itokenpocket）",
    website: "官方網址（如：https://www.tokenpocket.pro）",
    introduction: "項目簡介",
    example: "示例",
    submitTokenInfoAndLogo: "提交代幣信息及logo",
    toGithubSubmit: "跳轉至Github提交",
    nftType: "NFT類型",
    LAYOUT: {
      features: "功能",
      buyCrypto: "購買加密資產",
      mobileWallet: "手機錢包",
      hardwareWallet: "硬體錢包",
      extensionWallet: "挿件錢包",
      desktop: "桌面端錢包",
      fiveDegrees: "5Degrees",
      approvalDetector: "授權檢測",
      tokenSecurity: "代幣安全",
      keyGenerator: "私鑰生成器",
      information: "信息",
      blockchainGuide: "區塊鏈小白書",
      tronWallet: "波場小白書",
      iostWallet: "IOST小白書",
      tpMan: "TP俠",
      developers: "開發者",
      github: "Github (TP-Lab)",
      devCenter: "開發者中心",
      subToken: "提交代幣",
      subDApp: "提交DApp",
      subNFT: "提交NFT",
      subChain: "提交公鏈",
      company: "公司",
      about: "關於",
      careers: "招聘",
      pressKit: "品牌素材",
      swagShop: "周邊商店",
      support: "支持",
      helpCenter: "幫助中心",
      contactUs: "聯繫我們",
      legal: "法律",
      privacyPolicy: "隱私策略",
      terms: "服務協議",
      toHome: "返回首頁",
      defiWallet: "DeFi錢包",
      ETHWallet: "Ethereum Wallet",
      ethWallet: "ETH錢包"
    }
  },
  HOME: {
    download: "下載",
    downloadNow: "立即下載",
    HEADER: {
      title: "加密錢包 安全簡單",
      desc_1: "讓你安全、簡單地探索區塊鏈，是全球領先的數字資產錢包"
    },
    INTRODUCTION: {
      title: "TokenPocket深受全球的用戶信賴",
      desc_1: "我們為全球200多個國家及地區的用戶提供安全易用的數字資產服務",
      desc_2: "服務的用戶",
      desc_3: "日均交易數",
      desc_4: "國家及地區"
    },
    SECURITY: {
      title: "安全是我們的根基",
      desc_1: "TokenPocket只在用戶的設備裡生成並存儲私鑰助記詞，您是唯一可以訪問的人",
      desc_2: "TokenPocket同時開發了冷錢包、多簽錢包等，來滿足您對安全的需求",
      desc_3: "多鏈錢包，支持BTC、ETH、BSC、TRON、Aptos、Polygon、Solana、Cosmos、Polkadot、EOS、IOST等"
    },
    EXCHANGE: {
      title: "兌換、交易更簡單",
      desc_1: "隨時隨地，讓你更方便地交易",
      desc_2: "用你的信用卡購入數字資產，輕鬆存儲、發送、跨鏈與兌換",
      desc_3: "閃兌",
      desc_4: "簡單快速",
      desc_5: "跨鏈",
      desc_6: "多鏈轉換",
      desc_7: "購入數字資產",
      desc_8: "僅在5分鐘內"
    },
    DAPPSTORE: {
      title: "一站式DApp商店",
      desc_1: "找到你最喜愛的去中心化應用，發現最新、最熱的DApp，一切盡在TokenPocket",
      desc_2: "集成DApp瀏覽器，您可以訪問你的任何DApp鏈接",
      desc_3: "DeFi",
      desc_4: "NFT",
      desc_5: "DApp",
      desc_6: "已支持"
    },
    COMMUNITY: {
      title: "社區",
      desc_1: "我們堅持開放性社區，始終如一！我們歡迎所有開發者一起開發更方便、更豐富的的區塊鏈世界",
      desc_2: "TP-Lab",
      desc_3: "開發者社區",
      desc_4: "開發者文檔"
    },
    DOWNLOAD: {
      title: "現在就擁有您的TokenPocket！",
      desc_1: "安全可信賴的數字資產錢包，伴您探索區塊鏈世界"
    },
    FOLLOW: {
      title: "關注我們",
      desc1: "TokenPocket 客服不會私聊您！",
      desc2: "請注意，您即將進入TokenPocket社區，您進入社區可能會有人假冒TokenPocket的工作人員私聊您，請您務必警惕，所有私聊您的都可能是騙子！ TokenPocket工作人員不會主動跟您私信！",
      desc3: "知道了，繼續前往"
    },
    EXTENSIONMODAL: {
      title: "插件錢包現已上線！",
      desc1: "一款應用在電腦上的 Crypto/DeFi",
      desc2: "/GameFi 錢包",
      btnTextm: "複製鏈接",
      btnText: "立即使用",
      tips: "複製鏈接成功，請前往電腦端打開"
    }
  },
  DAPP: {
    RULES: {
      platform: "項目網絡不能為空",
      title: "項目名稱不能為空",
      address: "DApp合約帳號不能為空",
      url: "項目鏈接不能為空",
      desc: "項目簡介不能為空",
      icon_url: "請上傳DApp圖標",
      rakeBackAccount: "邀請獎勵合約賬號不能為空",
      email: "郵箱不能為空",
      others: "其他聯繫方式不能為空",
      tp_connect: "該項不能為空"
    },
    title: "項目名稱",
    address: "DApp合約地址",
    url: "項目鏈接",
    desc: "項目簡介",
    icon: "DApp圖標 (圖片大小：200x200px，支持JPG、PNG)",
    referral: "返佣",
    hasReferral: "是否有返佣機制",
    referralReward: "邀請獎勵發放方式",
    reward_1: "邀请奖励发放方式",
    reward_2: "邀請人需在DApp里手動領取",
    hasInviteReward: "邀請人賬號是否需要在DApp中完成一筆交易才能激活邀請獎勵",
    inviteAccount: "給TP發放邀請獎勵的合約賬號",
    DAppRequirement: "DApp要求",
    requirement_1: "1. DApp需要支持TokenPocket 移動端和插件端。",
    requirement_2: "2. 項目方提供的網址可公開訪問且穩定性有保證。",
    requirement_3: "3. 智能合約已部署到主網上，敏感邏輯處需開源。",
    requirement_4: "4. 敏感的合約需提供第三方安全機構的審計報告。",
    requirement_5: "5. 交互邏輯清晰，有實際用途，且已適配移動端。",
    requirement_6: "6. 符合相關法律法規，不存在欺詐和侵權等行為。",
    requirement_7: "7. 如違反相關法律法規，自願承受相應法律責任。",
    dappInfo: "項目信息：",
    necessary: "為必填項",
    language: "項目語言",
    languageDesc: "(多語言請分別提交)",
    en: "English",
    zh: "中文简体",
    zhTW: "中文繁体",
    descDesc: "一句話簡單描述項目，將出現在DApp副標題裡",
    auditInfo: "審計信息：",
    hasAudit: "是否已進行合約審計",
    auditUrl: "審計報告鏈接",
    auditUrlExample: "如：https://auditlink.com",
    auditReport: "審計報告",
    auditUpload: "點擊上傳",
    contact: "聯繫方式：",
    contactDesc: "請務必留下除郵箱以外的客服聯繫方式，否則無法通過審核",
    emailAddr: "郵箱",
    emailExample: "dapp@tokenpocket.pro",
    others: "其他",
    othersExample: "如：Discord：1234",
    auditOptional: "審計信息至少選填一項",
    oversize: "已超出限定字符",
    select: "請選擇",
    tutorial_url: "https://help.tokenpocket.pro/developer-en/",
    tutorial: "對接教程>>"
  },
  DOWNLOAD: {
    TITLE: "下載 TokenPocket",
    TITLE_Android: "TokenPocket 安卓客戶端",
    TITLE_IOS: "TokenPocket iOS客戶端",
    TITLE_Chrome: "TokenPocket 瀏覽器插件",
    TEXT: "TokenPocket 是一款支持多鏈的自託管錢包，使用簡單安全，深受全球數千萬人的信賴與喜愛",
    TEXT_Chrome: "TokenPocket插件錢包是一款多鏈自託管錢包，支持所有EVM兼容鏈，使用簡單安全，深受全球數千萬人的信賴與喜愛",
    TEXT_PC: "TokenPocket桌面端是基於ETH、TRON、EOS的多鏈錢包，我們努力為用戶提供強大且安全的數字資產管理服務",
    scanCode: "掃碼下載",
    installTutorial: "插件安装教程",
    desc_1: "請務必從官網下載錢包應用，並檢查網站的SSL證書",
    desc_2: "保護好您的私鑰、助記詞，切勿洩露，更不可與任何人透露",
    desc_3: "了解更多安全知識",
    verifyText: "當前安卓版本:",
    verifyText1: "如何驗證應用安全性",
    verifyText2: "當前版本:",
    verifyText3: "當前 Google Play 版本:",
    footerDesc_1: "多鏈錢包，支持BTC、ETH、BSC、TRON、Aptos、Matic、Solana、EOS、Polkadot、IOST等等",
    footerDesc_2: "多重安全保護措施，讓您安心使用",
    footerDesc_3: "支持DeFi、DApp、GameFi和NFT等",
    coming_soon: "即將上線",
    desc_tp_wallet: "TP Global Ltd 正式成為 TokenPocket 唯一 iOS App 發行商",
    tp_wallet_version: "TP Wallet 版本:",
    token_pocket_version: "Token Pocket 版本:",
    delisted: "已下架"
  },
  TOKEN: {
    RULES: {
      email: "Email不能為空",
      address: "合約地址不能為空",
      owner_address: "合約帳號地址不能為空",
      symbol: "代幣符號不能為空",
      bl_symbol: "網絡代碼不能為空",
      total_supply: "代幣數量不能為空",
      decimal: "代幣精度不能為空",
      precision: "代幣小數位不能為空",
      gas: "GAS費用不能為空",
      website: "官網地址不能為空",
      companyName: "公司或個人名字不能為空",
      contact: "項目方聯繫方式不能為空",
      name: "項目簡介不能為空",
      icon_url: "請上傳代幣Logo"
    },
    icon: "代幣圖標((200*200像素，支持jpg、jpeg、png)",
    handleText: "我們會在兩個工作日內處理您的申請"
  },
  RECRUITING: {
    title: "TP俠招募計劃",
    text: "加入TokenPocket社區",
    text1: "投身到區塊鏈世界，貢獻自己的一份力量",
    text2: "共同建設Web3.0世界",
    joinUs: "加入我們",
    aboutTitle: "關於TP俠",
    aboutText: "TP俠是TP社區中的重要一員，我們誠摯地邀請您成為全球TP俠中的一員！",
    aboutText1: "熱衷於區塊鏈事業並認可它的價值",
    aboutText2: "作為TokenPocket的忠誠用戶，希望為探索區塊鏈增加便利",
    missionTitle: "TP俠使命",
    missionText: "在全球範圍內，幫助TP錢包服務更多的區塊鏈用戶，我們希望您（滿足以下其中兩項要求即可報名）",
    missionText1: "能夠通過多種渠道拓展並促進TP與所在國家的的營銷公司或熱門項目合作",
    missionText2: "能夠策劃符合當地用戶需求的市場營銷活動",
    missionText3: "具有其中一至幾項主流社交媒體如Twitter、Youtube、Telegram、Discord等媒體的運營能力",
    missionText4: "具有流暢的英語水平，能完成對應的翻譯工作",
    missionText5: "TokenPocket當前計劃為區塊鏈市場提供更多的福利，如果您來自印度、美國、土耳其、俄羅斯、韓國、越南、菲律賓等，我們將給您提供更多的工作支持",
    getTitle: "我們提供的福利",
    getText: "一段直接與區塊鏈各個領域直接接觸的工作經歷，你將獲得不限於DApp項目方、kol、主流媒體的交互機會",
    getText1: "根據工作內容（翻譯推文、製作視頻、社群運營、尋求商務合作等）獲得對應的豐厚報酬",
    getText2: "獲得最專業的區塊鏈知識培訓，與團隊一起探索WEB3.0世界",
    getText3: "TP官方福利，包含TokenPocket定製衣服，硬件錢包",
    processTitle: "招募流程",
    processText: "投遞簡歷",
    processText1: "簡歷篩選",
    processText2: "線上面試",
    processText3: "面試結果",
    processText4: "開始工作",
    applyTitle: "招募對象",
    applyText: "來自全球任何地方",
    applyText1: "對區塊鏈保持著無盡的好奇和熱情",
    applyText2: "填寫表格並附上簡歷，我們會盡快聯繫您",
    footerTitle: "關於TokenPocket",
    footerText: "超過",
    footerText1_1: "2千萬",
    footerText1_2: "全球用戶量",
    footerText2_1: "350萬",
    footerText2_2: "月活",
    footerText3_1: "200個",
    footerText3_2: "國家和地區",
    footerText4: "TokenPocket 全球領先的多鏈自託管錢包",
    footerText5: "Coming soon"
  },
  NAVIGATION: {
    product: {
      title: "產品",
      selfCustodyWallet: "手機錢包",
      selfCustodyWalletDesc: "區塊鏈入口，手機上的多鏈錢包",
      hardwareWallet: "硬體錢包",
      hardwareWalletDesc: "用keypal守護您的資產，安全、放心",
      extensionWallet: "挿件錢包",
      extensionWalletDesc: "讓您更友好的體驗電腦端錢包",
      transit: "Transit",
      transitDesc: "多鏈DEX聚合閃兌與NFT市場平臺",
      fiveDegrees: "5Degrees",
      fiveDegreesDesc: "Web3.0社交協定"
    },
    assets: {
      title: "資產"
    },
    collaborations: {
      title: "合作"
    },
    community: {
      title: "社區",
      developers: "開發者",
      recruiting: "TP俠"
    },
    helpCenter: {
      title: "幫助中心"
    }
  },
  ABOUT: {
    title: "關於我們",
    desc: "TokenPocket是一款去中心化多鏈錢包，為用戶提供手機錢包、插件錢包和硬件錢包，支持比特幣、以太坊、幣安智能鏈、TRON、Aptos、Polygon、Solana、Polkadot、EOS等公鏈以及所有EVM兼容鏈。 TokenPocket服務於來自200多個國家和地區的2000多萬用戶。它是全球用戶信賴的領先的加密錢包。",
    philosophy: {
      title: "我們的理念",
      desc: "我們堅持開發技術社區，歡迎所有的開發者共同構建更便捷、更安全、更豐富的區塊鏈世界",
      ambition: "目標",
      ambition_desc: "讓區塊鏈隨處發生",
      value: "價值",
      value_desc: "讓數據回歸用戶，讓價值歸屬用戶",
      attitude: "態度",
      attitude_desc: "開放思維，互相協作"
    },
    milestones: {
      title: "里程碑",
      desc_2018_1: "TokenPocket成立",
      desc_2018_2: "由火幣、浩方資本、字節資本投資",
      desc_2019_1: "發布桌面端錢包，並支持TRON網絡",
      desc_2019_2: "谷歌商店下載次數突破100萬",
      desc_2020_1: "支持身份錢包（HD錢包）",
      desc_2020_2: "支持BSC網絡以及DeFi趨勢",
      desc_2020_3: "支持Eth2.0系統質押",
      desc_2021_1: "孵化去中心化聚合平台Transit",
      desc_2021_2: "總用戶量突破2000萬",
      desc_2021_3: "孵化硬件錢包KeyPal",
      desc_2022_1: "收購dFox並將品牌升級為TokenPocket Extension",
      January: "1月",
      February: "2月",
      March: "3月",
      April: "4月",
      May: "5月",
      June: "6月",
      July: "7月",
      August: "8月",
      September: "9月",
      October: "10月",
      November: "11月",
      December: "12月"
    },
    contact_us: {
      title: "聯繫我們",
      service: "服務郵箱",
      service_desc: "service@tokenpocket.pro",
      bd: "商務合作",
      bd_desc: "bd@tokenpocket.pro",
      developers: "開發者",
      developers_desc: "Discord link"
    }
  },
  SEO: {
    title: "TokenPocket - 你的通用數字錢包 | TP錢包-ETH錢包-BTC錢包-BSC錢包-HECO錢包-OKExChain錢包-Polkadot錢包-Kusama 錢包-DeFi錢包-Layer 2錢包-EOS錢包-TRX錢包",
    description: "TokenPocket是全球最大的數字貨幣錢包，支持包括BTC、ETH、BSC、HECO、TRON、Aptos、OKExChain、Polkadot、Kusama、EOS等在內的所有主流公鍊及Layer 2，已為全球近千萬用戶提供可信賴的數字貨幣資產管理服務，也是當前DeFi用戶必備的工具錢包。",
    keywords: "TokenPocket,Token Pocket,TP錢包,ETH錢包,BTC錢包,EOS錢包,IOST,波卡,Polkadot,COSMOS,波場,以太坊,DeFi,火幣鏈,幣安智能鏈,錢包,layer2,加密,區塊鏈,web3"
  }
};
