
export default {
  demo: {
    demo: '示例',
  },

  COMMON: {
    EMAIL: "Email",
    BATCH_SENDER: "批量转账",
    YES: "是",
    NO: "否",
    HAS: "有",
    HAVENT: "没有",
    BLOCKCHAIN: "项目网络",
    MULTIPLE_CHOICE: "(可多选)",
    IS_SUPPORT_TP_CONNECT: "是否支持TokenPocket连接（包括移动端和插件端）",
    SUPPORT_BOTH: "均已支持",
    SUPPORT_EXTENSION: "仅支持插件端",
    SUPPORT_MOBILE: "仅支持移动端",
    SUPPORT_NONE: "均未支持",
    blockchainWallet: "区块链钱包",
    iostWallet: "IOST钱包",
    tronWallet: "TRON钱包",
    platformInfo: {
      eos: "EOS",
      moac: "墨客",
      eth: "以太坊",
      jt: "井通",
      enu: "ENU",
      bos: "BOS",
      iost: "IOST",
      cosmos: "COSMOS",
      bnb: "BNB",
      trx: "TRON",
      bsc: "币安智能链",
      heco: "火币生态链",
      okex: "OKExChain",
      dot: "Polkadot",
      hsc: "虎符智能链",
      matic: "Polygon",
      klaytn: "Klaytn",
      arb: "Arbitrum One",
      arbn: "Arbirtum Nova",
      ftm: "Fantom",
      etc: "Ethereum Classic",
      cfx: "Conflux eSpace",
      solana: "Solana",
      avax: "AVAX",
      oe: "Optimistic Ethereum",
      xdai: "Gnosis Chain",
      harmony: "Harmony",
      wax: "WAX",
      aurora: "Aurora",
      ksm: "Kusama",
      mb: "Moonbeam",
      sbg: "SubGame",
      kcc: "KCC Mainnet",
      lat: "PlatON",
      bttc: "BTTC",
      gt: "GateChain",
      halo: "HALO",
      aptos: "Aptos"
    },
    submit: "提交",
    symbol: "代币符号（如：AAA）",
    bl_symbol: "网络代码",
    precision: "代币小数位",
    decimal: "精度（如：18）",
    totalSupply: "代币数量",
    contract: "合约地址（如：itokenpocket）",
    website: "官方网址（如：https://www.tokenpocket.pro）",
    introduction: "项目简介",
    example: "示例",
    submitTokenInfoAndLogo: "提交代币信息及logo",
    toGithubSubmit: "跳转至Github提交",
    nftType: "NFT类型",
    LAYOUT: {
      features: "功能",
      buyCrypto: "购买加密资产",
      mobileWallet: "手机钱包",
      hardwareWallet: "硬件钱包",
      extensionWallet: "插件钱包",
      desktop: "桌面端钱包",
      fiveDegrees: "5Degrees",
      approvalDetector: "授权检测",
      tokenSecurity: "代币安全检测",
      keyGenerator: "私钥生成器",
      information: "信息",
      blockchainGuide: "区块链小白书",
      tronWallet: "波场小白书",
      iostWallet: "IOST小白书",
      tpMan: "TP侠",
      developers: "开发者",
      github: "Github (TP-Lab)",
      devCenter: "开发者中心",
      subToken: "提交代币",
      subDApp: "提交DApp",
      subNFT: "提交NFT",
      subChain: "提交公链",
      company: "公司",
      about: "关于",
      careers: "招聘",
      pressKit: "品牌素材",
      swagShop: "周边商店",
      support: "支持",
      helpCenter: "帮助中心",
      contactUs: "联系我们",
      legal: "法律",
      privacyPolicy: "隐私策略",
      terms: "服务协议",
      toHome: "返回首页",
      defiWallet: "DeFi钱包",
      ETHWallet: "以太坊錢包",
      ethWallet: "ETH钱包"
    }
  },
  HOME: {
    download: "下载",
    downloadNow: "立即下载",
    HEADER: {
      title: "让区块链随处发生",
      desc_1: "让你安全、简单地探索区块链，是全球领先的数字资产钱包"
    },
    INTRODUCTION: {
      title: "TokenPocket深受全球用户信赖",
      desc_1: "我们为全球200多个国家及地区的用户提供安全易用的数字资产服务",
      desc_2: "服务的用户",
      desc_3: "日均交易数",
      desc_4: "国家及地区"
    },
    SECURITY: {
      title: "安全是我们的根基",
      desc_1: "TokenPocket只在用户的设备里生成并存储私钥助记词，您是唯一可以访问的人",
      desc_2: "TokenPocket同时开发了冷钱包、多签钱包等，来满足您对安全的需求",
      desc_3: "多链钱包，支持BTC、ETH、BSC、TRON、Aptos、Polygon、Solana、Cosmos、Polkadot、EOS、IOST等"
    },
    EXCHANGE: {
      title: "兑换、交易更简单",
      desc_1: "随时随地，让你更方便地交易",
      desc_2: "用你的信用卡购入数字资产，轻松存储、发送、跨链与兑换",
      desc_3: "闪兑",
      desc_4: "简单快速",
      desc_5: "跨链",
      desc_6: "多链转换",
      desc_7: "购入数字资产",
      desc_8: "仅在5分钟内"
    },
    DAPPSTORE: {
      title: "一站式DApp商店",
      desc_1: "找到你最喜爱的去中心化应用，发现最新、最热的DApp，一切尽在TokenPocket",
      desc_2: "集成DApp浏览器，您可以访问你的任何DApp链接",
      desc_3: "DeFi",
      desc_4: "NFT",
      desc_5: "DApp",
      desc_6: "已支持"
    },
    COMMUNITY: {
      title: "社区",
      desc_1: "我们坚持开放性社区，始终如一！我们欢迎所有开发者一起开发更方便、更丰富的的区块链世界",
      desc_2: "TP-Lab",
      desc_3: "开发者社区",
      desc_4: "开发者文档"
    },
    DOWNLOAD: {
      title: "现在就拥有您的TokenPocket!",
      desc_1: "安全可信赖的数字资产钱包，伴您探索区块链世界"
    },
    FOLLOW: {
      title: "关注我们",
      desc1: "TokenPocket 客服不会私聊您！",
      desc2: "请注意，您即将进入TokenPocket社区，您进入社区可能会有人假冒TokenPocket的工作人员私聊您，请您务必警惕，所有私聊您的都可能是骗子！TokenPocket工作人员不会主动跟您私信！",
      desc3: "知道了，继续前往"
    },
    EXTENSIONMODAL: {
      title: "Extension is now live!",
      desc1: "Your Crypto & DeFi & GameFi",
      desc2: "wallet on computer",
      btnText: "Use It Now",
      btnTextm: "Copy Link",
      tips: "Copy the link successfully, please go to the computer to open"
    }
  },
  DAPP: {
    RULES: {
      platform: "项目网络不能为空",
      title: "项目名称不能为空",
      address: "DApp合约帐号不能为空",
      url: "项目链接不能为空",
      desc: "项目简介不能为空",
      icon_url: "请上传DApp图标",
      rakeBackAccount: "邀请奖励合约账号不能为空",
      email: "邮箱不能为空",
      others: "其他联系方式不能为空",
      tp_connect: "该项不能为空"
    },
    title: "项目名称",
    address: "DApp合约地址",
    url: "项目链接",
    desc: "项目简介",
    icon: "DApp图标 (图片大小：200x200px，支持JPG、PNG)",
    referral: "返佣",
    hasReferral: "是否有返佣机制",
    referralReward: "邀请奖励发放方式",
    reward_1: "邀请奖励发放方式",
    reward_2: "邀请人需在DApp里手动领取",
    hasInviteReward: "邀请人账号是否需要在DApp中完成一笔交易才能激活邀请奖励",
    inviteAccount: "给TP发放邀请奖励的合约账号",
    DAppRequirement: "DApp要求",
    requirement_1: "1. DApp需要支持TokenPocket 移动端和插件端。",
    requirement_2: "2. 项目方提供的网址可公开访问且稳定性有保证。",
    requirement_3: "3. 智能合约已部署到主网上，敏感逻辑处需开源。",
    requirement_4: "4. 敏感的合约需提供第三方安全机构的审计报告。",
    requirement_5: "5. 交互逻辑清晰，有实际用途，且已适配移动端。",
    requirement_6: "6. 符合相关法律法规，不存在欺诈和侵权等行为。",
    requirement_7: "7. 如违反相关法律法规，自愿承受相应法律责任。",
    dappInfo: "项目信息：",
    necessary: "为必填项",
    language: "项目语言",
    languageDesc: "(多语言请分别提交)",
    en: "English",
    zh: "中文简体",
    zhTW: "中文繁体",
    descDesc: "一句话简单描述项目，将出现在DApp副标题里",
    auditInfo: "审计信息：",
    hasAudit: "是否已进行合约审计",
    auditUrl: "审计报告链接",
    auditUrlExample: "如：https://auditlink.com",
    auditReport: "审计报告",
    auditUpload: "点击上传",
    contact: "联系方式：",
    contactDesc: "请务必留下除邮箱以外的客服联系方式，否则无法通过审核",
    emailAddr: "邮箱",
    emailExample: "dapp@tokenpocket.pro",
    others: "其他",
    othersExample: "如：Discord：1234",
    auditOptional: "审计信息至少选填一项",
    oversize: "已超出限定字符",
    select: "请选择",
    tutorial_url: "https://help.tokenpocket.pro/developer-cn/",
    tutorial: "对接教程>>"
  },
  DOWNLOAD: {
    TITLE: "下载 TokenPocket",
    TITLE_Android: "TokenPocket 安卓客户端",
    TITLE_IOS: "TokenPocket iOS客户端",
    TITLE_Chrome: "TokenPocket 浏览器插件",
    TEXT: "TokenPocket 是一款支持多链的自托管钱包，使用简单安全，深受全球数千万人的信赖与喜爱",
    TEXT_Chrome: "TokenPocket插件钱包是一款多链自托管钱包，支持所有EVM兼容链，使用简单安全，深受全球数千万人的信赖与喜爱",
    TEXT_PC: "TokenPocket桌面端是基于ETH、TRON、EOS的多链钱包，我们努力为用户提供强大且安全的数字资产管理服务",
    scanCode: "扫码下载",
    installTutorial: "插件安装教程",
    desc_1: "请务必从官网下载钱包应用，并检查网站的SSL证书",
    desc_2: "保护好您的私钥、助记词，切勿泄露，更不可与任何人透露",
    desc_3: "了解更多安全知识",
    verifyText: "最新安卓版本：",
    verifyText1: "如何验证钱包App的真假",
    verifyText2: "最新版本",
    verifyText3: "最新谷歌商店版本：",
    footerTitle: "",
    footerDesc_1: "多链钱包，支持BTC、ETH、BSC、TRON、Matic、Aptos、Solana、EOS、Polkadot、IOST等等",
    footerDesc_2: "多重安全保护措施，让您安心使用",
    footerDesc_3: "支持DeFi、DApp、GameFi和NFT等",
    coming_soon: "即将上线",
    desc_tp_wallet: "TP Global Ltd 正式成为 TokenPocket 唯一 iOS App 发行商",
    tp_wallet_version: "TP Wallet版本:",
    token_pocket_version: "Token Pocket版本:",
    delisted: "已下架"
  },
  TOKEN: {
    RULES: {
      email: "Email不能为空",
      address: "合约地址不能为空",
      owner_address: "合约帐号地址不能为空",
      symbol: "代币符号不能为空",
      bl_symbol: "网络代码不能为空",
      total_supply: "代币数量不能为空",
      decimal: "代币精度不能为空",
      precision: "代币小数位不能为空",
      gas: "GAS费用不能为空",
      website: "官网地址不能为空",
      companyName: "公司或个人名字不能为空",
      contact: "项目方联系方式不能为空",
      name: "项目简介不能为空",
      icon_url: "请上传代币Logo"
    },
    icon: "代币图标(200*200像素，支持jpg、jpeg、png)",
    handleText: "我们会在两个工作日内处理您的申请"
  },
  RECRUITING: {
    title: "TP侠招募计划",
    text: "加入TokenPocket社区",
    text1: "投身到区块链世界，贡献自己的一份力量",
    text2: "共同建设Web3.0世界",
    joinUs: "加入我们",
    aboutTitle: "关于TP侠",
    aboutText: "TP侠是TP社区中的重要一员，我们诚挚地邀请您成为全球TP侠中的一员！",
    aboutText1: "热衷于区块链事业并认可它的价值",
    aboutText2: "作为TokenPocket的忠诚用户，希望为探索区块链增加便利",
    missionTitle: "TP侠使命",
    missionText: "在全球范围内，帮助TP钱包服务更多的区块链用户，我们希望您（满足以下其中两项要求即可报名）",
    missionText1: "能够通过多种渠道拓展并促进TP与所在国家的的营销公司或热门项目合作",
    missionText2: "能够策划符合当地用户需求的市场营销活动",
    missionText3: "具有其中一至几项主流社交媒体如Twitter、Youtube、Telegram、Discord等媒体的运营能力",
    missionText4: "具有流畅的英语水平，能完成对应的翻译工作",
    missionText5: "TokenPocket当前计划为区块链市场提供更多的福利，如果您来自印度、美国、土耳其、俄罗斯、韩国、越南、菲律宾等，我们将给您提供更多的工作支持",
    getTitle: "我们提供的福利",
    getText: "一段直接与区块链各个领域直接接触的工作经历，你将获得不限于DApp项目方、kol、主流媒体的交互机会",
    getText1: "根据工作内容（翻译推文、制作视频、社群运营、寻求商务合作等）获得对应的丰厚报酬",
    getText2: "获得最专业的区块链知识培训，与团队一起探索WEB3.0世界",
    getText3: "TP官方福利，包含TokenPocket定制衣服，硬件钱包",
    processTitle: "招募流程",
    processText: "投递简历",
    processText1: "简历筛选",
    processText2: "线上面试",
    processText3: "面试结果",
    processText4: "开始工作",
    applyTitle: "招募对象",
    applyText: "来自全球任何地方",
    applyText1: "对区块链保持着无尽的好奇和热情",
    applyText2: "填写表格并附上简历，我们会尽快联系您",
    footerTitle: "关于TokenPocket",
    footerText: "超过",
    footerText1_1: "2千万",
    footerText1_2: "全球用户量",
    footerText2_1: "350万",
    footerText2_2: "月活",
    footerText3_1: "200个",
    footerText3_2: "国家和地区",
    footerText4: "TokenPocket 全球领先的多链自托管钱包",
    footerText5: "Coming soon"
  },
  NAVIGATION: {
    product: {
      title: "产品",
      selfCustodyWallet: "手机钱包",
      selfCustodyWalletDesc: "您的移动端数字资产钱包，支持iOS和Android"
    },
    assets: {
      title: "资产"
    },
    collaborations: {
      title: "项目合作"
    },
    community: {
      title: "社区",
      developers: "开发者",
      recruiting: "TP侠"
    },
    helpCenter: {
      title: "帮助中心"
    }
  },
  ABOUT: {
    title: "关于我们",
    desc: "TokenPocket是一款去中心化多链钱包，为用户提供手机钱包、插件钱包和硬件钱包，支持比特币、以太坊、币安智能链、TRON、Aptos、Polygon、Solana、Polkadot、EOS等公链以及所有EVM兼容链。TokenPocket服务于来自200多个国家和地区的2000多万用户。它是全球用户信赖的领先的加密钱包。",
    philosophy: {
      title: "我们的理念",
      desc: "我们坚持开发技术社区，欢迎所有的开发者共同构建更便捷、更安全、更丰富的区块链世界",
      ambition: "目标",
      ambition_desc: "让区块链随处发生",
      value: "价值",
      value_desc: "让数据回归用户，让价值归属用户",
      attitude: "态度",
      attitude_desc: "开放思维，互相协作"
    },
    milestones: {
      title: "里程碑",
      desc_2018_1: "TokenPocket成立",
      desc_2018_2: "由火币、浩方资本、字节资本投资",
      desc_2019_1: "发布桌面端钱包，并支持TRON网络",
      desc_2019_2: "谷歌商店下载次数突破100万",
      desc_2020_1: "支持身份钱包（HD钱包）",
      desc_2020_2: "支持BSC网络以及DeFi趋势",
      desc_2020_3: "支持Eth2.0系统质押",
      desc_2021_1: "孵化去中心化聚合平台Transit",
      desc_2021_2: "总用户量突破2000万",
      desc_2021_3: "孵化硬件钱包KeyPal",
      desc_2022_1: "收购dFox并将品牌升级为TokenPocket Extension",
      January: "1月",
      February: "2月",
      March: "3月",
      April: "4月",
      May: "5月",
      June: "6月",
      July: "7月",
      August: "8月",
      September: "9月",
      October: "10月",
      November: "11月",
      December: "12月"
    },
    contact_us: {
      title: "联系我们",
      service: "服务邮箱",
      service_desc: "service@tokenpocket.pro",
      bd: "商务合作",
      bd_desc: "bd@tokenpocket.pro",
      developers: "开发者",
      developers_desc: "Discord"
    }
  },
  SEO: {
    // title: "tp商城",
    // description: "tp商城",
    // keywords: "tp商城"
    title: "TokenPocket|你的通用数字钱包/tp钱包官网下载/最新版/tp钱包下载/安卓版下载TokenPocket钱包下载",
    description: "TokenPocket是全球最大的数字货币钱包，支持包括BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, OKExChain, Polkadot, Kusama, EOS等在内的所有主流公链及Layer 2，已为全球近千万用户提供可信赖的数字货币资产管理服务，也是当前DeFi用户必备的工具钱包。",
    keywords: "tp钱包下载,TokenPocket官网,TP钱包,ETH钱包,BTC钱包,EOS钱包,TP官方下载,TP钱包官方,TokenPocket官网下载"
  }

};
