export default {
    demo: {
        demo: 'demo',
    },

    COMMON: {
        EMAIL: "อีเมล",
        BATCH_SENDER: "ผู้ส่งแบทช์",
        YES: "ใช่",
        NO: "ไม่",
        HAS: "ใช่",
        HAVENT: "ไม่",
        BLOCKCHAIN: "บล็อกเชน",
        MULTIPLE_CHOICE: "(ตัวเลือกหลากหลาย)",
        IS_SUPPORT_TP_CONNECT: "องรับการเชื่อมต่อ โทเค็นพ็อกเก็ต หรือไม่ (ส่วนขยายและแอพมือถือ)",
        SUPPORT_BOTH: "รองรับทั้งคู่",
        SUPPORT_EXTENSION: "เฉพาะส่วนขยาย",
        SUPPORT_MOBILE: "เฉพาะมือถือ",
        SUPPORT_NONE: "ไม่มี",
        blockchainWallet: "บล็อกเชนกระเป๋าเงิน",
        iostWallet: "กระเป๋าเงิน IOST",
        tronWallet: "กระเป๋าเงิน TRON",
        platformInfo: {
            eos: "EOS",
            moac: "MOAC",
            eth: "ETH",
            jt: "Jingtum ",
            enu: "ENU",
            bos: "BOS",
            iost: "IOST",
            cosmos: "COSMOS",
            bnb: "BNB",
            trx: "TRON",
            bsc: "BSC",
            heco: "HECO",
            okex: "OKExChain",
            dot: "Polkadot",
            hsc: "HSC",
            matic: "Polygon",
            klaytn: "Klaytn",
            arb: "Arbitrum One",
            arbn: "Arbirtum Nova",
            ftm: "Fantom",
            etc: "Ethereum Classic",
            cfx: "Conflux eSpace",
            solana: "Solana",
            avax: "AVAX",
            oe: "Optimistic Ethereum",
            xdai: "Gnosis Chain",
            harmony: "Harmony",
            wax: "WAX",
            aurora: "Aurora",
            ksm: "Kusama",
            mb: "Moonbeam",
            sbg: "SubGame",
            kcc: "KCC Mainnet",
            lat: "PlatON",
            bttc: "BTTC",
            gt: "GateChain",
            halo: "HALO",
            aptos: "Aptos"
        },
        submit: "ส่ง",
        symbol: "ชื่อโทเค็น",
        bl_symbol: "สัญลักษณ์ BL",
        precision: "ความแม่นยำของโทเค็น",
        decimal: "ทศนิยมของโทเค็น",
        totalSupply: "อุปทานทั้งหมด",
        contract: "สัญญาโทเค็น",
        website: "เว็บไซต์อย่างเป็นทางการ",
        introduction: "โทเค็นแนะนำ",
        example: "ตัวอย่าง",
        submitTokenInfoAndLogo: "Uอัปเดตโลโก้โทเค็นและข้อมูลอื่น ๆ",
        toGithubSubmit: "ไปที่ Github",
        nftType: "มาตรฐานโปรโตคอล NFT",
        LAYOUT: {
            features: "คุณสมบัติ",
            buyCrypto: "ซื้อคริปโต",
            mobileWallet: "กระเป๋าเงินมือถือ",
            hardwareWallet: "กระเป๋าเงินฮาร์ดแวร์",
            extensionWallet: "กระเป๋าเงินเสริม",
            desktop: "กระเป๋าเงินเดสก์ท็อป",
            fiveDegrees: "5องศา",
            approvalDetector: "Approval Detector",
            tokenSecurity: "ความปลอดภัยของโทเค็น",
            keyGenerator: "ตัวสร้างคีย์",
            information: "ข้อมูล",
            blockchainGuide: "คู่มือบล็อคเชน",
            tronWallet: "คู่มือ TRON",
            iostWallet: "คู่มือ IOST",
            tpMan: "ชาย TP",
            developers: "นักพัฒนา",
            github: "Github (TP-Labs)",
            devCenter: "ศูนย์นักพัฒนา",
            subToken: "ส่งโทเค็น",
            subDApp: "ส่งดีแอป",
            subNFT: "ส่ง NFT",
            subChain: "ส่ง โซ่",
            company: "บริษัท",
            about: "กี่ยวกับ",
            careers: "อาชีพ",
            pressKit: "Press Kit",
            swagShop: "Swag Shop",
            support: "สนับสนุน",
            helpCenter: "ศูนย์ช่วยเหลือ",
            contactUs: "ติดต่อเรา",
            legal: "ถูกกฎหมาย",
            privacyPolicy: "นโยบายความเป็นส่วนตัว",
            terms: "ข้อกำหนดการใช้งาน",
            toHome: "บ้าน",
            defiWallet: "กระเป๋าเงินDeFi",
            ETHWallet: "กระเป๋าเงินEthereum",
            ethWallet: "กระเป๋าเงินETH"
        }
    },
    HOME: {
        download: "ดาวน์โหลด",
        downloadNow: "ดาวน์โหลดทันที",
        HEADER: {
            title: "กระเป๋าเงินคริปโตที่ปลอดภัยของคุณเพื่อสำรวจบล็อคเชน",
            desc_1: "ซื้อ จัดเก็บ ส่ง แลกเปลี่ยนโทเค็นและรวบรวม NFT ได้ง่ายและปลอดภัย ได้รับความไว้วางใจจากผู้ใช้กว่า 20 ล้านคนจาก 200 กว่าประเทศและภูมิภาค"
        },
        INTRODUCTION: {
            title: "โทเค็นพ็อกเก็ต ได้รับความไว้วางใจจากผู้ใช้ทั่วโลก",
            desc_1: "เราให้บริการกระเป๋าเงินคริปโตที่ปลอดภัยและง่ายดายกว่า 200 ประเทศและภูมิภาคทั่วโลก",
            desc_2: "ให้บริการผู้ใช้",
            desc_3: "ธุรกรรมรายวัน",
            desc_4: "ประเทศและภูมิภาคที่สนับสนุน"
        },
        SECURITY: {
            title: "ความปลอดภัยตามที่ควรจะเป็น",
            desc_1: "โทเค็นพ็อกเก็ต สร้างและจัดเก็บคีย์และรหัสผ่านบนอุปกรณ์ของคุณเท่านั้น มีเพียงคุณเท่านั้นที่สามารถเข้าถึงบัญชีและทรัพย์สินของคุณ",
            desc_2: "โทเค็นพ็อกเก็ต ยังพัฒนาฮาร์ดแวร์กระเป๋าเงินเย็นและคุณสมบัติกระเป๋าเงินหลายป้ายเพื่อเพิ่มความปลอดภัยตามที่คุณต้องการ",
            desc_3: "รองรับ BTC, ETH, BSC, TRON, Polygon, Solana, Cosmos, Polkadot, EOS, IOST และอื่นๆ"
        },
        EXCHANGE: {
            title: "แลกเปลี่ยนและทำธุรกรรมได้อย่างง่ายดาย",
            desc_1: "คุณสามารถแลกเปลี่ยนคริปโตของคุณได้ทุกที่ทุกเวลาในโทเค็นพ็อกเก็ต",
            desc_2: "ซื้อคริปโต ด้วยบัตรเครดิต จัดเก็บ ส่ง ข้ามห่วงโซ่และแลกเปลี่ยนได้อย่างง่ายดาย",
            desc_3: "สลับ",
            desc_4: "ทันทีและง่ายมาก",
            desc_5: "สะพาน",
            desc_6: "ท่ามกลางสายโซ่ต่างๆ",
            desc_7: "ซื้อคริปโต",
            desc_8: "ภายใน 5 นาที"
        },
        DAPPSTORE: {
            title: "ดีแอปสโตร์",
            desc_1: "คุณสามารถค้นหาแอปพลิเคชั่นกระจายอำนาจที่คุณชื่นชอบ ค้นพบแอปพลิเคชั่นล่าสุดและร้อนแรงที่สุด และใช้งานได้โดยไม่ต้องออกจากกระเป๋าเงิน",
            desc_2: "รวมดีแอปเบราว์เซอร์อด้วย คุณสามารถเข้าถึง ดีแอป ด้วยลิงก์ของคุณได้ตลอดเวลา",
            desc_3: "DeFi",
            desc_4: "NFT",
            desc_5: "DApp",
            desc_6: "รองรับ"
        },
        COMMUNITY: {
            title: "ชุมชน",
            desc_1: "เรายืนกรานในชุมชนเทคโนโลยีแบบเปิด และเรายินดีต้อนรับนักพัฒนาทุกคนเพื่อสร้างโลกบล็อกเชนที่สะดวก ปลอดภัย และสมบูรณ์ยิ่งขึ้นไปด้วยกัน",
            desc_2: "TP-Lab",
            desc_3: "ชุมชน",
            desc_4: "เอกสารสำหรับนักพัฒนา"
        },
        DOWNLOAD: {
            title: "รับ กระเป๋าเงินโทเค็นพ็อกเก็ต ทันที!",
            desc_1: "กระเป๋าเงินคริปโตที่ปลอดภัยและเชื่อถือได้ของคุณในการสำรวจบล็อคเชน"
        },
        FOLLOW: {
            title: "ตามเรามา",
            desc1: "พนักงาน TokenPocket จะไม่ส่งข้อความส่วนตัวถึงคุณ!",
            desc2: "คำเตือน! คุณกำลังเข้าสู่ชุมชน TokenPocket อาจมีใครบางคนแอบอ้างเราเพื่อส่งข้อความส่วนตัวถึงคุณ! โปรดทราบว่าทุกคนที่ส่งข้อความส่วนตัวอาจเป็นสแกมเมอร์! เราจะไม่ติดต่อคุณก่อน!",
            desc3: "เข้าใจแล้ว ป้อน"
        },
        EXTENSIONMODAL: {
            title: "ส่วนขยายใช้งานได้แล้ว",
            desc1: "คริปโต และ DeFi และ GameFi ของคุณ",
            desc2: "กระเป๋าเงินบนคอมพิวเตอร์",
            btnText: "ใช้งานเดี๋ยวนี้",
            btnTextm: "คัดลอกลิงก์",
            tips: "คัดลอกลิงก์สำเร็จ โปรดไปที่คอมพิวเตอร์เพื่อเปิด"
        }
    },
    DAPP: {
        RULES: {
            platform: "จำเป็นต้องมีแพลตฟอร์ม",
            title: "ต้องระบุชื่อดีแอป",
            address: "ต้องมีสัญญาดีแอป",
            url: "จำเป็นต้องมีเว็บไซต์ดีแอป",
            desc: "ต้องระบุ",
            icon_url: "ต้องมีโลโก้ดีแอป",
            rakeBackAccount: "ต้องมีบัญชีสัญญา",
            email: "จำเป็นต้องใช้อีเมล",
            others: "ต้องการข้อมูลติดต่ออื่น",
            tp_connect: "รายการนี้ไม่สามารถเว้นว่างได้"
        },
        title: "ชื่อดีแอป",
        address: "Smart Contract",
        url: "เว็บไซต์ดีแอป",
        desc: "คำอธิบายสั้นๆ",
        icon: "โลโก้ ดีแอป(ต้องเป็น 200x200 - supports JPG, PNG.)",
        referral: "อ้างอิง",
        hasReferral: "มีระบบการอ้างอิงหรือไม่",
        referralReward: "การกระจายรางวัลผู้อ้างอิ",
        reward_1: "แจกจ่ายอัตโนมัติตามสัญญาอัจฉริยะ (สด)",
        reward_2: "จำเป็นต้องรับสิทธิ์ในดีแอป ด้วยตนเอง",
        hasInviteReward: "ผู้เชิญจำเป็นต้องทำธุรกรรมในดีแอป เพื่อเปิดใช้งานลิงก์ผู้อ้างอิงหรือไม่",
        inviteAccount: "สัญญาอัจฉริยะของการกระจายผู้อ้างอิง",
        DAppRequirement: "ข้อกำหนดของดีแอป",
        requirement_1: "1. ดีแอปต้องรองรับ โทเค็นพ็อกเก็ตมือถือ และส่วนขยาย โทเค็นพ็อกเก็ต",
        requirement_2: "2. เว็บไซต์ที่ให้มานั้นสามารถเข้าถึงได้และเสถียร",
        requirement_3: "3. สัญญาอัจฉริยะได้ปรับใช้บน mainnet และส่วนที่ละเอียดอ่อนต้องเป็นโอเพ่นซอร์ส",
        requirement_4: "4. สัญญาที่มีความละเอียดอ่อนต้องมีรายงานการตรวจสอบจากหน่วยงานรักษาความปลอดภัยบุคคลที่สาม",
        requirement_5: "5. ตรรกะการโต้ตอบมีความชัดเจนและได้รับการปรับให้เข้ากับ UI บนมือถือแล้ว",
        requirement_6: "6. ปฏิบัติตามกฎหมายและข้อบังคับที่เกี่ยวข้อง โดยปราศจากการฉ้อโกงและการละเมิด",
        requirement_7: "7. หากคุณละเมิดกฎหมายและข้อบังคับที่เกี่ยวข้อง คุณจะต้องรับผิดชอบทางกฎหมายที่เกี่ยวข้องโดยสมัครใจ",
        dappInfo: "ข้อมูลดีแอป",
        necessary: "จำเป็น",
        language: "ภาษาดีแอป",
        languageDesc: "(โปรดส่งแยกกันสำหรับหลายภาษา)",
        en: "English",
        zh: "中文简体",
        zhTW: "中文繁体",
        descDesc: "คำอธิบายสั้น ๆ ของโครงการในหนึ่งประโยค ซึ่งจะปรากฏในคำบรรยายของดีแอป",
        auditInfo: "ข้อมูลการตรวจสอบ",
        hasAudit: "ไม่ว่าจะมีการตรวจสอบสัญญาหรือไม่",
        auditUrl: "รายงานการตรวจสอบURL",
        auditUrlExample: "ตัวอย่างเช่น: https://auditlink.com",
        auditReport: "รายงานการตรวจสอบ",
        auditUpload: "อัพโหลด",
        contact: "รายละเอียดการติดต่อ",
        contactDesc: "โปรดทิ้งข้อมูลติดต่อฝ่ายบริการลูกค้านอกเหนือจากกล่องจดหมาย มิฉะนั้น จะไม่ผ่านการตรวจสอบ",
        emailAddr: "อีเมล",
        emailExample: "ตัวอย่างเช่น: service@yourdomain.com",
        others: "อื่นๆ",
        othersExample: "ตัวอย่างเช่น: โทรเลข：@123456789",
        auditOptional: "ใส่ข้อมูลการตรวจสอบอย่างน้อยหนึ่งรายการ",
        oversize: " เกินอักขระที่ผ่านการรับรอง",
        select: "เลือก",
        tutorial_url: "https://help.tokenpocket.pro/developer-en/",
        tutorial: "ดูบทช่วยสอน>>"
    },
    DOWNLOAD: {
        TITLE: "ดาวน์โหลด โทเค็นพ็อกเก็ต",
        TITLE_Android: "โทเค็นพ็อกเก็ต สำหรับ แอนดรอยด์",
        TITLE_IOS: "โทเค็นพ็อกเก็ต สำหรับ iOS",
        TITLE_Chrome: "โทเค็นพ็อกเก็ตสำหรับเบราว์เซอร์ของคุณ",
        TEXT: "โทเค็นพ็อกเก็ตเป็นกระเป๋าเงินดิจิตอลเข้ารหัสหลายสาย ใช้งานง่ายและปลอดภัยซึ่งคนนับล้านไว้วางใจ",
        TEXT_Chrome: "โทเค็นพ็อกเก็ต Extension เป็นกระเป๋าเงินดิจิตอลเข้ารหัสหลายสาย รองรับ EVM ที่เข้ากันได้กับ EVM ทั้งหมด ง่ายและปลอดภัยในการใช้งานที่ได้รับความไว้วางใจจากคนนับล้าน",
        TEXT_PC: "โทเค็นพ็อกเก็ต Desktop เป็นกระเป๋าเงิน multi-blockchain ที่ใหญ่ที่สุดตาม EOS ETH BOS TRON เรามุ่งมั่นที่จะมอบการจัดการสินทรัพย์ดิจิทัลที่มีประสิทธิภาพและปลอดภัยแก่ผู้ใช้",
        scanCode: "Scan to Download",
        installTutorial: "Install Tutorial",
        desc_1: "ดาวน์โหลดแอปจากเว็บไซต์อย่างเป็นทางการและตรวจสอบใบรับรอง SSL",
        desc_2: "ปกป้อง Recovery Phrase (mnemonic) และ Private Key ของคุณไม่ให้รั่วไหล อย่าแชร์ให้ผู้อื่นทราบ",
        desc_3: "เรียนรู้เคล็ดลับความปลอดภัยเพิ่มเติม",
        verifyText: "เวอร์ชัน แอนดรอย ล่าสุด",
        verifyText1: "วิธีตรวจสอบความปลอดภัยของแอป",
        verifyText2: "เวอร์ชันล่าสุด:",
        verifyText3: "เวอร์ชันล่าสุดของ Google Play",
        footerTitle: "สร้างความประทับใจแรกพบที่ยอดเยี่ยม",
        footerDesc_1: "รองรับ BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST และอื่นๆ",
        footerDesc_2: "การป้องกันความปลอดภัยหลายชั้น",
        footerDesc_3: "รองรับ DeFi, DApp, GameFi และ NFT",
        coming_soon: "Coming Soon",
        desc_tp_wallet: "TP Global Ltd ได้รับการแต่งตั้งอย่างเป็นทางการให้เป็นผู้เผยแพร่แอป iOS ของ TokenPocket แต่เพียงผู้เดียว",
        tp_wallet_version: "TP Wallet รุ่น:",
        token_pocket_version: "Token Pocket รุ่น:",
        delisted: "ถูกเพิกถอน"
    },
    TOKEN: {
        RULES: {
            email: "จำเป็นต้องใช้อีเมล",
            address: "ต้องระบุที่อยู่",
            owner_address: "ต้องระบุที่อยู่ของเจ้าของ",
            symbol: "ต้องมีสัญลักษณ์",
            bl_symbol: "จำเป็นต้องใช้สัญลักษณ์ BL",
            total_supply: "ต้องจัดหาทั้งหมด",
            decimal: "ต้องมีทศนิยม",
            precision: "ต้องมีความแม่นยำ",
            gas: "จำเป็นต้องใช้ GAS",
            website: "จำเป็นต้องมีเว็บไซต์",
            companyName: "จำเป็นต้องระบุชื่อบริษัทหรือบุคคล",
            contact: "ต้องติดต่อ",
            name: "จำเป็นต้องมีการแนะนำโครงการ",
            icon_url: "ต้องมีโลโก้"
        },
        icon: "โลโก้โทเค็น (200 * 200 พิกเซล รองรับ jpg, jpeg, png, รูปแบบการตั้งชื่อไฟล์รูปภาพ: ชื่อโทเค็น โลโก้ไม่ต้องการมุมมน)",
        handleText: "เราจะดำเนินการตามคำขอของคุณใน 2 วันทำการ"
    },
    RECRUITING: {
        title: "TP Man Recruitment Plan",
        text: "Join the TokenPocket community",
        text1: "Committed to the blockchain world and contribute your part",
        text2: "We build a Web3.0 world together",
        joinUs: "Join Us",
        aboutTitle: "About TP Man",
        aboutText: "TP Man is an important part of the TokenPocket community, and we sincerely invite you to join us!",
        aboutText1: "You are a blockchain enthusiast and endorse the industry value.",
        aboutText2: "Enjoy the convenience brought by TokenPocket wallet when you explore the blockchain world.",
        missionTitle: "The Mission of TP Man",
        missionText: "Help TokenPocket to serve more blockchain users around the world. We hope you, meet two of the following requirements to apply.",
        missionText1: "Expand and promote TokenPocket cooperation with companies or hot projects in your country through various channels",
        missionText2: "Plan marketing activities that meet the needs of local users",
        missionText3: "Have the ability to operate mainstream social medias such as Twitter, Youtube, Telegram, and Discord",
        missionText4: "Fluent in English, and be able to complete translation work",
        missionText5: "TokenPocket plans to provide more usage and technical support for global blockchain users, so we hope that you have a certain understanding of the blockchain markets of no less than one country and their users (India, the United States, Turkey, Russia, South Korea, Vietnam, the Philippines, etc.)",
        getTitle: "What will you get?",
        getText: "A work experience directly involved with various fields of the blockchain industry, and you will get but not limited to communication opportunities with DApp projects, Influencers, and mainstream media in the industry.",
        getText1: "Get rich rewards from your work such as tweets translation, making video, community operation and business cooperation.",
        getText2: "Get the most professional blockchain knowledge training and explore the Web3.0 world with the team together.",
        getText3: "TokenPocket official benefits, including TokenPocket Swag and hardware wallets.",
        processTitle: "Recruitment process",
        processText: "Submit CV",
        processText1: "CV screening",
        processText2: "Online interview ",
        processText3: "Interview results",
        processText4: "Welcome aboard",
        applyTitle: "Who can apply",
        applyText: "Face the world, regardless of country",
        applyText1: "Be keen and curious about the blockchain world",
        applyText2: "Fill out the form and attach your resume, then we will contact you as soon as possible",
        footerTitle: "About TokenPocket",
        footerText: "over",
        footerText1_1: "20M",
        footerText1_2: "global users",
        footerText2_1: "3.5M",
        footerText2_2: "monthly active users",
        footerText3_1: "200",
        footerText3_2: "countries and regions",
        footerText4: "TokenPocket is the world's leading multi-chain self-custodial wallet",
        footerText5: "Coming soon"
    },
    NAVIGATION: {
        product: {
            title: "ผลิตภัณฑ์",
            selfCustodyWallet: "กระเป๋าเงินมือถือ",
            selfCustodyWalletDesc: "คริปโต&DeFi กระเป๋าเงินมือถือ บนบล็อคเชน",
            hardwareWallet: "กระเป๋าเงินฮาร์ดแวร์",
            hardwareWalletDesc: "รับ KeyPal ของคุณเพื่อปกป้องทรัพย์สินของคุณ",
            extensionWallet: "กระเป๋าสเงินเสริม",
            extensionWalletDesc: "กระเป๋าเงินที่ดีกว่าบนคอมพิวเตอร์ของคุณ",
            transit: "ขนส่ง",
            transitDesc: "Multi-chain DEX Aggregator และ  NFT Marketplace แพลตฟอร์ม",
            fiveDegrees: "5องศา",
            fiveDegreesDesc: "โซเชียลเน็ตเวิร์กโปรโตคอลใน Web3.0"
        },
        assets: {
            title: "ทรัพย์สิน"
        },
        collaborations: {
            title: "ความร่วมมือ"
        },
        community: {
            title: "ชุมชน",
            developers: "นักพัฒนา",
            recruiting: "ชายTP"
        },
        helpCenter: {
            title: "ช่วย"
        }
    },
    ABOUT: {
        title: "About Us",
        desc: "TokenPocket is a multi-chain decentralized wallet, it provides users with mobile wallet, extension wallet and hardware wallet, supporting public chains including Bitcoin, Ethereum, BNB Smart Chain, TRON, Aptos, Polygon, Solana, Polkadot, EOS and all EVM compatible chains. Serving over 20 millions users from more than 200 countries and regions. It is a world-wide leading crypto wallet that trusted by global users.",
        philosophy: {
            title: "Our philosophy",
            desc: "We insist on an open technology community, and we welcome all developers to build a more convenient, secure and richer blockchain world together",
            ambition: "Ambition",
            ambition_desc: "Make the blockchain happen everywhere",
            value: "Value",
            value_desc: "Let data return to users, make value belong to real owners",
            attitude: "Attitude",
            attitude_desc: "Open-minded, mutual collaboration"
        },
        milestones: {
            title: "Milestones",
            desc_2018_1: "TokenPocket Founded",
            desc_2018_2: "Invested by Huobi, Hofan, Byte Capital",
            desc_2019_1: "Released desktop wallet, supported TRON",
            desc_2019_2: "Google Play download exceeded 1,000,000",
            desc_2020_1: "HD wallet supported",
            desc_2020_2: "Supported BSC and DeFi tendency",
            desc_2020_3: "Supported Eth2.0 Staking",
            desc_2021_1: "Incubated Transit",
            desc_2021_2: "User base exceeded 20,000,000",
            desc_2021_3: "Incubated KeyPal hardware wallet",
            desc_2022_1: "Acquired dFox and rebranded to TokenPocket Extension",
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December"
        },
        contact_us: {
            title: "Contact Us",
            service: "Customer Service",
            service_desc: "service@tokenpocket.pro",
            bd: "Business Collaborations",
            bd_desc: "bd@tokenpocket.pro",
            developers: "Developers",
            developers_desc: "Discord"
        }
    },
    SEO: {
        title: "TokenPocket - Your universal digital wallet | TP wallet - ETH wallet - BTC wallet - BSC wallet - HECO wallet - OKExChain wallet - Polkadot wallet - Kusama wallet - DeFi wallet - Layer 2 wallet - EOS wallet - TRX wallet",
        description: "โทเค็นพ็อกเก็ต เป็นกระเป๋าเงินสกุลเงินดิจิทัลชั้นนำของโลก รองรับบล็อคเชนสาธารณะรวมถึง BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS and เลเยอร์ 2.",
        keywords: "โทเค็นพ็อกเก็ต,โทเค็นพ็อกเก็ต,กระเป๋าเงินTP,กระเป๋าเงินEthereum ,Bitcoin,EOS,IOST,COSMOS,heco,bsc,เลเยอร์2,DeFi,กระเป๋าเงิน,crypto,blockchain,web3,NFT"
    }
};
