
export default {
    demo: {
        demo: 'demo',
    },
        COMMON: {
            EMAIL: "이메일",
            BATCH_SENDER: "일괄 송신자",
            YES: "예",
            NO: "아니요",
            HAS: "예",
            HAVENT: "아니요",
            BLOCKCHAIN: "블록체인",
            MULTIPLE_CHOICE: "(다중 선택)",
            IS_SUPPORT_TP_CONNECT: "TokenPocket 연결을 지원합니까? (확장 및 모바일 앱)",
            SUPPORT_BOTH: "둘 다 지원됩니다",
            SUPPORT_EXTENSION: "확장만",
            SUPPORT_MOBILE: "모바일 전용",
            SUPPORT_NONE: "없음",
            blockchainWallet: "블록체인 지갑",
            iostWallet: "IOST 지갑",
            tronWallet: "TRON 지갑",
            platformInfo: {
                eos: "EOS",
                moac: "MOAC",
                eth: "ETH",
                jt: "Jingtum ",
                enu: "ENU",
                bos: "BOS",
                iost: "IOST",
                cosmos: "COSMOS",
                bnb: "BNB",
                trx: "TRON",
                bsc: "BSC",
                heco: "HECO",
                okex: "OKExChain",
                dot: "Polkadot",
                hsc: "HSC",
                matic: "Polygon",
                klaytn: "Klaytn",
                arb: "Arbitrum One",
                arbn: "Arbirtum Nova",
                ftm: "Fantom",
                etc: "Ethereum Classic",
                cfx: "Conflux eSpace",
                solana: "Solana",
                avax: "AVAX",
                oe: "Optimistic Ethereum",
                xdai: "Gnosis Chain",
                harmony: "Harmony",
                wax: "WAX",
                aurora: "Aurora",
                ksm: "Kusama",
                mb: "Moonbeam",
                sbg: "SubGame",
                kcc: "KCC Mainnet",
                lat: "PlatON",
                bttc: "BTTC",
                gt: "GateChain",
                halo: "HALO",
                aptos: "Aptos"
            },
            submit: "제출",
            symbol: "토큰 이름",
            bl_symbol: "베이스 라인 심볼을 입력해주세요",
            precision: "정밀도를 입력해주세요",
            decimal: "소수점을 입력해주세요",
            totalSupply: "총 공급량",
            contract: "토큰 컨트렉",
            website: "웹사이트를 입력해주세요",
            introduction: "토큰 소개",
            example: "예시",
            submitTokenInfoAndLogo: "토큰 정보 및 로고 제출",
            toGithubSubmit: "Github 커밋으로 이동",
            nftType: "NFT 유형",
            LAYOUT: {
                features: "기능",
                buyCrypto: "암호화폐 구매",
                mobileWallet: "모바일 지갑",
                hardwareWallet: "하드웨어 지갑",
                extensionWallet: "플러그인 지갑",
                desktop: "데스크탑 지갑",
                fiveDegrees: "5Degrees",
                approvalDetector: "감지 승인",
                tokenSecurity: "토큰 보안",
                keyGenerator: "열쇠 생성기",
                information: "정보",
                blockchainGuide: "Blockchain 지갑 가이드라인",
                tronWallet: "TRON 지갑 가이드라인",
                iostWallet: "IOST 지갑 가이드라인",
                tpMan: "TP 맨",
                developers: "개발자",
                github: "Github (TP-Lab)",
                devCenter: "개발자 센터",
                subToken: "토큰 제출하기",
                subDApp: "DApp 체출하기",
                subNFT: "NFT 제출",
                subChain: "체인",
                company: "회사",
                about: "에 대한",
                careers: "직업",
                pressKit: "프레스 키트",
                swagShop: "스웨그 샵",
                support: "서포트",
                helpCenter: "도움 센터",
                contactUs: "연락주세요",
                legal: "법률",
                privacyPolicy: "개인 정보 보호 정책",
                terms: "이용 약관",
                toHome: "홈",
                defiWallet: "DeFi 지갑",
                ETHWallet: "이더리움 지갑",
                ethWallet: "eth 지갑"
            }
        },
        HOME: {
            download: "다운로드",
            downloadNow: "지금 다운로드",
            HEADER: {
                title: "블록체인을 탐색할 수 있는 안전한 암호화폐 지갑",
                desc_1: "토큰을 구입, 저장, 전송, 교환 및 수집하기 쉽고 안전합니다. 200개 이상의 국가 및 지역에서 2,000만 명 이상의 사용자가 사용합니다."
            },
            INTRODUCTION: {
                title: "토큰포켓은 글로벌 사용자가 신뢰합니다",
                desc_1: "토큰포켓은 전 세계 200여 개 국가와 지역에 안전하고 간편한 암호화폐 지갑 서비스를 제공하고 있습니다",
                desc_2: "서비스 사용자",
                desc_3: "일 트랜잭션",
                desc_4: "지원 국가 및 지역"
            },
            SECURITY: {
                title: "보안은 필수적입니다",
                desc_1: "토큰포켓 사용자는 자신의 기기에서만 키와 비밀번호를 생성하고 저장하며 사용자 자신만이 계정과 자산에 액세스할 수 있습니다.",
                desc_2: "토큰포켓은 또한 하드웨어 콜드 지갑 및 다중 서명 지갑 기능을 개발하여 필수에 따라 보안을 강화 가능합니다.",
                desc_3: "BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, Cosmos, Polkadot, EOS, IOST 등을 지원합니다."
            },
            EXCHANGE: {
                title: "쉬운 거래 및 전송",
                desc_1: "토큰포켓에서 언제 어디서나 암호화폐를 거래할 수 있습니다.",
                desc_2: "신용 카드로 암호화폐를 구매 가능. 쉬운 저장, 전송, 크로스 체인 및 거래.",
                desc_3: "스왑",
                desc_4: "쉽고 빠른",
                desc_5: "브릿지",
                desc_6: "다른 체인들 중",
                desc_7: "암호화폐 구매",
                desc_8: "5분 안에"
            },
            DAPPSTORE: {
                title: "댑 스토어",
                desc_1: "가장 좋아하는 탈중앙화 애플리케이션을 찾을 수 있으며, 최신 및 가장 인기있는 디앱을 찾고 지갑 내에서 사용 가능합니다.",
                desc_2: "DApp 브라우저가 통합되어 링크를 통해 디앱을 이용 가능합니다.",
                desc_3: "DeFi",
                desc_4: "NFT",
                desc_5: "DApp",
                desc_6: "지원"
            },
            COMMUNITY: {
                title: "커뮤니티",
                desc_1: "토큰포켓은 오픈 기술 커뮤니티이며 더 편리하고 안전하며 풍부한 블록체인 세상을 함께 구축할 모든 개발자를환영합니다",
                desc_2: "TP-Lab",
                desc_3: "커뮤니티",
                desc_4: "개발자 문서"
            },
            DOWNLOAD: {
                title: "토큰포켓 지갑을 지금 다운로드하세요!",
                desc_1: "블록체인 탐색을 위한 안전하고 신뢰할 수 있는 암호화 지갑"
            },
            FOLLOW: {
                title: "우리를 따라오세요",
                desc1: "TokenPocket 직원은 개인 메시지를 보내지 않습니다!",
                desc2: "주의! TokenPocket 커뮤니티에 참여 중입니다. 누군가 우리를 사칭하여 개인 메시지를 보낼 수 있습니다! 개인 메시지를 보내는 사람은 모두 사기꾼일 수 있다는 점에 유의하세요! 우리는 당신에게 먼저 연락하지 않을 것입니다!",
                desc3: "이해, 입력"
            },
            EXTENSIONMODAL: {
                title: "확장 기능이 활성화되었습니다!",
                desc1: "귀하의 Crypto & DeFi & GameFi",
                desc2: "컴퓨터의 지갑",
                btnText: "지금 사용",
                btnTextm: "링크 복사",
                tips: "링크를 성공적으로 복사하십시오. 열려면 컴퓨터로 이동하십시오"
            }
        },
        DAPP: {
            RULES: {
                platform: "platform 명칭을 입력해주세요",
                title: "DApp 명칭을 입력해주세요.",
                address: "DApp컨트렉을 입력해주세요.",
                url: "DApp웹사이트 주소를 입력해주세요.",
                desc: "모든분의 자기소개 문구를 입력해주세요.",
                icon_url: "DApp 아이콘을 업로드 해주세요.",
                rakeBackAccount: "초대 리워드 컨트렉 계정을 입력해주세요.",
                email: "이메일을 입력해주세요",
                others: "기타 연락처 정보가 필요합니다",
                tp_connect: "이 항목은 비워둘 수 없습니다."
            },
            title: "DApp 이름",
            address: "DApp 컨트렉 주소",
            url: "DApp 웹사이트 주소",
            desc: "짦은 소개문구",
            icon: "DApp 로고 (반드시 200x200 - JPG, PNG 지원)",
            referral: "리퍼럴",
            hasReferral: "리퍼럴 시스템이 있습니까?",
            referralReward: "초대 리워드 분배방식",
            reward_1: "초대 리워드 분배방식",
            reward_2: "초대하신분은 DApp내부에서 수동으로 수령하셔야 합니다.",
            hasInviteReward: "초대하신 분은 DApp내부에서 1회의 트랜섹션을 완료한후 초대 리워드를 활성화 할수 있습니다.",
            inviteAccount: "초청 보상을 TP에 공개하기위한 계약 계정",
            DAppRequirement: "DApp 요구사항",
            requirement_1: "1. DApp은 TokenPocket 앱 및 TokenPocket 확장을 지원해야 합니다..",
            requirement_2: "2. 프로젝트측에서 제공해주신 웹사이트 주소는 접근 가능하며 안전해야 합니다.",
            requirement_3: "3. 스마트 컨트렉은 이미 메인넷에 응용이 되었으며 민감한 부분에 대하여 오픈소스로 전환해야 합니다.",
            requirement_4: "4. 민감한 스마트 컨트렉은 제3자를 통해 안전성에 대한 검증 및 리포트가 필요합니다.",
            requirement_5: "5. 상호적용 로직이 분명하며 모바일 유저인터페이스에도 응용이 되어야 합니다.",
            requirement_6: "6. 관련되 법과 규제를 준수하며 범법및 침해행위를 하면 안됩니다.",
            requirement_7: "7. 관련 법규를 위반할 경우 자발적으로 해당 법적 책임을지게됩니다. ",
            dappInfo: "DApp 정보 :",
            necessary: "필수",
            language: "DApp 언어",
            languageDesc: "(언어가 다양하면 별도로 제출하세요)",
            en: "English",
            zh: "中文简体",
            zhTW: "中文繁体",
            descDesc: "한 문장으로 된 프로젝트에 대한 간략한 설명",
            auditInfo: "감사 정보 :",
            hasAudit: "계약 감사가 수행되었는지 여부",
            auditUrl: "감사 보고서 URL",
            auditUrlExample: "예 : https://auditlink.com",
            auditReport: "감사 보고서",
            auditUpload: "업로드",
            contact: "연락처 세부 정보",
            contactDesc: "우편함 이외의 고객 서비스 연락처 정보를 남겨 두십시오, 그렇지 않으면 검토를 통과하지 않을 것입니다",
            emailAddr: "이메일",
            emailExample: "예 : service@yourdomain.com",
            others: "기타",
            othersExample: "예 : Telegram ： @ 123456789",
            auditOptional: "감사 정보 항목을 하나 이상 입력하십시오",
            oversize: " 정규화 된 문자를 초과했습니다",
            select: "선택",
            tutorial_url: "https://help.tokenpocket.pro/developer-en/",
            tutorial: "튜토리얼 보기>>"
        },
        DOWNLOAD: {
            TITLE: "토큰포켓 다운로드",
            TITLE_Android: "토큰포켓 안드로이드 버전",
            TITLE_IOS: "토큰포켓 iOS 버전",
            TITLE_Chrome: "토큰포켓 브라우저 버전",
            TEXT: "토큰포켓은 수백만 명이 사용하는 쉽고 안전한 멀티 체인 암호화 지갑입니다.",
            TEXT_Chrome: "토큰포켓 확장 지갑은 수백만 명이 쉽고 안전한 사용하며 모든 EVM 호환 체인을 지원하는 멀티 체인 암호화 지갑입니다.",
            TEXT_PC: "TokenPocket 데스크톱은 EOS, ETH, BOS 그리고 TRON을 지원하는 가장 큰 멀티 블록체인 지갑입니다. 저희는 강력하고 안전한 디지털 자산 관리 솔루션을 제공해드립니다.",
            scanCode: "코드 스캔",
            installTutorial: "튜토리얼 설치",
            desc_1: "공식 웹 사이트에서 앱을 다운로드하고 SSL 인증을 확인하십시오",
            desc_2: "복구 구문(니모닉) 및 개인 키가 유출되지 않도록 안전하게 보관하고 타인과 절대 공유하지 마세요",
            desc_3: "보안 팁 더 알아보기",
            verifyText: "현재 APK 버전:",
            verifyText1: "앱의 보안을 확인하는 방법",
            verifyText2: "현재 버전:",
            verifyText3: "현재 Google Play 버전:",
            footerDesc_1: "BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST 등 지원",
            footerDesc_2: "멀티 레이어 보안 보호 기능 제공",
            footerDesc_3: "DeFi, DApp, GameFi, NFT 지원",
            coming_soon: "Coming Soon",
            desc_tp_wallet: "TP Global Ltd는 공식적으로 TokenPocket의 유일한 iOS 앱 퍼블리셔로 지정되었습니다",
            tp_wallet_version: "TP Wallet 버전:",
            token_pocket_version: "Token Pocket 버전:",
            delisted: "상장폐지"
        },
        TOKEN: {
            RULES: {
                email: "이메일을 입력해주세요.",
                address: "컨트렉주소를 입력해세요.",
                owner_address: "컨트렉 계정주소를 입력해주세요.",
                symbol: "심볼을 입력해주세요.",
                bl_symbol: "베이스 라인 심볼을 입력해주세요.",
                total_supply: "총 공급량을 입력해주세요.",
                decimal: "소수점을 입력해주세요.",
                precision: "정밀도를 입력해주세요.",
                gas: "기스 비용을 입력해주세요.",
                website: "웹사이트를 입력해주세요.",
                companyName: "회사 혹은 개인 이름을 입력해주세요.",
                contact: "연락처를 입력해주세요.",
                name: "프로젝트 소개를 입력해주세요.",
                icon_url: "로고를 올려주세요."
            },
            icon: "토큰로고(200 * 200 픽셀, jpg, jpeg, png 포맷을 지원합니다).",
            handleText: "저희가 2 업무일 안에 처리해드리겠습니다."
        },
        RECRUITING: {
            title: "TP Man 채용계획",
            text: "TokenPocket 커뮤니티 가입",
            text1: "블록체인 세계에 헌신하고 당신의 몫을 기여하십시오",
            text2: "우리는 함께 Web3.0 세상을 만듭니다",
            joinUs: "우리와 함께",
            aboutTitle: "티피맨 소개",
            aboutText: "TP Man은 TokenPocket 커뮤니티의 중요한 부분이며 글로벌 TP Men의 회원이 되실 수 있도록 진심으로 초대합니다!",
            aboutText1: "당신은 블록체인 애호가이며 업계 가치를 인정합니다.",
            aboutText2: "블록체인 세계를 탐험할 때 TokenPocket 지갑이 제공하는 편리함을 즐기십시오.",
            missionTitle: "TP맨의 미션",
            missionText: "TokenPocket이 전 세계의 더 많은 블록체인 사용자에게 서비스를 제공할 수 있도록 도와주세요. 다음 요구 사항 중 두 가지를 충족하여 지원하시기 바랍니다.",
            missionText1: "다양한 채널을 통해 국내 기업이나 핫한 프로젝트와의 협력을 확대하고 추진하세요.",
            missionText2: "지역 사용자의 요구에 맞는 마케팅 활동을 계획합니다.",
            missionText3: "Twitter, Youtube, Telegram, Discord와 같은 주류 소셜 미디어를 운영할 수 있는 능력이 있어야 합니다.",
            missionText4: "영어가 유창하고 번역 작업을 완료할 수 있습니다.",
            missionText5: "TokenPocket은 글로벌 블록체인 사용자에게 더 많은 사용 및 기술 지원을 제공할 계획이므로 적어도 한 국가와 해당 사용자(인도, 미국, 터키, 러시아, 한국, 베트남, 필리핀 등)",
            getTitle: "무엇을 얻습니까?",
            getText: "블록체인 산업의 다양한 분야와 직접 관련된 업무 경험으로 DApp 프로젝트, 인플루언서 및 업계의 주류 미디어와의 커뮤니케이션 기회를 얻을 수 있습니다.",
            getText1: "트윗 번역, 영상 제작, 커뮤니티 운영, 비즈니스 협력 등 풍부한 보상을 확보하세요.",
            getText2: "가장 전문적인 블록체인 지식 교육을 받고 팀과 함께 Web3.0 세계를 탐험하십시오.",
            getText3: "TokenPocket 맞춤형 의류 및 하드웨어 지갑을 포함한 TokenPocket 공식 혜택.",
            processTitle: "채용 과정",
            processText: "이력서 제출",
            processText1: "화면 이력서",
            processText2: "온라인 인터뷰",
            processText3: "면접 결과",
            processText4: "작업 확장",
            applyTitle: "신청할 수 있는 사람",
            applyText: "나라를 가리지 않고 세상을 마주하다",
            applyText1: "블록체인 세계에 대한 관심과 호기심",
            applyText2: "양식을 작성하고 이력서를 첨부하면 최대한 빨리 연락 드리겠습니다.",
            footerTitle: "에 대한 TokenPocket",
            footerText: "초과하다",
            footerText1_1: "20M",
            footerText1_2: "글로벌 사용자",
            footerText2_1: "3.5M",
            footerText2_2: "활성 사용자",
            footerText3_1: "200",
            footerText3_2: "국가 및 지역",
            footerText4: "양식을 작성하고 이력서를 첨부하면 최대한 빨리 연락 드리겠습니다.",
            footerText5: "출시 예정"
        },
        NAVIGATION: {
            product: {
                title: "생산품",
                selfCustodyWallet: "모바일 지갑",
                selfCustodyWalletDesc: "블록체인 진입, 모바일 멀티체인 지갑",
                hardwareWallet: "하드웨어 지갑",
                hardwareWalletDesc: "키팔로 자산을 보호하고 안전하고 안심하세요.",
                extensionWallet: "플러그인 지갑",
                extensionWalletDesc: "너의 컴퓨터에 더 좋은 지갑을 놓아라",
                transit: "Transit",
                transitDesc: "멀티체인 DEX 애그리게이터 및 NFT 마켓플레이스 플랫폼",
                fiveDegrees: "5Degrees",
                fiveDegreesDesc: "Web3.0의 소셜 네트워크 프로토콜"
            },
            assets: {
                title: "자산"
            },
            collaborations: {
                title: "협업"
            },
            community: {
                title: "커뮤니티",
                developers: "개발자",
                recruiting: "모병"
            },
            helpCenter: {
                title: "도움"
            }
        },
        ABOUT: {
            title: "소개",
            desc: "토큰포켓'은 멀티 체인 탈중앙화 지갑으로 모바일 지갑, 웹 지갑 그리고 하드웨어 지갑을 제공하며 비트코인, 이더리움, 바이낸스 스마트 체인, 트론, 폴리곤, 솔라나, 앱토스, 폴카닷, EOS 및 모든 EVM 호환 체인을 지원합니다. 200개 이상의 국가 및 지역에서 2,000만 명 이상의 사용자에게 서비스를 제공합니다. 전 세계 이용자들의 신뢰를 받은 세계적인 암호화폐 지갑입니다.",
            philosophy: {
                title: "철학",
                desc: "우리는 오픈 소스 기술 커뮤니티를 추구하며, 모든 개발자가 보다 편리하고 안전하며 풍부한 블록체인 세계를 함께 구축하기를 환영합니다.",
                ambition: "포부",
                ambition_desc: "블록체인을 모든 곳에 사용 될 수 있도록 만들겠습니다.",
                value: "가치",
                value_desc: "데이터를 사용자에게 돌려주고 가치를 실제 소유자가 가질 수 있게 합니다.",
                attitude: "태도",
                attitude_desc: "열린 마음으로 상호 협력합니다."
            },
            milestones: {
                title: "이정표",
                desc_2018_1: "토큰포켓 창립",
                desc_2018_2: "Huobi, Hofan, Byte Capital로부터 투자 유치",
                desc_2019_1: "트론 지원 데스크탑 지갑 출시",
                desc_2019_2: "구글 플레이, 토큰포켓 앱 다운로드 100만 건 돌파",
                desc_2020_1: "HD 지갑 지원",
                desc_2020_2: "BSC 및 DeFi 프로젝트 지원",
                desc_2020_3: "이더리움 2.0 스테이킹 지원",
                desc_2021_1: "스왑 플랫폼 Transit 인큐베이팅",
                desc_2021_2: "사용자 2천만명 돌파",
                desc_2021_3: "하드웨어 지갑 KeyPal 인큐베이팅",
                desc_2022_1: "dFox 인수 후 토큰포켓 웹 확장 지갑으로 브랜드 변경",
                January: "1월",
                February: "2월",
                March: "3월",
                April: "4월l",
                May: "5월",
                June: "6월",
                July: "7월",
                August: "8월",
                September: "9월",
                October: "10월",
                November: "11월",
                December: "12월"
            },
            contact_us: {
                title: "문의하기",
                service: "고객 서비스",
                service_desc: "service@tokenpocket.pro",
                bd: "사업 협업",
                bd_desc: "bd@tokenpocket.pro",
                developers: "개발자",
                developers_desc: "Discord"
            }
        },
        SEO: {
            title: "토큰포켓 - 여러분의 범용 디지털 지갑입니다. | TP 지갑 - ETH  지갑 - BTC  지갑 - BSC  지갑 - HECO 지갑 - OKExChain 지갑 - Polkadot 지갑 - Kusama 지갑 - DeFi  지갑 - Layer 2 지갑 - EOS 지갑 - TRX 지갑",
            description: "TokenPocket is a world-leading digital currency wallet, supporting public blockchains including BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS and Layer 2.",
            keywords: "TokenPocket,Token Pocket,TP wallet,Ethereum wallet,Bitcoin,EOS,IOST,COSMOS,heco,bsc,layer2,DeFi,wallet,암호화, 블록체인, web3"
        }
};
