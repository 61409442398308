<template>
  <div id="app">
    <!-- <index-page /> -->
    <router-view />
  </div>
</template>

<script>
// import IndexPage from "./view/index.vue";

export default {
  name: "App",
  components: {
    // IndexPage,
  },
};
</script>

<style>
#app {
  width: 100%;
}
</style>
