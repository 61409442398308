export default {
    demo: {
        demo: 'demo',
    },
    COMMON: {
        EMAIL: "Email",
        BATCH_SENDER: "Batchsender",
        YES: "Oo",
        NO: "Hindi",
        HAS: "Oo",
        HAVENT: "Hindi",
        BLOCKCHAIN: "Blockchain",
        WALLET_GUIDE: "Gabay sa Wallet",
        MULTIPLE_CHOICE: "(Maraming pagpipilian)",
        IS_SUPPORT_TP_CONNECT: "Sinusuportahan ba nito ang koneksyon ng TokenPocket? (Extension at Mobile app)",
        SUPPORT_BOTH: "Parehong sinusuportahan",
        SUPPORT_EXTENSION: "Tanging Extension",
        SUPPORT_MOBILE: "Tanging mobile",
        SUPPORT_NONE: "wala",
        blockchainWallet: "Blockchain Wallet",
        iostWallet: "IOST Wallet",
        tronWallet: "TRON Wallet",
        platformInfo: {
            eos: "EOS",
            moac: "MOAC",
            eth: "ETH",
            jt: "Jingtum ",
            enu: "ENU",
            bos: "BOS",
            iost: "IOST",
            cosmos: "COSMOS",
            bnb: "BNB",
            trx: "TRON",
            bsc: "BSC",
            heco: "HECO",
            okex: "OKExChain",
            dot: "Polkadot",
            hsc: "HSC",
            matic: "Polygon",
            klaytn: "Klaytn",
            arb: "Arbitrum One",
            arbn: "Arbirtum Nova",
            ftm: "Fantom",
            etc: "Ethereum Classic",
            cfx: "Conflux eSpace",
            solana: "Solana",
            avax: "AVAX",
            oe: "Optimistic Ethereum",
            xdai: "Gnosis Chain",
            harmony: "Harmony",
            wax: "WAX",
            aurora: "Aurora",
            ksm: "Kusama",
            mb: "Moonbeam",
            sbg: "SubGame",
            kcc: "KCC Mainnet",
            lat: "PlatON",
            bttc: "BTTC",
            gt: "GateChain",
            halo: "HALO",
            aptos: "Aptos"
        },
        submit: "I-submit",
        symbol: "Pangalan ng Token",
        bl_symbol: "Simbolo ng BL",
        precision: "Precision ng Token",
        decimal: "Decimal ng Token",
        totalSupply: "Kabuuang Supply",
        contract: "Contract ng Token",
        website: "Opisyal na website",
        introduction: "Panimula ng Token",
        example: "Halimbawa",
        submitTokenInfoAndLogo: "I-update ang logo ng token at iba pang Impormasyon",
        toGithubSubmit: "Pumunta sa Github",
        nftType: "Pamantayan ng protocol ng NFT",
        LAYOUT: {
            features: "Mga tampok",
            buyCrypto: "Bumili ng Crypto",
            mobileWallet: "Mobile na Wallet",
            hardwareWallet: "Hardware na Wallet",
            extensionWallet: "Extension na Wallet",
            desktop: "Desktop na Wallet",
            fiveDegrees: "5Degrees",
            approvalDetector: "Detektor ng Pag-apruba",
            tokenSecurity: "Seguridad ng Token",
            keyGenerator: "Key Generator",
            information: "Impormasyon",
            blockchainGuide: "Gabay sa Blockchain",
            tronWallet: "Gabay sa TRON",
            iostWallet: "Gabay sa IOST",
            tpMan: "TP Man",
            developers: "Mga developer",
            github: "Github (TP-Lab)",
            devCenter: "Dev Center",
            subToken: "I-submit ang Token",
            subDApp: "I-submit ang DApp",
            subNFT: "I-submit ang NFT",
            subChain: "Isumite ang Chain",
            company: "kumpanya",
            about: "Tungkol sa",
            careers: "Mga Career",
            pressKit: "Pindutin ang Kit",
            swagShop: "Swag Shop",
            support: "Suporta",
            helpCenter: "Help Center",
            contactUs: "Makipag-ugnayan sa amin",
            legal: "Legal",
            privacyPolicy: "Patakaran sa Privacy",
            terms: "Mga Tuntunin ng Paggamit",
            toHome: "Home",
            defiWallet: "DeFi na Wallet",
            ETHWallet: "Ethereum Wallet",
            ethWallet: "ETH na Wallet"
        }
    },
    HOME: {
        download: "I-download",
        downloadNow: "I-download na ngayon",
        HEADER: {
            title: "Ang iyong secure na crypto wallet para i-explore ang blockchain",
            desc_1: "Madali at ligtas na bumili, mag-imbak, magpadala, mag-swap ng mga token at mangolekta ng mga NFT. Pinagkakatiwalaan ng 20+ milyong user mula sa 200+ na bansa at rehiyon."
        },
        INTRODUCTION: {
            title: "Ang TokenPocket ay pinagkakatiwalaan ng mga global users",
            desc_1: "Nagbibigay kami ng ligtas at madaling serbisyo ng crypto wallet sa 200+ na bansa at rehiyon sa buong mundo",
            desc_2: "Naglilingkod sa mga user",
            desc_3: "Pang-araw-araw na Transaksyon",
            desc_4: "Pagsuporta sa mga bansa at rehiyon"
        },
        SECURITY: {
            title: "Seguridad tulad ng nararapat",
            desc_1: "Ang TokenPocket ay bumubuo at nag-iimbak ng mga key at password sa iyong device lamang, ikaw lang ang makaka-access sa iyong account at mga asset.",
            desc_2: "Gumagawa din ang TokenPocket ng hardware cold wallet at feature na multi-sign wallet para mapahusay ang seguridad ayon sa kailangan mo.",
            desc_3: "Sinusuportahan ang BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, Cosmos, Polkadot, EOS, IOST at iba pa."
        },
        EXCHANGE: {
            title: "Exchange & Madaling Transact",
            desc_1: "Maaari mong i-trade ang iyong crypto anumang oras, kahit saan sa loob ng TokenPocket.",
            desc_2: "Bumili ng crypto gamit ang mga credit card. Mag-imbak, magpadala, mag-cross chain at mag-exchange ng madali.",
            desc_3: "I-swap",
            desc_4: "Agad at madali",
            desc_5: "Bridge",
            desc_6: "Kabilang sa iba't ibang mga chain",
            desc_7: "Bumili ng Crypto",
            desc_8: "Sa loob ng 5 minuto"
        },
        DAPPSTORE: {
            title: "Isang DApp Store",
            desc_1: "Maaari mong mahanap ang iyong mga paboritong desentralisadong application, tuklasin ang pinakabago at pinakamainit at gamitin ang mga ito nang hindi umaalis sa wallet.",
            desc_2: "Pinagsama ang DApp Browser, maaari mong palaging ma-access ang DApps gamit ang iyong mga link.",
            desc_3: "DeFi",
            desc_4: "NFT",
            desc_5: "DApp",
            desc_6: "Sinusuportahan"
        },
        COMMUNITY: {
            title: "Komunidad",
            desc_1: "Iginigiit namin ang isang bukas na komunidad ng teknolohiya, at tinatanggap namin ang lahat ng mga developer na bumuo ng isang mas maginhawa, secure at mas mayamang mundo ng blockchain nang magkasama.",
            desc_2: "TP-Lab",
            desc_3: "Komunidad",
            desc_4: "Dev Docs"
        },
        DOWNLOAD: {
            title: "Kunin ang TokenPocket Wallet ngayon!",
            desc_1: "Ang iyong secure at pinagkakatiwalaang crypto wallet para i-explore ang blockchain"
        },
        FOLLOW: {
            title: "Sundan mo kami",
            desc1: "Ang TokenPocket Staffs ay hindi magpapadala sa iyo ng mga pribadong mensahe!",
            desc2: "Ingat! Pumapasok ka sa komunidad ng TokenPocket, maaaring may gumagaya sa amin para magpadala sa iyo ng mga pribadong mensahe! Mangyaring magkaroon ng kamalayan na, sinuman na nagpapadala ng mga pribadong mensahe ay maaaring isang scammer! Hindi ka muna namin makokontak!",
            desc3: "Naiintindihan, pumasok"
        },
        EXTENSIONMODAL: {
            title: "Live na ngayon ang extension!",
            desc1: "Ang iyong Crypto at DeFi at GameFi",
            desc2: "wallet sa computer",
            btnText: "Gamitin Ito Ngayon",
            btnTextm: "Kopyahin ang Link",
            tips: "Matagumpay na kopyahin ang link, mangyaring pumunta sa computer para buksan"
        }
    },
    DAPP: {
        RULES: {
            platform: "Kinakailangan ang platform",
            title: "Kinakailangan ang Pangalan ng DApp",
            address: "Kinakailangan ang Contract ng DApp",
            url: "Kinakailangan ang Website ng DApp",
            desc: "Kinakailangan ang desc",
            icon_url: "Kinakailangan ang Logo ng DApp",
            rakeBackAccount: "Kinakailangan ang account ng Contract",
            email: "Kinakailangan ang email",
            others: "Kinakailangan ang iba pang impormasyon sa pakikipag-ugnayan",
            tp_connect: "Hindi maaaring blangko ang item na ito"
        },
        title: "Pangalan ng DApp",
        address: "Smart Contract",
        url: "Website ng DApp",
        desc: "Maikling Description",
        icon: "Logo ng DApp (Dapat ay 200x200 - sumusuporta sa JPG, PNG.)",
        referral: "Referral",
        hasReferral: "May referral system ba ito",
        referralReward: "Ang pamamahagi ng reward sa referral",
        reward_1: "Awtomatikong ipamahagi sa pamamagitan ng smart contract (Live)",
        reward_2: "Kailangang i-claim ito sa DApp nang Manu-mano",
        hasInviteReward: "Kailangan bang gumawa ng transaksyon ang nag-imbita sa DApp para ma-activate ang referral link",
        inviteAccount: "Ang smart contract ng referral distribution",
        DAppRequirement: "DApp Requirement",
        requirement_1: "1. Kailangang suportahan ng DApp ang TokenPocket mobile at TokenPocket extension.",
        requirement_2: "2. Ang ibinigay na website ay naa-access at stable.",
        requirement_3: "3. Ang mga smart contract ay na-deploy sa mainnet, at ang sensitibong bahagi ay nangangailangan na maging open source.",
        requirement_4: "4. Ang mga sensitibong contract ay nangangailangan ng mga ulat sa pag-audit mula sa mga ahensya ng seguridad ng third-party.",
        requirement_5: "5. Ang logic ng pakikipag-ugnayan ay malinaw at inangkop sa mobile UI.",
        requirement_6: "6. Sundin ang mga nauugnay na batas at regulasyon, nang walang pandaraya at paglabag.",
        requirement_7: "7. Kung lalabag ka sa mga nauugnay na batas at regulasyon, kusang-loob mong aakohin ang kaukulang mga legal na responsibilidad.",
        dappInfo: "Impormasyon ng DApp:",
        necessary: "kailangan",
        language: "Wika ng DApp",
        languageDesc: "(Mangyaring i-submit nang hiwalay para sa maraming wika)",
        en: "English",
        zh: "中文简体",
        zhTW: "中文繁体",
        descDesc: "Isang maikling paglalarawan ng proyekto sa isang pangungusap, na lalabas sa subtitle ng DApp",
        auditInfo: "Impormasyon sa pag-audit:",
        hasAudit: "Kung ang pag-audit ng contract ay naisagawa",
        auditUrl: "Url ng ulat ng audit",
        auditUrlExample: "Halimbawa: https://auditlink.com",
        auditReport: "Ulat sa Pag-audit",
        auditUpload: "Mag-upload",
        contact: "Mga detalye ng contact",
        contactDesc: "Pakitiyak na iwanan ang impormasyon sa pakikipag-ugnayan sa customer service maliban sa mailbox, kung hindi, hindi ito papasa sa pagsusuri",
        emailAddr: "Email",
        emailExample: "Halimbawa: service@yourdomain.com",
        others: "Iba pa",
        othersExample: "Halimbawa: Telegram：@123456789",
        auditOptional: "Punan ang hindi bababa sa isang item ng impormasyon sa pag-audit",
        oversize: " ay lumampas sa mga kwalipikadong character",
        select: "Mag-select",
        tutorial_url: "https://help.tokenpocket.pro/developer-en/",
        tutorial: "Tingnan ang tutorial>>"
    },
    DOWNLOAD: {
        TITLE: "I-download ang TokenPocket",
        TITLE_Android: "TokenPocket para sa Android",
        TITLE_IOS: "TokenPocket para sa iOS",
        TITLE_Chrome: "TokenPocket para sa iyong browser",
        TEXT: "Ang TokenPocket ay isang multi-chain na crypto wallet, madali at secure na gamitin na pinagkakatiwalaan ng milyun-milyon.",
        TEXT_Chrome: "Ang TokenPocket Extension ay isang multi-chain na crypto wallet, lahat ng EVM compatible na chain ay sinusuportahan. Madali at secure na gamitin ang pinagkakatiwalaan ng milyun-milyon.",
        TEXT_PC: "Ang TokenPocket Desktop ay ang pinakamalaking multi-blockchain wallet na nakabatay sa EOS ETH BOS TRON, nagsusumikap kaming magbigay ng malakas at secure na pamamahala ng digital asset sa mga user.",
        scanCode: "Scan to Download",
        installTutorial: "I-install ang Tutorial",
        desc_1: "I-download ang app mula sa opisyal na website at suriin ang SSL certification nito",
        desc_2: "Protektahan ang iyong Recovery Phrase (mnemonic) at Private Key mula sa pag-leaking, huwag na huwag itong ibahagi sa iba",
        desc_3: "Matuto pa ng mga tip sa seguridad",
        verifyText: "Pinakabagong Bersyon ng Android:",
        verifyText1: "Paano i-verify ang seguridad ng isang app",
        verifyText2: "Pinakabagong bersyon",
        verifyText3: "Pinakabagong Bersyon ng Google Play:",
        footerDesc_1: "Sinusuportahan ang BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST at iba pa.",
        footerDesc_2: "Multi-layer na mga proteksyon sa seguridad",
        footerDesc_3: "Sinusuportahan ang DeFi, DApp, GameFi at NFT",
        coming_soon: "Coming Soon",
        desc_tp_wallet: "Ang TP Global Ltd ay opisyal na itinalaga bilang nag-iisang iOS App publisher ng TokenPocket",
        tp_wallet_version: "TP Wallet Bersyon:",
        token_pocket_version: "Token Pocket Bersyon:",
        delisted: "Na-delist"
    },
    TOKEN: {
        RULES: {
            email: "Kinakailangan ang email",
            address: "Kinakailangan ang address",
            owner_address: "Kinakailangan ang address ng may-ari",
            symbol: "Kinakailangan ang simbolo",
            bl_symbol: "Kinakailangan ang Simbolo ng BL",
            total_supply: "Kinakailangan ang kabuuang supply",
            decimal: "Kinakailangan ang decimal",
            precision: "Kinakailangan ang Precision",
            gas: "Kinakailangan ang GAS",
            website: "Kailangan ang website",
            companyName: "Kinakailangan ang pangalan ng kumpanya o personal",
            contact: "Kinakailangan ang Contact",
            name: "Kinakailangan ang Introduction ng proyekto",
            icon_url: "Kinakailangan ang logo"
        },
        icon: "Logo ng Token (200 * 200 pixels, sumusuporta sa jpg, jpeg, png, format ng pagpapangalan ng file ng imahe: pangalan ng token, hindi kailangan ng mga bilog na sulok ang logo)",
        handleText: "Ipoproseso namin ang iyong kahilingan sa loob ng 2 working days"
    },
    RECRUITING: {
        title: "TP Man Recruitment Plan",
        text: "Sumali sa komunidad ng TokenPocket",
        text1: "Nakatuon sa mundo ng blockchain at mag-ambag ng iyong bahagi",
        text2: "Bumuo kami ng isang Web3.0 na mundo nang magkasama",
        joinUs: "Sumali sa amin",
        aboutTitle: "Tungkol sa TP Man",
        aboutText: "Ang TP Man ay isang mahalagang bahagi ng komunidad ng TokenPocket, at taos-puso kaming nag-aanyaya sa iyo na sumali sa amin!",
        aboutText1: "Ikaw ay isang mahilig sa blockchain at ini-endorso ang halaga ng industriya.",
        aboutText2: "Tangkilikin ang kaginhawaan na hatid ng TokenPocket wallet kapag i-explore mo ang mundo ng blockchain.",
        missionTitle: "Ang Misyon ng TP Man",
        missionText: "Tulungan ang TokenPocket na maghatid ng higit pang mga gumagamit ng blockchain sa buong mundo. Umaasa kami na matugunan mo ang dalawa sa mga sumusunod na kinakailangan para mag-apply.",
        missionText1: "Palawakin at isulong ang pakikipagtulungan ng TokenPocket sa mga kumpanya o maiinit na proyekto sa iyong bansa sa pamamagitan ng iba't ibang channel",
        missionText2: "Magplano ng mga aktibidad sa marketing na nakakatugon sa mga pangangailangan ng mga lokal na user",
        missionText3: "Magkaroon ng kakayahang magpatakbo ng mga pangunahing social media tulad ng Twitter, Youtube, Telegram, at Discord",
        missionText4: "Fluent sa Ingles, at makatapos ng gawaing pagsasalin",
        missionText5: "Plano ng TokenPocket na magbigay ng higit pang paggamit at teknikal na suporta para sa mga global na gumagamit ng blockchain, kaya umaasa kami na mayroon kang tiyak na pag-unawa sa mga merkado ng blockchain ng hindi bababa sa isang bansa at ang kanilang mga user (India, United States, Turkey, Russia, South Korea, Vietnam, Pilipinas, atbp.)",
        getTitle: "Ano ang makukuha mo?",
        getText: "Isang karanasan sa trabaho na direktang kasangkot sa iba't ibang larangan ng industriya ng blockchain, at makakakuha ka ngunit hindi limitado sa mga pagkakataon sa komunikasyon sa mga proyekto ng DApp, Influencer, at mainstream na media sa industriya.",
        getText1: "Makakuha ng masaganang gantimpala mula sa iyong trabaho gaya ng pagsasalin ng mga tweet, paggawa ng video, pagpapatakbo ng komunidad at pakikipagtulungan sa negosyo.",
        getText2: "Kunin ang pinakapropesyonal na pagsasanay sa kaalaman sa blockchain at galugarin ang mundo ng Web3.0 kasama ang koponan nang magkasama.",
        getText3: "Mga opisyal na benepisyo ng TokenPocket, kabilang ang TokenPocket Swag at mga wallet ng hardware.",
        processTitle: "Proseso ng pangangalap",
        processText: "I-submit ang CV",
        processText1: "Pagsusuri ng CV",
        processText2: "Online na panayam ",
        processText3: "Mga resulta ng panayam",
        processText4: "Welcome sa aboard",
        applyTitle: "Sino ang maaaring mag-apply",
        applyText: "Harapin ang mundo, anuman ang bansa",
        applyText1: "Maging masigasig at mausisa tungkol sa mundo ng blockchain",
        applyText2: "Punan ang form at ilakip ang iyong resume, pagkatapos ay makikipag-ugnayan kami sa iyo sa lalong madaling panahon",
        footerTitle: "Tungkol sa TokenPocket",
        footerText: "sa buo",
        footerText1_1: "20M",
        footerText1_2: "pandaigdigang mga user",
        footerText2_1: "3.5M",
        footerText2_2: "buwanang aktibong user",
        footerText3_1: "200",
        footerText3_2: "mga bansa at rehiyon",
        footerText4: "Ang TokenPocket ay ang nangungunang multi-chain na self-custodial wallet sa buong mundo",
        footerText5: "Malapit na"
    },
    NAVIGATION: {
        product: {
            title: "Produkto",
            selfCustodyWallet: "Mobile na Wallet",
            selfCustodyWalletDesc: "Crypto&DeFi Mobile Wallet sa Blockchain.",
            hardwareWallet: "Hardware na Wallet",
            hardwareWalletDesc: "Kunin ang Iyong KeyPal, para Bantayan ang Iyong Mga Asset.",
            extensionWallet: "Extension na Wallet",
            extensionWalletDesc: "Isang Mas Mahusay na Wallet sa Iyong Computer.",
            transit: "Transit",
            transitDesc: "Multi-chain DEX Aggregator at NFT Marketplace Platform.",
            fiveDegrees: "5Degrees",
            fiveDegreesDesc: "Social Network Protocol sa Web3.0."
        },
        assets: {
            title: "Mga asset"
        },
        collaborations: {
            title: "pakikipag-kolaborasyon"
        },
        community: {
            title: "Komunidad",
            developers: "mga developer",
            recruiting: "TP Man"
        },
        helpCenter: {
            title: "Tulong"
        }
    },
    ABOUT: {
        title: "Tungkol sa Amin",
        desc: "Ang TokenPocket ay isang multi-chain na desentralisadong wallet, nagbibigay ito sa mga user ng mobile wallet, extension wallet at hardware wallet, na sumusuporta sa mga public chain kabilang ang Bitcoin, Ethereum, BNB Smart Chain, TRON, Polygon, Solana, Aptos, Polkadot, EOS at lahat ng EVM compatible chain. . Naglilingkod sa mahigit 20 milyong user mula sa mahigit 200 bansa at rehiyon. Ito ay isang nangungunang crypto wallet sa buong mundo na pinagkakatiwalaan ng mga global user.",
        philosophy: {
            title: "Ang aming Pilosopiya",
            desc: "Iginigiit namin ang isang bukas na komunidad ng teknolohiya, at tinatanggap namin ang lahat ng mga developer na bumuo ng isang mas maginhawa, secure at mas mayamang mundo ng blockchain nang magkasama.",
            ambition: "Ambisyon",
            ambition_desc: "Gawin ang blockchain na mangyari sa lahat ng dako",
            value: "kahalagahan",
            value_desc: "Hayaang bumalik ang data sa mga user, gawing halaga ang pagmamay-ari ng mga tunay na may-ari",
            attitude: "Saloobin",
            attitude_desc: "Bukas ang isipan, pagtutulungan sa isa't isa"
        },
        milestones: {
            title: "Milestones",
            desc_2018_1: "Itinatag ang TokenPocket",
            desc_2018_2: "Namuhunan ng Huobi, Hofan, Byte Capital",
            desc_2019_1: "Inilabas ang desktop wallet, suportado ang TRON",
            desc_2019_2: "Ang pag-download ng Google Play ay lumampas sa 1,000,000",
            desc_2020_1: "Sinusuportahan ang HD wallet",
            desc_2020_2: "Sinusuportahan ang BSC at DeFi tendency",
            desc_2020_3: "Sinusuportahan ang Eth2.0 Staking",
            desc_2021_1: "Incubated Transit",
            desc_2021_2: "User base exceeded 20,000,000",
            desc_2021_3: "Incubated KeyPal hardware wallet",
            desc_2022_1: "Nakuha ang dFox at na-rebranded sa TokenPocket Extension",
            January: "Enero",
            February: "Pebrero",
            March: "Marso",
            April: "Abril",
            May: "Mayo",
            June: "Hunyo",
            July: "Hulyo",
            August: "Agosto",
            September: "Setyembre",
            October: "Oktubre",
            November: "Nobyembre",
            December: "Disyembre"
        },
        contact_us: {
            title: "Makipag-ugnayan sa amin",
            service: "Serbisyo sa Customer",
            service_desc: "service@tokenpocket.pro",
            bd: "Pakikipagtulungan sa Negosyo",
            bd_desc: "bd@tokenpocket.pro",
            developers: "Mga Developer",
            developers_desc: "Discord"
        }
    },
    SEO: {
        title: "TokenPocket - Ang iyong universal digital wallet | TP wallet - ETH wallet - BTC wallet - BSC wallet - HECO wallet - OKExChain wallet - Polkadot wallet - Kusama wallet - DeFi wallet - Layer 2 wallet - EOS wallet - TRX wallet",
        description: "Ang TokenPocket ay isang world-leading digital currency wallet, na sumusuporta sa mga public blockchain kabilang ang BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS at Layer 2.",
        keywords: "TokenPocket,Token Pocket,TP wallet,Ethereum wallet,Bitcoin,EOS,IOST,COSMOS,heco,bsc,layer2,DeFi,wallet,crypto,blockchain,web3"
    }

};
