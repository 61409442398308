
export default {
    demo: {
        demo: 'demo',
    },
        COMMON: {
            EMAIL: "Email",
            BATCH_SENDER: "Batchsender",
            YES: "Sim",
            NO: "Não",
            HAS: "Sim",
            HAVENT: "Não",
            BLOCKCHAIN: "Blockchain",
            MULTIPLE_CHOICE: "(Escolha múltipla)",
            IS_SUPPORT_TP_CONNECT: "Suporta conexão com TokenPocket (app móvel e extensão)?",
            SUPPORT_BOTH: "Ambas são suportadas",
            SUPPORT_EXTENSION: "Apenas extensão",
            SUPPORT_MOBILE: "Apenas móvel",
            SUPPORT_NONE: "Nenhuma",
            blockchainWallet: "Carteira Blockchain",
            iostWallet: "Carteira IOST",
            tronWallet: "Carteira TRON",
            platformInfo: {
                eos: "EOS",
                moac: "MOAC",
                eth: "ETH",
                jt: "Jingtum ",
                enu: "ENU",
                bos: "BOS",
                iost: "IOST",
                cosmos: "COSMOS",
                bnb: "BNB",
                trx: "TRON",
                bsc: "BSC",
                heco: "HECO",
                okex: "OKExChain",
                dot: "Polkadot",
                hsc: "HSC",
                matic: "Polygon",
                klaytn: "Klaytn",
                arb: "Arbitrum One",
                arbn: "Arbirtum Nova",
                ftm: "Fantom",
                etc: "Ethereum Classic",
                cfx: "Conflux eSpace",
                solana: "Solana",
                avax: "AVAX",
                oe: "Optimistic Ethereum",
                xdai: "Gnosis Chain",
                harmony: "Harmony",
                wax: "WAX",
                aurora: "Aurora",
                ksm: "Kusama",
                mb: "Moonbeam",
                sbg: "SubGame",
                kcc: "KCC Mainnet",
                lat: "PlatON",
                bttc: "BTTC",
                gt: "GateChain",
                halo: "HALO",
                aptos: "Aptos"
            },
            submit: "Submeter",
            symbol: "Nome do Token",
            bl_symbol: "Símbolo BL",
            precision: "Precisão do Token",
            decimal: "Decimais do Token",
            totalSupply: "Fornecimento total",
            contract: "Contrato do token",
            website: "Site Oficial",
            introduction: "Introdução do Token",
            example: "Exemplo",
            submitTokenInfoAndLogo: "Actualizar Logo do Token e outras informações",
            toGithubSubmit: "Ir para Github",
            nftType: "Protocolo estandardizado NFT",
            LAYOUT: {
                features: "características",
                buyCrypto: "Comprar cripto",
                mobileWallet: "Carteira móve",
                hardwareWallet: "Carteira de Hardware",
                extensionWallet: "Extensão da Carteira",
                desktop: "Carteira Desktop",
                fiveDegrees: "5Degrees",
                approvalDetector: "Detector de Aprovação",
                tokenSecurity: "Segurança do Token",
                keyGenerator: "Gerador de Chaves",
                information: "Informação",
                blockchainGuide: "Guia da Blockchain",
                tronWallet: "Guia TRON",
                iostWallet: "Guia IOST",
                tpMan: "Homem TP",
                developers: "Programadores",
                github: "Github (TP-Lab)",
                devCenter: "Centro de Programadores",
                subToken: "Submeter Token",
                subDApp: "Submeter DApp",
                subNFT: "Submeter NFT",
                subChain: "Submeter Chain",
                company: "Companhia",
                about: "Acerca (de)",
                careers: "Carreiras",
                pressKit: "Kit de Imprensa",
                swagShop: "Loja Swag",
                support: "Suporte",
                helpCenter: "Centro de Ajuda",
                contactUs: "Contacta-nos",
                legal: "Legal",
                privacyPolicy: "Política de Privacidade",
                terms: "Termos de Utilização",
                toHome: "Página principal",
                defiWallet: "Carteira DeF",
                ETHWallet: "Carteira Ethereum",
                ethWallet: "Carteira ETH"
            }
        },
        HOME: {
            download: "Descarga",
            downloadNow: "Descarrega agora",
            HEADER: {
                title: "A tua carteira cripto segura para explorar a blockchain",
                desc_1: "Fácil e segura para comprar, armazenar, enviar, trocar tokens e colecionar NFTs. Com a confiança de mais de 20 milhões de utilizadores de mais de 200 países e regiões"
            },
            INTRODUCTION: {
                title: "Utilizadores à escala global confiam na Tokenpocket",
                desc_1: "Providenciamos uma carteira segura e fácil de utilizar em mais de 200 países e regiões por todo o mundo",
                desc_2: "Servindo os utilizadores",
                desc_3: "Transações diárias",
                desc_4: "Suporta países e reguões"
            },
            SECURITY: {
                title: "Segurança como ela deve ser",
                desc_1: "A TokenPocket gera e armazena chaves e passwords no teu dispositivo apenas, só tu podes aceder à tua conta e aos teus fundos.",
                desc_2: "A TokenPocket também desenvolve carteiras frias e carteiras multi-sig a fim de melhorar a segurança de acordo com as tuas necessidades.",
                desc_3: "Suportando BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, Cosmos, Polkadot, EOS, IOST and so on."
            },
            EXCHANGE: {
                title: "Troca e transaciona facilmente",
                desc_1: "Podes negociar as tuas criptos a qualquer momento, em qualquer lado com a TokenPocket.",
                desc_2: "Compra criptos com cartão de crédito. Armazena, envia, troca de cadeira e negoceia com facilidade.",
                desc_3: "Swap",
                desc_4: "Instantânea e fácil",
                desc_5: "Bridge",
                desc_6: "Entre diferentes cadeias",
                desc_7: "Compra cripto",
                desc_8: "Em 5 minutos"
            },
            DAPPSTORE: {
                title: "Uma loja de DApps",
                desc_1: "Podes encontrar as tuas aplicações descentralizadas favoritas, descobrir as mais recentes e populares sem sair da carteira.",
                desc_2: "Explorador de DAapps integrado, podes sempre aceder às DApps com os teus links.",
                desc_3: "DeFi",
                desc_4: "NFT",
                desc_5: "DApp",
                desc_6: "Suportado"
            },
            COMMUNITY: {
                title: "Comunidade",
                desc_1: "Insistimos numa tecnologia aberta à comunidade e convidamos todos os programadores a desenvolver de uma forma mais conveniente e segura uma blockchain mais rica",
                desc_2: "TP-Lab",
                desc_3: "Comunidade",
                desc_4: "Dev Docs"
            },
            DOWNLOAD: {
                title: "Obtém uma carteira TokenPocket agora!",
                desc_1: "A tua carteira segura e confiável para explorar a blockchain"
            },
            FOLLOW: {
                title: "Siga-nos",
                desc1: "TokenPocket Os funcionários não enviarão mensagens privadas!",
                desc2: "uidado! Você está entrando na comunidade TokenPocket, pode haver alguém se passando por nós para lhe enviar mensagens privadas! Esteja ciente de que qualquer pessoa que envie mensagens privadas pode ser um golpista! Nós nunca entraremos em contato com você primeiro!",
                desc3: "Entendido, digite"
            },
            EXTENSIONMODAL: {
                title: "Extension is now live!",
                desc1: "Your Crypto & DeFi & GameFi",
                desc2: "wallet on computer",
                btnText: "Use It Now",
                btnTextm: "Copy Link",
                tips: "Copy the link successfully, please go to the computer to open"
            }
        },
        DAPP: {
            RULES: {
                platform: "Plataforma é necessária",
                title: "Nome da DApp é necessário",
                address: "Contrato da DApp é necessário",
                url: "Website é necessário",
                desc: "Descrição é necessária",
                icon_url: "Logo da DApp é necessário",
                rakeBackAccount: "Conta do contrato é necessária",
                email: "Email é necessário",
                others: "Outras informações de contacto são necessárias”",
                tp_connect: "Este campo não pode ser deixado em branco"
            },
            title: "Nome da DApp”",
            address: "Smart Contract",
            url: "ebsite da DApp",
            desc: "Curta descrição”",
            icon: "Logo da DApp (tem que ser 200x2000 - suporta JPG,PNG)",
            referral: "Referenciação",
            hasReferral: "em sistema de referenciação?",
            referralReward: "Distribuição das recompensas de referenciação",
            reward_1: "Distribui automaticamente pelo smart contract",
            reward_2: "Necessária a colheita manual na DApp",
            hasInviteReward: "O referenciador tem que efectuar uma transação na DApp para activar link de referenciação?",
            inviteAccount: "O referenciador tem que efectuar uma transação na DApp para activar link de referenciação",
            DAppRequirement: "Requerimentos da DApp",
            requirement_1: "1. A DApp precisa de suportar TokenPocket móvel e extensão TokenPocket.",
            requirement_2: "2. A DApp precisa de suportar TokenPocket móvel e extensão TokenPocket.",
            requirement_3: "3. Os smart contracts estão implantados na mainnet e a parte sensitiva tem que ser open source.",
            requirement_4: "4. Os contratos sensitivos requerem auditoria por parte de agentes de segurança terciários.",
            requirement_5: "5. A lógica de integração é clara e foi adapatda ao UI móvel.",
            requirement_6: "6. Obedece a leis e regulações relevantes, sem infringir a lei e sem fraude.",
            requirement_7: "7. Se violares leis e regulações relevantes, assumirá voluntariamente as respectivas responsabilidades legais.",
            dappInfo: "Informação da DApp:",
            necessary: "requerido",
            language: "Idioma da DApp",
            languageDesc: "(Por favor submeter vários idiomas separadamente)",
            en: "English",
            zh: "中文简体",
            zhTW: "中文繁体",
            descDesc: "Uma breve descrição do projecto numa só frase, que irá aparecer como subtítulo",
            auditInfo: "Informação sobre Auditoria:",
            hasAudit: "Se o contracto foi auditado",
            auditUrl: "URL do relatório de auditoria",
            auditUrlExample: "For example: https://auditlink.com",
            auditReport: "Relatório de Auditoria",
            auditUpload: "Carregar",
            contact: "Detalhes de contacto",
            contactDesc: "Por favor assegura-te que forneces informação de contacto relativa ao serviço de apoio ao cliente para além do endereço de email, caso contrário não será aprovada",
            emailAddr: "Email",
            emailExample: "por exemplo: service@yourdomain.com",
            others: "Outros",
            othersExample: "por exemplo: Telegram：@123456789",
            auditOptional: "Preenche pelo menos um dos items acerca da auditoria",
            oversize: "excedeu o número de caracteres",
            select: "selecionar",
            tutorial_url: "https://help.tokenpocket.pro/developer-en/",
            tutorial: "Ver tutorial>>"
        },
        DOWNLOAD: {
            TITLE: "Descarregar TokenPocket",
            TITLE_Android: "TokenPocket para Android",
            TITLE_IOS: "TokenPocket para iOS",
            TITLE_Chrome: "TokenPocket para explorador",
            TEXT: "A TokenPocket é uma carteira cripto multi-cadeia, fácil e segura de utilizar, confiada por milhões.",
            TEXT_Chrome: "A extensão TokenPocket é uma carteira cripto multi-cadeia que suporta todas as cadeiras EVM. Fácil e segura, confiada por milhões.",
            TEXT_PC: "A TokenPocket Desktop é a maior carteira multi-cadeira EOS ETH BOS TRON, providenciamos uma gestão segura e poderosa dos teus activos.",
            scanCode: "Scan to Download",
            installTutorial: "Install Tutorial",
            desc_1: "Descarrega a app do website oficial e verifica a certificação SSL",
            desc_2: "Protege a tua frase de recuperação (mnemónica) e a tua chave privada, nunca partilhes com terceiros",
            desc_3: "Aprende mais dicas de segurança",
            verifyText: "última versão APK:",
            verifyText1: "Como verificar a segurança de uma app",
            verifyText2: "última versão:",
            verifyText3: "última versão no Google Play:",
            footerDesc_1: "Suporta BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST and so on.",
            footerDesc_2: "Várias camadas de protecção",
            footerDesc_3: "DeFi, DApp, GameFi and NFT supported",
            coming_soon: "Coming Soon",
            desc_tp_wallet: "A TP Global Ltd foi oficialmente nomeada como a única editora de aplicativos iOS do TokenPocket",
            tp_wallet_version: "TP Wallet Versão:",
            token_pocket_version: "Token Pocket Versão:",
            delisted: "Removido"
        },
        TOKEN: {
            RULES: {
                email: "Email é necessário",
                address: "Endereço é necessário",
                owner_address: "Endereço do proprietário é necessário",
                symbol: "Símbolo é necessário",
                bl_symbol: "Símbolo BL é necessário",
                total_supply: "Fornecimento total é necessário",
                decimal: "Decimais são necessários",
                precision: "Precisão é necessária",
                gas: "Gas é necessário",
                website: "Website é necessário",
                companyName: "Companhia ou nome pessoal é necessário",
                contact: "Contacto é necessário",
                name: "Introdução ao projecto é necessária",
                icon_url: "Logo é necessário"
            },
            icon: "Logo do token (200*200 pixels, suportando jpg, jpeg, png, formato do nome do ficheiro de imagem: nome do token, logo não necessita de cantos arredondados",
            handleText: "Processaremos o teu pedido em 2 dias úteis"
        },
        RECRUITING: {
            title: "Plano de Recrutamento Homem TP",
            text: "Junta-te à comunidade TokenPocket",
            text1: "Comprometidos com a blockchain, contribui também",
            text2: "Juntos construímos um mundo Web3.0",
            joinUs: "Junta-te a nós",
            aboutTitle: "Acerca do Homem TP",
            aboutText: "O Homem TP é uma parte importante da comunidade TokenPocket, convidamos-te a juntares-te a nós!",
            aboutText1: "És um entusiasta da blockchain e apoias o valor da industria",
            aboutText2: "Disfruta da conveniência trazida pela carteira TokenPocket na exploração da blockchain",
            missionTitle: "A missão do Homem TP",
            missionText: "Ajuda a TokenPocket a servir mais utilizadores por todo o mundo. Esperamos que preenchas aos dois seguintes requisitos",
            missionText1: "Expandir e promover a cooperação da Tokenpocket com outras companhias ou projectos no teu país através de vários canais",
            missionText2: "Planear actividades de marketing dirigidas aos utilizadores locais",
            missionText3: "Ter a habilidade para operar redes sociais tais como Twitter, Youtube, Telegram ou Discord",
            missionText4: "Fluência em inglês e ser capaz de realizar traduções",
            missionText5: "A TokenPocket planeia expandir a sua utilização bem como suporte técnico por isso esperamos que tenhas algum entendimento sobre a blockchain e os seus mercados em pelo menos um país (Índia, Estados Unidos, Turquia, Russia, Coreia do Sul, Vietname, Filipinas, etc",
            getTitle: "O que irei receber?",
            getText: "Uma experiência de trabalho em várias áreas da industria da blockchain e irás ter oportunidade de comunicar com outros projectos, influenciadores e meios de comunicação mainstream",
            getText1: "Ganha recompensas pelos teus trabalhos, sejam eles traduções de tweets, vídeos, trabalho junto da comunidade ou desenvolvimento de negócios",
            getText2: "Acede a conhecimentos profissionais e vamos explorar a Web3.0 juntos, em equipa",
            getText3: "Benefícios oficiais da TokenPocket, incluindo TokenPocket Swag e carteiras frias",
            processTitle: "Processo de recrutamento",
            processText: "Submete o teu CV",
            processText1: "Análise de CV",
            processText2: "Entrevista Online",
            processText3: "Resultados da entrevista",
            processText4: "Sê bem-vindo",
            applyTitle: "Quem pode candidatar-se",
            applyText: "Aborda o mundo, independentemente do país",
            applyText1: "Aplica-te e sê curioso acerca da blockchain",
            applyText2: "Fill out the form and attach your resume, then we will contact you as soon as possible",
            footerTitle: "Preenche o formulário e anexa o teu CV, iremos contactar-te o mais breve possível",
            footerText: "mais",
            footerText1_1: "20M",
            footerText1_2: "Utilizadores globais",
            footerText2_1: "3.5M",
            footerText2_2: "utilizadores activos mensais",
            footerText3_1: "200",
            footerText3_2: "países e regiões",
            footerText4: "A TokenPocket é a carteira líder a nível mundial",
            footerText5: "Em breve"
        },
        NAVIGATION: {
            product: {
                title: "Produto",
                selfCustodyWallet: "Carteira móvel",
                selfCustodyWalletDesc: "Carteira móvel cripto e Defi na Blockchain.",
                hardwareWallet: "Carteira Hardware",
                hardwareWalletDesc: "Obtém a tua KeyPal, para guardar os teus activos.",
                extensionWallet: "Extensão de carteira",
                extensionWalletDesc: "Uma carteira melhor no teu computador.",
                transit: "Transit",
                transitDesc: "Agregador de DEX Multi-cadeira e mercado de NFT.",
                fiveDegrees: "5Degrees",
                fiveDegreesDesc: "Protocolo de Rede Social em Web3.0."
            },
            assets: {
                title: "Activos"
            },
            collaborations: {
                title: "Colaborações"
            },
            community: {
                title: "Comunidade",
                developers: "Programadores",
                recruiting: "Homem TP"
            },
            helpCenter: {
                title: "Ajuda"
            }
        },
        ABOUT: {
            title: "Acerca de Nós",
            desc: "A TokenPocket é uma carteira descentralizada multi-cadeia disponível em carteira móvel, extensão do explorador e carteira fria, suportando cadeias públicas tais como Bitcoin, Ethereum, BNB Smart Chain, TRON, Polygon, Solana, Aptos, Polkadot, EOS e todas as cadeias compatíveis com EVM. É uma carteira lider a nível mundial, com a confiança de mais de 20 milhões de utilizadores em mais de 200 países e regiões em todo o mundo",
            philosophy: {
                title: "A nossa filosofia",
                desc: "Acreditamos uma comunidade tecnológica aberta, pelo que convidamos todos os programadores de desenvolver uma blockchain mais rica conosco, de forma segura e conveniente",
                ambition: " Ambição",
                ambition_desc: "Fazer a blockchain acontecer em todo o lado",
                value: "Valor",
                value_desc: "Deixar os dados voltar aos utilizadores, fazer com que o valor pertença aos seus verdadeiros donos",
                attitude: "Atitude",
                attitude_desc: "Colaboração mútua de mente aberta"
            },
            milestones: {
                title: "Marcos alcançados",
                desc_2018_1: "Fundação da TokenPocket",
                desc_2018_2: "Investimento da Huobi, Hofan, Byte Capital",
                desc_2019_1: "Lançamento da carteira desktop, suportando TRON",
                desc_2019_2: "Mais de 1M de downloads na Google Play",
                desc_2020_1: "Suporte para carteira fria",
                desc_2020_2: "Suporte da BSC e plataformas DeFi",
                desc_2020_3: "Suporte de staking ETH2.0",
                desc_2021_1: "Incubação da TransitSwap",
                desc_2021_2: "Número de utilizadores ultrapassa os 20M",
                desc_2021_3: "Incubação da carteira fria KeyPal",
                desc_2022_1: "Aquisição da dFox e rebranding da mesma para Extensão TokenPocket",
                January: "Janeiro",
                February: "Fevereiro",
                March: "Março",
                April: "Abril",
                May: "Maio",
                June: "Junho",
                July: "Julho",
                August: "Agosto",
                September: "Setembro",
                October: "Outubro",
                November: "Novembro",
                December: "Dezembro"
            },
            contact_us: {
                title: "Contacta-nos",
                service: "Apoio ao Cliente",
                service_desc: "service@tokenpocket.pro",
                bd: "Propostas de Colaboração",
                bd_desc: "bd@tokenpocket.pro",
                developers: "Programadores",
                developers_desc: "Discord"
            }
        },
        SEO: {
            title: "TokenPocket - Your universal digital wallet | TP wallet - ETH wallet - BTC wallet - BSC wallet - HECO wallet - OKExChain wallet - Polkadot wallet - Kusama wallet - DeFi wallet - Layer 2 wallet - EOS wallet - TRX wallet",
            description: "A TokenPocket é uma carteira de activos digitais líder a nível mundial que suporta blockchains incluindo BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS e Layer 2",
            keywords: "TokenPocket,Token Pocket,TP wallet,Ethereum wallet,Bitcoin,EOS,IOST,COSMOS,heco,bsc,layer2,DeFi,wallet,crypto,blockchain,web3"
        }

};
