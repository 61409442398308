
export default {
    demo: {
        demo: 'demo',
    },
        COMMON: {
            EMAIL: "Email",
            BATCH_SENDER: "Batchsender",
            YES: "हाँ",
            NO: "नहीं",
            HAS: "हाँ",
            HAVENT: "नहीं",
            BLOCKCHAIN: "ब्लॉकचेन",
            MULTIPLE_CHOICE: "(बहुविकल्पी)",
            IS_SUPPORT_TP_CONNECT: "क्या यह टोकनपॉकेट कनेक्शन का समर्थन करता है? (एक्सटेंशन और मोबाइल ऐप)",
            SUPPORT_BOTH: "दोनों समर्थित हैं",
            SUPPORT_EXTENSION: "केवल एक्सटेंशन",
            SUPPORT_MOBILE: "केवल मोबाइल",
            SUPPORT_NONE: "कोई भी नहीं",
            blockchainWallet: "ब्लॉकचेन वॉलेट",
            iostWallet: "IOST वॉलेट",
            tronWallet: "TRON वॉलेट",
            platformInfo: {
                eos: "EOS",
                moac: "MOAC",
                eth: "ETH",
                jt: "Jingtum ",
                enu: "ENU",
                bos: "BOS",
                iost: "IOST",
                cosmos: "COSMOS",
                bnb: "BNB",
                trx: "TRON",
                bsc: "BSC",
                heco: "HECO",
                okex: "OKExChain",
                dot: "Polkadot",
                hsc: "HSC",
                matic: "Polygon",
                klaytn: "Klaytn",
                arb: "Arbitrum One",
                arbn: "Arbirtum Nova",
                ftm: "Fantom",
                etc: "Ethereum Classic",
                cfx: "Conflux eSpace",
                solana: "Solana",
                avax: "AVAX",
                oe: "Optimistic Ethereum",
                xdai: "Gnosis Chain",
                harmony: "Harmony",
                wax: "WAX",
                aurora: "Aurora",
                ksm: "Kusama",
                mb: "Moonbeam",
                sbg: "SubGame",
                kcc: "KCC Mainnet",
                lat: "PlatON",
                bttc: "BTTC",
                gt: "GateChain",
                halo: "HALO",
                aptos: "Aptos"
            },
            submit: "प्रस्तुत करना",
            symbol: "टोकन नाम",
            bl_symbol: "BL Symbol",
            precision: "टोकन प्रेसिजन",
            decimal: "टोकन दशमलव",
            totalSupply: "कुल आपूर्ति",
            contract: "टोकन अनुबंध",
            website: "आधिकारिक वेबसाइट",
            introduction: "टोकन परिचय",
            example: "उदाहरण",
            submitTokenInfoAndLogo: "टोकन लोगो और अन्य जानकारी अपडेट करें",
            toGithubSubmit: "Github पर जाएं",
            nftType: "NFT प्रोटोकॉल मानक",
            LAYOUT: {
                features: "विशेषताएँ",
                buyCrypto: "क्रिप्टो खरीदें",
                mobileWallet: "मोबाइल वॉलेट",
                hardwareWallet: "हार्डवेयर वॉलेट",
                extensionWallet: "एक्सटेंशन वॉलेट",
                desktop: "डेस्कटॉप वॉलेट",
                fiveDegrees: "5Degrees",
                approvalDetector: "अनुमोदन डिटेक्टर",
                tokenSecurity: "टोकन सुरक्षा",
                keyGenerator: "कुंजी जेनरेटर",
                information: "जानकारी",
                blockchainGuide: "ब्लॉकचेन गाइड",
                tronWallet: "ट्रॉन गाइड",
                iostWallet: "IOST गाइड",
                tpMan: "TP Man",
                developers: "डेवलपर्स",
                github: "Github (TP-Lab)",
                devCenter: "डेवलपर केंद्र",
                subToken: "टोकन जमा करें",
                subDApp: "DApp जमा करें",
                subNFT: "NFT जमा करें",
                subChain: "सार्वजनिक श्रृंखला जमा करें",
                company: "कंपनी",
                about: "के बारे में",
                careers: "करियर",
                pressKit: "प्रेस किट",
                swagShop: "स्वैग की दुकान",
                support: "सहायता",
                helpCenter: "सहायता केंद्र",
                contactUs: "संपर्क करें",
                legal: "वैध",
                privacyPolicy: "गोपनीयता नीति",
                terms: "उपयोग की शर्तें",
                toHome: "होम",
                defiWallet: "DeFi वॉलेट",
                ETHWallet: "Ethereum वॉलेट",
                ethWallet: "ETH वॉलेट"
            }
        },
        HOME: {
            download: "डाउनलोड",
            downloadNow: "अब डाउनलोड करो",
            HEADER: {
                title: "ब्लॉकचेन का पता लगाने के लिए आपका सुरक्षित क्रिप्टो वॉलेट",
                desc_1: "खरीदने, स्टोर करने, भेजने, टोकन की अदला-बदली करने और NFTs एकत्र करने में आसान और सुरक्षित। 200+ देशों और क्षेत्रों के 20+ मिलियन उपयोगकर्ताओं द्वारा विश्वसनीय।"
            },
            INTRODUCTION: {
                title: "वैश्विक उपयोगकर्ताओं द्वारा टोकनपॉकेट पर भरोसा किया जाता है",
                desc_1: "हम दुनिया भर के 200+ देशों और क्षेत्रों में सुरक्षित और आसान क्रिप्टो वॉलेट सेवा प्रदान कर रहे हैं",
                desc_2: "उपयोगकर्ताओं की सेवा",
                desc_3: "दैनिक लेनदेन",
                desc_4: "सहायक देश और क्षेत्र"
            },
            SECURITY: {
                title: "सुरक्षा जैसी होनी चाहिए",
                desc_1: "टोकनपॉकेट केवल आपके डिवाइस पर कुंजी और पासवर्ड बनाता है और संग्रहीत करता है, केवल आप ही अपने खाते और संपत्ति तक पहुंच सकते हैं।",
                desc_2: "टोकनपॉकेट आपकी आवश्यकता के अनुसार सुरक्षा बढ़ाने के लिए हार्डवेयर कोल्ड वॉलेट और मल्टी-साइन वॉलेट सुविधा भी विकसित करता है।",
                desc_3: "BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, Cosmos, Polkadot, EOS, IOST इत्यादि का समर्थन करना।"
            },
            EXCHANGE: {
                title: "आसानी से एक्सचेंज और लेनदेन करें",
                desc_1: "आप टोकनपॉकेट के भीतर कभी भी, कहीं भी अपने क्रिप्टो का व्यापार कर सकते हैं।",
                desc_2: "क्रेडिट कार्ड से क्रिप्टो खरीदें। आसानी से स्टोर करें, भेजें, क्रॉस चेन और एक्सचेंज करें।",
                desc_3: "बदलना",
                desc_4: "तुरंत और आसानी से",
                desc_5: "Bridge",
                desc_6: "विभिन्न चेइनो के बीच",
                desc_7: "क्रिप्टो खरीदें",
                desc_8: "5 मिनट में"
            },
            DAPPSTORE: {
                title: "एक DApp स्टोर",
                desc_1: "आप अपने पसंदीदा विकेन्द्रीकृत एप्लिकेशन ढूंढ सकते हैं, नवीनतम और सबसे लोकप्रिय एप्लिकेशन खोज सकते हैं और बटुए को छोड़े बिना उनका उपयोग कर सकते हैं।",
                desc_2: "DApps ब्राउज़र एकीकृत, आप हमेशा अपने लिंक के साथ DApps तक पहुंच सकते हैं।",
                desc_3: "DeFi",
                desc_4: "NFT",
                desc_5: "DApp",
                desc_6: "समर्थित"
            },
            COMMUNITY: {
                title: "समुदाय",
                desc_1: "हम एक खुले प्रौद्योगिकी समुदाय पर जोर देते हैं, और हम सभी डेवलपर्स का एक साथ अधिक सुविधाजनक, सुरक्षित और समृद्ध ब्लॉकचेन दुनिया बनाने के लिए स्वागत करते हैं",
                desc_2: "TP-Lab",
                desc_3: "समुदाय",
                desc_4: "Dev Docs"
            },
            DOWNLOAD: {
                title: "टोकनपॉकेट वॉलेट अभी प्राप्त करें!",
                desc_1: "ब्लॉकचेन का पता लगाने के लिए आपका सुरक्षित और विश्वसनीय क्रिप्टो वॉलेट"
            },
            FOLLOW: {
                title: "हमारे पर का पालन करें",
                desc1: "TokenPocket कर्मचारी आपको निजी संदेश नहीं भेजेंगे!",
                desc2: "सावधानी! आप टोकनपॉकेट समुदाय में प्रवेश कर रहे हैं, हो सकता है कि कोई व्यक्ति आपको निजी संदेश भेजने के लिए प्रतिरूपित कर रहा हो! कृपया ध्यान रखें कि निजी संदेश भेजने वाला कोई भी व्यक्ति स्कैमर हो सकता है! हम आपसे पहले कभी संपर्क नहीं करेंगे!",
                desc3: "समझे, दर्ज करें"
            },
            EXTENSIONMODAL: {
                title: "एक्सटेंशन अब लाइव है!",
                desc1: "आपका क्रिप्टो और DeFi और GameFi",
                desc2: "कंप्यूटर पर वॉलेट",
                btnText: "इसे अब प्रयोग करो",
                btnTextm: "लिंक कॉपी करे",
                tips: "लिंक को सफलतापूर्वक कॉपी करें, कृपया खोलने के लिए कंप्यूटर पर जाएं"
            }
        },
        DAPP: {
            RULES: {
                platform: "प्लेटफार्म आवश्यक है",
                title: "DApp नाम की आवश्यक है",
                address: "DApp अनुबंध की आवश्यकता है",
                url: "DApp वेबसाइट की आवश्यक है",
                desc: "Desc की आवश्यक है",
                icon_url: "DApp लोगो की आवश्यक है",
                rakeBackAccount: "अनुबंध खाते की आवश्यकता है",
                email: "Email की जरूरत है",
                others: "अन्य संपर्क जानकारी की आवश्यकता है",
                tp_connect: "यह आइटम खाली नहीं हो सकता"
            },
            title: "DApp नाम",
            address: "स्मार्ट अनुबंध",
            url: "DApp वेबसाइट",
            desc: "संक्षिप्त वर्णन",
            icon: "DApp Logo (Must be 200x200 - supports JPG, PNG.)",
            referral: "रेफरल",
            hasReferral: "क्या इसमें रेफरल सिस्टम है",
            referralReward: "रेफरल इनाम का वितरण",
            reward_1: "स्मार्ट अनुबंध द्वारा स्वचालित रूप से वितरित करें (लाइव)",
            reward_2: "इसे मैन्युअल रूप से DApp पर दावा करने की आवश्यकता है",
            hasInviteReward: "क्या रेफरल लिंक को सक्रिय करने के लिए आमंत्रणकर्ता को DApp में लेनदेन करने की आवश्यकता है",
            inviteAccount: "रेफरल वितरण का स्मार्ट अनुबंध",
            DAppRequirement: "DApp आवश्यकता",
            requirement_1: "1. DApp को टोकनपॉकेट मोबाइल और टोकनपॉकेट एक्सटेंशन का समर्थन करने की आवश्यकता है।",
            requirement_2: "2. प्रदान की गई वेबसाइट सुलभ और स्थिर है।",
            requirement_3: "3. स्मार्ट कॉन्ट्रैक्ट्स मेननेट पर तैनात हैं, और संवेदनशील हिस्से को ओपन सोर्स होना चाहिए।",
            requirement_4: "4. संवेदनशील अनुबंधों के लिए तृतीय-पक्ष सुरक्षा एजेंसियों से ऑडिट रिपोर्ट की आवश्यकता होती है।",
            requirement_5: "5. इंटरैक्शन लॉजिक स्पष्ट है और इसे मोबाइल UI के लिए अनुकूलित किया गया है।",
            requirement_6: "6. धोखाधड़ी और उल्लंघन के बिना प्रासंगिक कानूनों और विनियमों का पालन करें।",
            requirement_7: "7. यदि आप प्रासंगिक कानूनों और विनियमों का उल्लंघन करते हैं, तो आप स्वेच्छा से संबंधित कानूनी जिम्मेदारियों को ग्रहण करेंगे।",
            dappInfo: "DApp जानकारी:",
            necessary: "आवश्यक",
            language: "DApp भाषा",
            languageDesc: "(कृपया अनेक भाषाओं के लिए अलग से सबमिट करें)",
            en: "English",
            zh: "中文简体",
            zhTW: "中文繁体",
            descDesc: "एक वाक्य में परियोजना का संक्षिप्त विवरण, जो DApp उपशीर्षक में दिखाई देगा",
            auditInfo: "लेखापरीक्षा जानकारी:",
            hasAudit: "क्या अनुबंध लेखापरीक्षा की गई है",
            auditUrl: "ऑडिट रिपोर्ट url",
            auditUrlExample: "उदाहरण के लिए: https://auditlink.com",
            auditReport: "परीक्षण विवरण",
            auditUpload: "अपलोड करना",
            contact: "संपर्क विवरण",
            contactDesc: "कृपया मेलबॉक्स के अलावा ग्राहक सेवा संपर्क जानकारी छोड़ना सुनिश्चित करें, अन्यथा यह समीक्षा पास नहीं करेगा",
            emailAddr: "Email",
            emailExample: "उदाहरण के लिए: service@yourdomain.com",
            others: "अन्य",
            othersExample: "उदाहरण के लिए: टेलीग्राम：@123456789",
            auditOptional: "ऑडिट जानकारी का कम से कम एक आइटम भरें",
            oversize: " योग्य वर्णों को पार कर गया है",
            select: "चयन",
            tutorial_url: "https://help.tokenpocket.pro/developer-en/",
            tutorial: "ट्यूटोरियल देखें >>"
        },
        DOWNLOAD: {
            TITLE: "टोकनपॉकेट डाउनलोड करें",
            TITLE_Android: "एंड्रॉयड के लिए टोकनपॉकेट",
            TITLE_IOS: "iOS के लिए टोकनपॉकेट",
            TITLE_Chrome: "आपके ब्राउज़र के लिए टोकनपॉकेट",
            TEXT: "टोकनपॉकेट एक बहु-श्रृंखला क्रिप्टो वॉलेट है, जिसका उपयोग करना आसान और सुरक्षित है, जिस पर लाखों लोग भरोसा करते हैं।",
            TEXT_Chrome: "टोकनपॉकेट एक्सटेंशन एक बहु-श्रृंखला क्रिप्टो वॉलेट है, जो सभी EVM संगत श्रृंखला समर्थित है। उपयोग करने में आसान और सुरक्षित, जिस पर लाखों लोग भरोसा करते हैं।",
            TEXT_PC: "TokenPocket Desktop सबसे बड़ा मल्टी-ब्लॉकचेन वॉलेट आधारित EOS ETH BOS TRON है, हम उपयोगकर्ताओं को एक शक्तिशाली और सुरक्षित डिजिटल एसेट मैनेजमेंट प्रदान करने का प्रयास करते हैं।",
            ALERT: "कृपया इस पेज को अपना मोबाइल फोन खोलें या कोबो वॉलेट इंस्टॉल करने के लिए दाईं ओर क्यूआर कोड स्कैन करें।",
            scanCode: "डाउनलोड करने के लिए स्कैन करें",
            installTutorial: "ट्यूटोरियल स्थापित करें",
            desc_1: "आधिकारिक वेबसाइट से ऐप डाउनलोड करें और इसके एसएसएल प्रमाणन की जांच करें",
            desc_2: "अपने पुनर्प्राप्ति वाक्यांश (स्मरक) और निजी कुंजी को लीक होने से बचाएं, इसे कभी भी दूसरों को साझा न करें",
            desc_3: "अधिक सुरक्षा युक्तियाँ जानें",
            verifyText: "नवीनतम एंड्रॉयड संस्करण:",
            verifyText1: "किसी app's की सुरक्षा कैसे सत्यापित करें",
            verifyText2: "नवीनतम संस्करण",
            verifyText3: "नवीनतम Google Play संस्करण:",
            footerTitle: "एक अच्छा पहला प्रभाव बनाएं",
            footerDesc_1: "BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST इत्यादि का समर्थन करना।",
            footerDesc_2: "बहु-परत सुरक्षा सुरक्षा",
            footerDesc_3: "DeFi, DApp, GameFi and NFT समर्थित",
            coming_soon: "Coming Soon",
            desc_tp_wallet: "टीपी ग्लोबल लिमिटेड को आधिकारिक तौर पर टोकनपॉकेट के एकमात्र आईओएस ऐप प्रकाशक के रूप में नियुक्त किया गया है",
            tp_wallet_version: "TP Wallet संस्करण:",
            token_pocket_version: "Token Pocket संस्करण:",
            delisted: "हटाए"
        },
        TOKEN: {
            RULES: {
                email: "Email की जरूरत है",
                address: "पता आवश्यक है",
                owner_address: "मालिक का पता आवश्यक है",
                symbol: "प्रतीक आवश्यक है",
                bl_symbol: "BL प्रतीक आवश्यक है",
                total_supply: "कुल आपूर्ति की आवश्यकता है",
                decimal: "दशमलव आवश्यक है",
                precision: "प्रेसिजन की आवश्यकता है",
                gas: "गैस की आवश्यकता है",
                website: "वेबसाइट की आवश्यकता है",
                companyName: "कंपनी या व्यक्तिगत नाम आवश्यक है",
                contact: "संपर्क आवश्यक है",
                name: "परियोजना परिचय की आवश्यकता है",
                icon_url: "लोगो की आवश्यकता है"
            },
            icon: "टोकन लोगो (200 * 200 pixels, supporting jpg, jpeg, png, image फ़ाइल नामकरण प्रारूप का समर्थन: टोकन नाम, लोगो को गोल कोनों की आवश्यकता नहीं है)",
            handleText: "हम आपके अनुरोध को 2 कार्यदिवसों में संसाधित करेंगे"
        },
        RECRUITING: {
            title: "TP Man भर्ती योजना",
            text: "टोकनपॉकेट समुदाय में शामिल हों",
            text1: "ब्लॉकचेन की दुनिया के लिए प्रतिबद्ध और अपना योगदान दें",
            text2: "हम एक साथ एक Web3.0 दुनिया का निर्माण करते हैं",
            joinUs: "हमसे जुड़ें",
            aboutTitle: "TP Man के बारे में",
            aboutText: "TP Man टोकनपॉकेट समुदाय का एक महत्वपूर्ण हिस्सा है, और हम ईमानदारी से आपको हमसे जुड़ने के लिए आमंत्रित करते हैं!",
            aboutText1: "आप एक ब्लॉकचेन उत्साही हैं और उद्योग मूल्य का समर्थन करते हैं।",
            aboutText2: "जब आप ब्लॉकचेन की दुनिया का पता लगाते हैं तो टोकनपॉकेट वॉलेट द्वारा लाई गई सुविधा का आनंद लें।",
            missionTitle: "TP Man का मिशन",
            missionText: "दुनिया भर में अधिक ब्लॉकचेन उपयोगकर्ताओं की सेवा करने के लिए टोकनपॉकेट की मदद करें। हम आशा करते हैं कि आप आवेदन करने के लिए निम्नलिखित में से दो आवश्यकताओं को पूरा करेंगे।",
            missionText1: "विभिन्न चैनलों के माध्यम से अपने देश में कंपनियों या हॉट प्रोजेक्ट्स के साथ टोकनपॉकेट सहयोग का विस्तार और प्रचार करें",
            missionText2: "स्थानीय उपयोगकर्ताओं की जरूरतों को पूरा करने वाली मार्केटिंग गतिविधियों की योजना बनाएं",
            missionText3: "मुख्यधारा के सोशल मीडिया जैसे ट्विटर, यूट्यूब, टेलीग्राम और डिस्कॉर्ड को संचालित करने की क्षमता रखते हैं",
            missionText4: "अंग्रेजी में धाराप्रवाह, और अनुवाद कार्य पूरा करने में सक्षम हो",
            missionText5: "टोकनपॉकेट वैश्विक ब्लॉकचेन उपयोगकर्ताओं के लिए अधिक उपयोग और तकनीकी सहायता प्रदान करने की योजना बना रहा है, इसलिए हम आशा करते हैं कि आपको कम से कम एक देश और उनके उपयोगकर्ताओं (भारत, संयुक्त राज्य अमेरिका, तुर्की, रूस, दक्षिण कोरिया) के ब्लॉकचेन बाजारों की एक निश्चित समझ है। वियतनाम, फिलीपींस, आदि)",
            getTitle: "आपको क्या मिलेगा?",
            getText: "ब्लॉकचैन उद्योग के विभिन्न क्षेत्रों में सीधे तौर पर शामिल एक कार्य अनुभव, और आपको उद्योग में DApp परियोजनाओं, इन्फ्लुएंसर और मुख्यधारा के मीडिया के साथ संचार के अवसर मिलेंगे, लेकिन सीमित नहीं होंगे।",
            getText1: "अपने काम से समृद्ध पुरस्कार प्राप्त करें जैसे कि ट्वीट अनुवाद, वीडियो बनाना, सामुदायिक संचालन और व्यावसायिक सहयोग।",
            getText2: "सबसे अधिक पेशेवर ब्लॉकचेन ज्ञान प्रशिक्षण प्राप्त करें और टीम के साथ मिलकर Web3.0 दुनिया का अन्वेषण करें।",
            getText3: "टोकनपॉकेट आधिकारिक लाभ, जिसमें टोकनपॉकेट स्वैग और हार्डवेयर वॉलेट शामिल हैं।",
            processTitle: "भर्ती प्रक्रिया",
            processText: "CV जमा करें",
            processText1: "CV जाँच",
            processText2: "ऑनलाइन साक्षात्कार ",
            processText3: "साक्षात्कार के परिणाम",
            processText4: "नाव पर स्वागत है",
            applyTitle: "कौन आवेदन कर सकता है",
            applyText: "देश की परवाह किए बिना दुनिया का सामना करें",
            applyText1: "ब्लॉकचेन की दुनिया के बारे में उत्सुक और उत्सुक रहें",
            applyText2: "फॉर्म भरें और अपना बायोडाटा संलग्न करें, फिर हम आपसे जल्द से जल्द संपर्क करेंगे",
            footerTitle: "टोकनपॉकेट के बारे में",
            footerText: "ऊपर",
            footerText1_1: "20M",
            footerText1_2: "वैश्विक उपयोगकर्ता",
            footerText2_1: "3.5M",
            footerText2_2: "मासिक सक्रिय उपयोगकर्ता",
            footerText3_1: "200",
            footerText3_2: "देश और क्षेत्र",
            footerText4: "टोकनपॉकेट दुनिया का अग्रणी मल्टी-चेन सेल्फ-कस्टोडियल वॉलेट है",
            footerText5: "जल्द आ रहा है"
        },
        NAVIGATION: {
            product: {
                title: "प्रोडक्ट",
                selfCustodyWallet: "मोबाइल वॉलेट",
                selfCustodyWalletDesc: "ब्लॉकचेन पर क्रिप्टो और डेफी मोबाइल वॉलेट।",
                hardwareWallet: "हार्डवेयर वॉलेट",
                hardwareWalletDesc: "अपनी संपत्ति की रक्षा के लिए अपना KeyPal प्राप्त करें.",
                extensionWallet: "एक्सटेंशन वॉलेट",
                extensionWalletDesc: "आपके कंप्यूटर पर एक बेहतर वॉलेट।",
                transit: "ट्रांजिट",
                transitDesc: "मल्टी-चेन DEX एग्रीगेटर और NFT मार्केटप्लेस प्लेटफॉर्म।",
                fiveDegrees: "5Degrees",
                fiveDegreesDesc: "Web3.0 में सोशल नेटवर्क प्रोटोकॉल।"
            },
            assets: {
                title: "संपत्ति"
            },
            collaborations: {
                title: "सहयोग"
            },
            community: {
                title: "समुदाय",
                developers: "डेवलपर्स",
                recruiting: "TP Man"
            },
            helpCenter: {
                title: "सेवा"
            }
        },
        ABOUT: {
            title: "हमारे बारे में",
            desc: "टोकनपॉकेट एक बहु-श्रृंखला विकेन्द्रीकृत वॉलेट है, यह उपयोगकर्ताओं को मोबाइल वॉलेट, एक्सटेंशन वॉलेट और हार्डवेयर वॉलेट प्रदान करता है, Bitcoin, Ethereum, BNB Smart Chain, TRON, Polygon, Solana, Aptos, Polkadot, EOS और सभी EVM संगत चेन सहित सार्वजनिक श्रृंखलाओं का समर्थन करता है। . 200 से अधिक देशों और क्षेत्रों के 20 मिलियन से अधिक उपयोगकर्ताओं को सेवा प्रदान करना। यह एक विश्वव्यापी अग्रणी क्रिप्टो वॉलेट है जिस पर वैश्विक उपयोगकर्ताओं द्वारा भरोसा किया जाता है।",
            philosophy: {
                title: "हमारा दर्शन",
                desc: "हम एक खुले प्रौद्योगिकी समुदाय पर जोर देते हैं, और हम सभी डेवलपर्स का एक साथ अधिक सुविधाजनक, सुरक्षित और समृद्ध ब्लॉकचेन दुनिया बनाने के लिए स्वागत करते हैं",
                ambition: "महत्वाकांक्षा",
                ambition_desc: "ब्लॉकचेन को हर जगह बनाएं",
                value: "मूल्य",
                value_desc: "उपयोगकर्ताओं को डेटा वापस करने दें, मूल्य को वास्तविक स्वामियों का बनाएं",
                attitude: "मनोभाव",
                attitude_desc: "खुले विचारों वाला, आपसी सहयोग"
            },
            milestones: {
                title: "उपलब्धि",
                desc_2018_1: "टोकनपॉकेट की स्थापना",
                desc_2018_2: "हुओबी, होफन, बाइट कैपिटल द्वारा निवेश किया गया",
                desc_2019_1: "जारी किया गया डेस्कटॉप वॉलेट, समर्थित TRON",
                desc_2019_2: "गूगल प्ले डाउनलोड 1,000,000 से अधिक हो गया",
                desc_2020_1: "HD वॉलेट समर्थित",
                desc_2020_2: "समर्थित BSC और DeFi प्रवृत्ति",
                desc_2020_3: "समर्थित Eth2.0 स्टेकिंग",
                desc_2021_1: "इनक्यूबेटेड ट्रांजिट",
                desc_2021_2: "उपयोगकर्ता आधार 20,000,000 से अधिक हो गया",
                desc_2021_3: "इनक्यूबेटेड कीपाल हार्डवेयर वॉलेट",
                desc_2022_1: "dFox का अधिग्रहण किया और टोकन पॉकेट एक्सटेंशन में पुनः ब्रांडेड किया गया",
                January: "जनवरी",
                February: "फ़रवरी",
                March: "मार्च",
                April: "अप्रैल",
                May: "मई",
                June: "जून",
                July: "जुलाई",
                August: "अगस्त",
                September: "सितंबर",
                October: "अक्टूबर",
                November: "नवंबर",
                December: "दिसंबर"
            },
            contact_us: {
                title: "संपर्क करें",
                service: "ग्राहक सेवा",
                service_desc: "service@tokenpocket.pro",
                bd: "व्यापार सहयोग",
                bd_desc: "bd@tokenpocket.pro",
                developers: "डेवलपर्स",
                developers_desc: "Discord link"
            }
        },
        SEO: {
            title: "टोकनपॉकेट - आपका सार्वभौमिक डिजिटल वॉलेट | TP वॉलेट - ETH वॉलेट - BTC वॉलेट - BSC वॉलेट - HECO वॉलेट - OKExChain वॉलेट - Polkadot वॉलेट - Kusama वॉलेट - DeFi वॉलेट - Layer 2 वॉलेट - EOS वॉलेट - TRX वॉलेट",
            description: "TokenPocket एक विश्व-अग्रणी डिजिटल मुद्रा वॉलेट है, जो BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS और Layer 2 सहित सार्वजनिक ब्लॉकचेन का समर्थन करता है।",
            keywords: "TokenPocket,Token Pocket,TP wallet,Ethereum wallet,Bitcoin,EOS,IOST,COSMOS,heco,bsc,layer2,DeFi,wallet,crypto,blockchain,web3"
        }

};
