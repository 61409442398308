import { createI18n } from 'vue-i18n'
import zh from "./zh"
import en from "./en"
import zhTw from "./zhTw"
import es from "./es"
import fil from "./fil"
import hi from "./hi"
import ja from "./ja"
import ko from "./ko"
import pt from "./pt"
import ru from "./ru"
import th from "./th"
import vi from "./vi"

//vue3 i18n配置 https://www.jianshu.com/p/6468db64ab0b
// 这是获取浏览器的语言
const messagesList = {
  "zh": zh,   //简体中文
  "zhTw": zhTw, //繁体中文
  "en": en,   //英文
  "fil": fil,
  "hi": hi,
  "es": es,
  "ja": ja,   //日
  "ko": ko,  //韩
  "pt": pt,
  "ru": ru,
  "th": th,
  "vi": vi
}

const language = 'zh';
// 准备翻译的语言环境信息
const i18n = createI18n({
  // 首先从缓存里拿，没有的话就用浏览器语言，
  locale: sessionStorage.getItem('lang') || language,
  fallbackLocale: 'zh', // 设置备用语
  messages: messagesList
});

export default i18n
