export default {
    demo: {
        demo: 'demo',
    },
    COMMON: {
        EMAIL: "Email",
        BATCH_SENDER: "BatchSender",
        YES: "Có",
        NO: "Không",
        HAS: "Có",
        HAVENT: "Không",
        BLOCKCHAIN: "Blockchain",
        MULTIPLE_CHOICE: "(Nhiều lựa chọn)",
        IS_SUPPORT_TP_CONNECT: "Có hỗ trợ kết nối TokenPocket không? (Ứng dụng mở rộng và ứng dụng dành cho thiết bị di động)",
        SUPPORT_BOTH: "Cả hai đều được hỗ trợ",
        SUPPORT_EXTENSION: "Chỉ ứng dụng mở rộng",
        SUPPORT_MOBILE: "Chỉ ứng dụng dành cho thiết bị di động",
        SUPPORT_NONE: "Không có",
        blockchainWallet: "Ví Blockchain",
        iostWallet: "Ví IOST",
        tronWallet: "Ví TRON",
        platformInfo: {
            eos: "EOS",
            moac: "MOAC",
            eth: "ETH",
            jt: "Jingtum ",
            enu: "ENU",
            bos: "BOS",
            iost: "IOST",
            cosmos: "COSMOS",
            bnb: "BNB",
            trx: "TRON",
            bsc: "BSC",
            heco: "HECO",
            okex: "OKExChain",
            dot: "Polkadot",
            hsc: "HSC",
            matic: "Polygon",
            klaytn: "Klaytn",
            arb: "Arbitrum One",
            arbn: "Arbirtum Nova",
            ftm: "Fantom",
            etc: "Ethereum Classic",
            cfx: "Conflux eSpace",
            solana: "Solana",
            avax: "AVAX",
            oe: "Optimistic Ethereum",
            xdai: "Gnosis Chain",
            harmony: "Harmony",
            wax: "WAX",
            aurora: "Aurora",
            ksm: "Kusama",
            mb: "Moonbeam",
            sbg: "SubGame",
            kcc: "KCC Mainnet",
            lat: "PlatON",
            bttc: "BTTC",
            gt: "GateChain",
            halo: "HALO",
            aptos: "Aptos"
        },
        submit: "Nộp",
        symbol: "Tên Token",
        bl_symbol: "Biểu tượng BL",
        precision: "Độ chính xác của Token",
        decimal: "Số thập phân của Token",
        totalSupply: "Tổng cung",
        contract: "Hợp đồng Token",
        website: "Trang web chính thức",
        introduction: "Giới thiệu Token",
        example: "Ví dụ",
        submitTokenInfoAndLogo: "Cập nhật biểu tượng Token và các Thông tin khác",
        toGithubSubmit: "Đi tới Github",
        nftType: "Tiêu chuẩn giao thức NFT",
        LAYOUT: {
            features: "Tính năng",
            buyCrypto: "Mua tiền điện tử",
            mobileWallet: "Ví di động",
            hardwareWallet: "Ví phần cứng",
            extensionWallet: "Ví mở rộng",
            desktop: "Ví trên máy tính để bàn",
            fiveDegrees: "5Degrees",
            approvalDetector: "Approval Detector",
            tokenSecurity: "Bảo mật Token",
            keyGenerator: "Tạo khóa",
            information: "Thông tin",
            blockchainGuide: "Hướng dẫn về Blockchain",
            tronWallet: "Hướng dẫn về TRON",
            iostWallet: "Hướng dẫn về IOST",
            tpMan: "TP Man",
            developers: "Nhà phát triển",
            github: "Github (TP-Labs)",
            devCenter: "Trung tâm phát triển",
            subToken: "Gửi Token",
            subDApp: "Gửi DApp",
            subNFT: "Gửi NFT",
            subChain: "Gửi chuỗi",
            company: "Công ty",
            about: "Giới thiệu",
            careers: "Nghề nghiệp",
            pressKit: "Press Kit",
            swagShop: "Swag Shop",
            support: "Ủng hộ",
            helpCenter: "Trung tâm trợ giúp",
            contactUs: "Liên hệ với chúng tôi",
            legal: "Hợp pháp",
            privacyPolicy: "Chính sách bảo mật",
            terms: "Điều khoản Sử dụng",
            toHome: "Trang chủ",
            defiWallet: "Ví DeFi",
            ETHWallet: "Ví Ethereum",
            ethWallet: "Ví ETH"
        }
    },
    HOME: {
        download: "Tải xuống",
        downloadNow: "Tải ngay",
        HEADER: {
            title: "Ví tiền điện tử an toàn của bạn giúp khám phá blockchain",
            desc_1: "Dễ dàng và an toàn để mua, lưu trữ, gửi, hoán đổi token và thu thập NFT. Được tin dùng bởi hơn 20 triệu người dùng từ hơn 200 quốc gia và khu vực."
        },
        INTRODUCTION: {
            title: "TokenPocket được người dùng toàn cầu tin cậy",
            desc_1: "Chúng tôi đang cung cấp dịch vụ ví tiền điện tử an toàn và dễ dàng tại hơn 200 quốc gia và khu vực trên thế giới",
            desc_2: "Phục vụ người dùng",
            desc_3: "Giao dịch hàng ngày",
            desc_4: "Hỗ trợ các quốc gia và khu vực"
        },
        SECURITY: {
            title: "Bảo mật đúng cấp độ",
            desc_1: "TokenPocket chỉ tạo và lưu trữ khóa và mật khẩu trên thiết bị của bạn, chỉ bạn mới có thể truy cập vào tài khoản và tài sản của mình.",
            desc_2: "TokenPocket cũng phát triển ví lạnh phần cứng và tính năng ví đa chữ ký để tăng cường bảo mật khi bạn cần.",
            desc_3: "Hỗ trợ BTC, ETH, BSC, TRON, Polygon, Solana, Cosmos, Polkadot, EOS, IOST, v.v."
        },
        EXCHANGE: {
            title: "Trao đổi & Giao dịch Dễ dàng",
            desc_1: "Bạn có thể giao dịch bằng tiền điện tử của mình mọi lúc, mọi nơi trong TokenPocket.",
            desc_2: "Mua tiền điện tử bằng thẻ tín dụng. Lưu trữ, gửi, chuỗi chéo và trao đổi một cách dễ dàng.",
            desc_3: "Tráo đổi",
            desc_4: "Tức thì và dễ dàng",
            desc_5: "Cầu",
            desc_6: "Giữa các chuỗi khác nhau",
            desc_7: "Mua tiền điện tử",
            desc_8: "Trong 5 phút"
        },
        DAPPSTORE: {
            title: "A DApp Store",
            desc_1: "Bạn có thể tìm thấy các ứng dụng phi tập trung yêu thích của mình, khám phá và sử dụng những ứng dụng mới nhất và hấp dẫn nhất mà không cần rời khỏi ví.",
            desc_2: "Trình duyệt DApp được tích hợp, bạn luôn có thể truy cập DApp bằng các liên kết của mình.",
            desc_3: "DeFi",
            desc_4: "NFT",
            desc_5: "DApp",
            desc_6: "Được hỗ trợ"
        },
        COMMUNITY: {
            title: "Cộng đồng",
            desc_1: "Chúng tôi quyết tâm tạo ra một cộng đồng công nghệ mở và hoan nghênh tất cả các nhà phát triển cùng nhau xây dựng một thế giới blockchain thuận tiện, an toàn và phong phú hơn",
            desc_2: "TP-Lab",
            desc_3: "Cộng đồng",
            desc_4: "Tài liệu phát triển"
        },
        DOWNLOAD: {
            title: "Nhận Ví TokenPocket ngay bây giờ!",
            desc_1: "Ví tiền điện tử an toàn và uy tín của bạn giúp khám phá blockchain"
        },
        FOLLOW: {
            title: "Theo chúng tôi",
            desc1: "Nhân viên TokenPocket sẽ không gửi tin nhắn riêng tư cho bạn!",
            desc2: "Thận trọng! Bạn đang tham gia cộng đồng TokenPocket, có thể có ai đó mạo danh chúng tôi để gửi tin nhắn riêng tư cho bạn! Xin lưu ý rằng bất kỳ ai gửi tin nhắn riêng tư đều có thể là kẻ lừa đảo! Chúng tôi sẽ không bao giờ liên hệ với bạn trước!",
            desc3: "Đã hiểu, nhập"
        },
        EXTENSIONMODAL: {
            title: "Ứng dụng mở rộng hiện đã hoạt động!",
            desc1: "Crypto & DeFi & GameFi của bạn",
            desc2: "ví trên máy tính",
            btnText: "Sử dụng ngay",
            btnTextm: "Sao chép link",
            tips: "Copy link thành công, vui lòng vào máy tính để mở"
        }
    },
    DAPP: {
        RULES: {
            platform: "Bắt buộc phải có platfrom",
            title: "Bắt buộc phải có tên DApp",
            address: "Bắt buộc phải có hợp đồng DApp",
            url: "Bắt buộc phải có trang web DApp",
            desc: "Bắt buộc phải có mô tả",
            icon_url: "Bắt buộc phải có logo DApp",
            rakeBackAccount: "Bắt buộc phải có tài khoản hợp đồng",
            email: "Bắt buộc phải có email",
            others: "Bắt buộc phải có thông tin liên hệ khác",
            tp_connect: "Mục này không được để trống"
        },
        title: "Tên DApp",
        address: "Hợp đồng Thông minh",
        url: "Trang web DApp",
        desc: "Mô tả ngắn",
        icon: "Logo DApp (Phải có kích thước 200x200 - hỗ trợ JPG, PNG.)",
        referral: "Giới thiệu",
        hasReferral: "Có hệ thống giới thiệu không",
        referralReward: "Phát phần thưởng giới thiệu",
        reward_1: "Tự động phát theo hợp đồng thông minh (Trực tiếp)",
        reward_2: "Cần phải nhận phần thưởng trên DApp theo cách thủ công",
        hasInviteReward: "Người mời có cần thực hiện giao dịch trong DApp để kích hoạt link giới thiệu không",
        inviteAccount: "Hợp đồng thông minh về chuyển lời mời giới thiệu",
        DAppRequirement: "Yêu cầu DApp",
        requirement_1: "1. DApp cần phải hỗ trợ ứng dụngTokenPocket trên thiết bị di động và ứng dụng TokenPocket mở rộng.",
        requirement_2: "2. Trang web được cung cấp có thể truy cập được và ổn định.",
        requirement_3: "3. Các hợp đồng thông minh đã được triển khai trên mạng chính và phần nhạy cảm phải là mã nguồn mở.",
        requirement_4: "4. Các hợp đồng nhạy cảm yêu cầu phải có báo cáo kiểm tra từ các cơ quan an ninh bên thứ ba",
        requirement_5: "5. Logic tương tác rõ ràng và đã được điều chỉnh cho phù hợp với giao diện người dùng di động.",
        requirement_6: "6. Tuân thủ luật định có liên quan, không gian lận và vi phạm.",
        requirement_7: "7. Nếu bạn vi phạm các luật định liên quan, bạn sẽ phải tự chịu trách nhiệm pháp lý tương ứng.",
        dappInfo: "Thông tin DApp:",
        necessary: "yêu cầu",
        language: "Ngôn ngữ DApp",
        languageDesc: "(Vui lòng gửi riêng nếu có nhiều ngôn ngữ)",
        en: "English",
        zh: "中文简体",
        zhTW: "中文繁体",
        descDesc: "Mô tả ngắn gọn về dự án trong một câu, câu này sẽ xuất hiện trong phụ đề DApp",
        auditInfo: "Thông tin kiểm tra:",
        hasAudit: "Việc kiểm tra hợp đồng đã được thực hiện hay chưa",
        auditUrl: "Url báo cáo kiểm tra",
        auditUrlExample: "Ví dụ: https://auditlink.com",
        auditReport: "Báo cáo kiểm tra",
        auditUpload: "Tải lên",
        contact: "Thông tin liên hệ",
        contactDesc: "Hãy chắc chắn để lại thông tin liên hệ cho bộ phận dịch vụ khách hàng ngoài hộp thư, nếu không sẽ không được xem xét",
        emailAddr: "Email",
        emailExample: "Ví dụ: service@yourdomain.com",
        others: "Khác",
        othersExample: "Ví dụ: Telegram：@123456789",
        auditOptional: "Điền vào ít nhất một mục thông tin kiểm tra",
        oversize: "đã vượt quá số ký tự đủ điều kiện",
        select: "Lựa chọn",
        tutorial_url: "https://help.tokenpocket.pro/developer-en/",
        tutorial: "Xem hướng dẫn >>"
    },
    DOWNLOAD: {
        TITLE: "Tải xuống TokenPocket",
        TITLE_Android: "TokenPocket dành cho Android",
        TITLE_IOS: "TokenPocket dành cho iOS",
        TITLE_Chrome: "TokenPocket dành cho trình duyệt của bạn",
        TEXT: "TokenPocket là một ví tiền điện tử đa chuỗi, dễ sử dụng và an toàn, được hàng triệu người tin tưởng.",
        TEXT_Chrome: "TokenPocket mở rộng là một ví tiền điện tử đa chuỗi, tất cả các chuỗi tương thích EVM đều được hỗ trợ. Sử dụng dễ dàng và an toàn, được hàng triệu người tin cậy.",
        TEXT_PC: "TokenPocket Desktop là ví đa chuỗi lớn nhất dựa trên nền tảng EOS ETH BOS TRON, chúng tôi cố gắng cung cấp khả năng quản lý tài sản số mạnh mẽ và an toàn cho người dùng.",
        scanCode: "Quét để tải xuống",
        installTutorial: "Hướng dẫn cài đặt",
        desc_1: "Tải xuống ứng dụng từ trang web chính thức và kiểm tra chứng nhận SSL của ứng dụng",
        desc_2: "Bảo vệ Recovery Phrase (mnemonic) và Private key của bạn khỏi bị rò rỉ, không bao giờ chia sẻ với người khác",
        desc_3: "Tìm hiểu thêm các mẹo bảo mật",
        verifyText: "Phiên bản Android mới nhất:",
        verifyText1: "Cách xác minh tính bảo mật của ứng dụng",
        verifyText2: "Phiên bản mới nhất:",
        verifyText3: "Phiên bản Google Play mới nhất:",
        footerTitle: "Tạo ấn tượng ban đầu tuyệt vời",
        footerDesc_1: "Hỗ trợ BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST, v.v.",
        footerDesc_2: "Bảo vệ an ninh nhiều lớp",
        footerDesc_3: "Hỗ trợ DeFi, DApp, GameFi và NFT",
        coming_soon: "Coming Soon",
        desc_tp_wallet: "TP Global Ltd đã chính thức được chỉ định là nhà xuất bản Ứng dụng iOS duy nhất của TokenPocket",
        tp_wallet_version: "TP Wallet Phiên bản:",
        token_pocket_version: "Token Pocket Phiên bản:",
        delisted: "Đã hủy niêm yết"
    },
    TOKEN: {
        RULES: {
            email: "Bắt buộc phải có email",
            address: "Bắt buộc phải có địa chỉ",
            owner_address: "Bắt buộc phải có địa chỉ chủ sở hữu",
            symbol: "Bắt buộc phải có biểu tượng",
            bl_symbol: "Bắt buộc phải có biểu tượng BL",
            total_supply: "Bắt buộc phải có tổng cung",
            decimal: "Bắt buộc phải có dấu thập phân",
            precision: "Bắt buộc phải có độ chính xác",
            gas: "Bắt buộc phải có GAS",
            website: "Bắt buộc phải có trang web",
            companyName: "Bắt buộc phải có tên công ty hoặc tên cá nhân",
            contact: "Bắt buộc phải có liên hệ",
            name: "Bắt buộc phải có giới thiệu về dự án",
            icon_url: "Bắt buộc phải có logo"
        },
        icon: "Logo của token (200*200 pixel, hỗ trợ jpg, jpeg, png, định dạng đặt tên tệp hình ảnh: tên token, logo không cần bo góc tròn)",
        handleText: "Chúng tôi sẽ xử lý yêu cầu của bạn trong 2 ngày làm việc"
    },
    RECRUITING: {
        title: "TP Man Recruitment Plan",
        text: "Join the TokenPocket community",
        text1: "Committed to the blockchain world and contribute your part",
        text2: "We build a Web3.0 world together",
        joinUs: "Join Us",
        aboutTitle: "About TP Man",
        aboutText: "TP Man is an important part of the TokenPocket community, and we sincerely invite you to join us!",
        aboutText1: "You are a blockchain enthusiast and endorse the industry value.",
        aboutText2: "Enjoy the convenience brought by TokenPocket wallet when you explore the blockchain world.",
        missionTitle: "The Mission of TP Man",
        missionText: "Help TokenPocket to serve more blockchain users around the world. We hope you, meet two of the following requirements to apply.",
        missionText1: "Expand and promote TokenPocket cooperation with companies or hot projects in your country through various channels",
        missionText2: "Plan marketing activities that meet the needs of local users",
        missionText3: "Have the ability to operate mainstream social medias such as Twitter, Youtube, Telegram, and Discord",
        missionText4: "Fluent in English, and be able to complete translation work",
        missionText5: "TokenPocket plans to provide more usage and technical support for global blockchain users, so we hope that you have a certain understanding of the blockchain markets of no less than one country and their users (India, the United States, Turkey, Russia, South Korea, Vietnam, the Philippines, etc.)",
        getTitle: "What will you get?",
        getText: "A work experience directly involved with various fields of the blockchain industry, and you will get but not limited to communication opportunities with DApp projects, Influencers, and mainstream media in the industry.",
        getText1: "Get rich rewards from your work such as tweets translation, making video, community operation and business cooperation.",
        getText2: "Get the most professional blockchain knowledge training and explore the Web3.0 world with the team together.",
        getText3: "TokenPocket official benefits, including TokenPocket Swag and hardware wallets.",
        processTitle: "Recruitment process",
        processText: "Submit CV",
        processText1: "CV screening",
        processText2: "Online interview ",
        processText3: "Interview results",
        processText4: "Welcome aboard",
        applyTitle: "Who can apply",
        applyText: "Face the world, regardless of country",
        applyText1: "Be keen and curious about the blockchain world",
        applyText2: "Fill out the form and attach your resume, then we will contact you as soon as possible",
        footerTitle: "About TokenPocket",
        footerText: "over",
        footerText1_1: "20M",
        footerText1_2: "global users",
        footerText2_1: "3.5M",
        footerText2_2: "monthly active users",
        footerText3_1: "200",
        footerText3_2: "countries and regions",
        footerText4: "TokenPocket is the world's leading multi-chain self-custodial wallet",
        footerText5: "Coming soon"
    },
    NAVIGATION: {
        product: {
            title: "Sản phẩm",
            selfCustodyWallet: "Ví di động",
            selfCustodyWalletDesc: "Ví di động Crypto & DeFi trên Blockchain.",
            hardwareWallet: "Ví phần cứng",
            hardwareWalletDesc: "Nhận KeyPal của bạn, để bảo vệ tài sản của bạn.",
            extensionWallet: "Ví mở rộng",
            extensionWalletDesc: "Một loại ví tốt hơn trên máy tính của bạn.",
            transit: "Chuyển tuyến",
            transitDesc: "Công cụ tổng hợp đa chuỗi DEX và Nền tảng thị trường NFT.",
            fiveDegrees: "5Degrees",
            fiveDegreesDesc: "Giao thức mạng xã hội trong Web3.0."
        },
        assets: {
            title: "Tài sản"
        },
        collaborations: {
            title: "hợp tác"
        },
        community: {
            title: "cộng đồng",
            developers: "nhà phát triển",
            recruiting: "TP Man"
        },
        helpCenter: {
            title: "Hỗ trợ"
        }
    },
    ABOUT: {
        title: "About Us",
        desc: "TokenPocket is a multi-chain decentralized wallet, it provides users with mobile wallet, extension wallet and hardware wallet, supporting public chains including Bitcoin, Ethereum, BNB Smart Chain, TRON, Aptos, Polygon, Solana, Polkadot, EOS and all EVM compatible chains. Serving over 20 millions users from more than 200 countries and regions. It is a world-wide leading crypto wallet that trusted by global users.",
        philosophy: {
            title: "Our philosophy",
            desc: "We insist on an open technology community, and we welcome all developers to build a more convenient, secure and richer blockchain world together",
            ambition: "Ambition",
            ambition_desc: "Make the blockchain happen everywhere",
            value: "Value",
            value_desc: "Let data return to users, make value belong to real owners",
            attitude: "Attitude",
            attitude_desc: "Open-minded, mutual collaboration"
        },
        milestones: {
            title: "Milestones",
            desc_2018_1: "TokenPocket Founded",
            desc_2018_2: "Invested by Huobi, Hofan, Byte Capital",
            desc_2019_1: "Released desktop wallet, supported TRON",
            desc_2019_2: "Google Play download exceeded 1,000,000",
            desc_2020_1: "HD wallet supported",
            desc_2020_2: "Supported BSC and DeFi tendency",
            desc_2020_3: "Supported Eth2.0 Staking",
            desc_2021_1: "Incubated Transit",
            desc_2021_2: "User base exceeded 20,000,000",
            desc_2021_3: "Incubated KeyPal hardware wallet",
            desc_2022_1: "Acquired dFox and rebranded to TokenPocket Extension",
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December"
        },
        contact_us: {
            title: "Contact Us",
            service: "Customer Service",
            service_desc: "service@tokenpocket.pro",
            bd: "Business Collaborations",
            bd_desc: "bd@tokenpocket.pro",
            developers: "Developers",
            developers_desc: "Discord"
        }
    },
    SEO: {
        title: "TokenPocket - Ví kĩ thuật số toàn cầu của bạn | Ví TP - Ví ETH - Ví BTC - Ví BSC - Ví HECO - Ví OKExChain - Ví Polkadot - Ví Kusama - Ví DeFi - Ví Layer 2 - Ví EOS - Ví TRX",
        description: "TokenPocket là ví kỹ thuật số hàng đầu thế giới, hỗ trợ các blockchain công khai bao gồm BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS và Layer 2.",
        keywords: "TokenPocket, Token Pocket, ví TP, ví Ethereum, Bitcoin, EOS, IOST, COSMOS, heco, bsc, layer2, DeFi, ví,wallet,crypto,blockchain,web3,NFT"
    }

};
