export default {
    demo: {
        demo: 'demo',
    },
    COMMON: {
        EMAIL: "Email",
        BATCH_SENDER: "Отправитель пакета",
        YES: "Да",
        NO: "Нет",
        HAS: "Да",
        HAVENT: "Нет",
        BLOCKCHAIN: "Блокчейн",
        MULTIPLE_CHOICE: "(Множественный выбор)",
        IS_SUPPORT_TP_CONNECT: "Поддерживает ли он подключение TokenPocket? (Расширение и мобильное приложение)",
        SUPPORT_BOTH: "Поддерживаются оба",
        SUPPORT_EXTENSION: "Только расширение",
        SUPPORT_MOBILE: "Только для мобильных устройств",
        SUPPORT_NONE: "Нет",
        blockchainWallet: "Блокчейн-кошелек",
        iostWallet: "Кошелек IOST",
        tronWallet: "Кошелек ТRОN",
        platformInfo: {
            eos: "EOS",
            moac: "MOAC",
            eth: "ETH",
            jt: "Jingtum ",
            enu: "ENU",
            bos: "BOS",
            iost: "IOST",
            cosmos: "COSMOS",
            bnb: "BNB",
            trx: "TRON",
            bsc: "BSC",
            heco: "HECO",
            okex: "OKExChain",
            dot: "Polkadot",
            hsc: "HSC",
            matic: "Polygon",
            klaytn: "Klaytn",
            arb: "Arbitrum One",
            arbn: "Arbirtum Nova",
            ftm: "Fantom",
            etc: "Ethereum Classic",
            cfx: "Conflux eSpace",
            solana: "Solana",
            avax: "AVAX",
            oe: "Optimistic Ethereum",
            xdai: "Gnosis Chain",
            harmony: "Harmony",
            wax: "WAX",
            aurora: "Aurora",
            ksm: "Kusama",
            mb: "Moonbeam",
            sbg: "SubGame",
            kcc: "KCC Mainnet",
            lat: "PlatON",
            bttc: "BTTC",
            gt: "GateChain",
            halo: "HALO",
            aptos: "Aptos"
        },
        submit: "Отправить",
        symbol: "Имя токена",
        bl_symbol: "BL Symbol",
        precision: "Точность токена",
        decimal: "Десятичный токен",
        totalSupply: "Общее предложение",
        contract: "Токен-контракт",
        website: "Официальный сайт",
        introduction: "Введение токена",
        example: "Пример",
        submitTokenInfoAndLogo: "Обновить логотип токена и другую информацию",
        toGithubSubmit: "Перейти на Github",
        nftType: "Стандарт протокола NFT",
        LAYOUT: {
            features: "Особенности",
            buyCrypto: "Купить криптовалюту",
            mobileWallet: "Мобильный кошелек",
            hardwareWallet: "Аппаратный кошелек",
            extensionWallet: "Расширение кошелька",
            desktop: "Настольный кошелек",
            fiveDegrees: "5Degrees",
            approvalDetector: "Детектор одобрения",
            tokenSecurity: "Безопасность токена",
            keyGenerator: "Генератор ключей",
            information: "Информация",
            blockchainGuide: "Руководство по блокчейну",
            tronWallet: "Руководство по TRON",
            iostWallet: "Руководство по IOST",
            tpMan: "ТП-мужчина",
            developers: "Разработчики",
            github: "Github (TP-Lab)",
            devCenter: "Центр разработки",
            subToken: "Отправить токен",
            subDApp: "Отправить DApp",
            subNFT: "Отправить NFT",
            subChain: "Цепь",
            company: "Компания",
            about: "О",
            careers: "Карьера",
            pressKit: "Пресс-кит",
            swagShop: "Магазин сувениров",
            support: "Поддержка",
            helpCenter: "Справочный центр",
            contactUs: "Свяжитесь с нами",
            legal: "Юридический",
            privacyPolicy: "Privacy Policy",
            terms: "Terms of Use",
            toHome: "Дом",
            defiWallet: "Кошелек DeFi",
            ETHWallet: "Кошелек Эфириума",
            ethWallet: "ETH-кошелек"
        }
    },
    HOME: {
        download: "Скачать",
        downloadNow: "Скачать сейчас",
        HEADER: {
            title: "Ваш безопасный криптокошелек для изучения блокчейна",
            desc_1: "Легко и безопасно покупать, хранить, отправлять, обменивать токены и собирать NFT. Нам доверяют более 20 миллионов пользователей из более чем 200 стран и регионов."
        },
        INTRODUCTION: {
            title: "TokenPocket доверяют пользователи со всего мира",
            desc_1: "Мы предоставляем безопасный и простой сервис криптовалютного кошелька в более чем 200 странах и регионах по всему миру",
            desc_2: "Обслуживание пользователей",
            desc_3: "Ежедневные транзакции",
            desc_4: "Поддерживаемые страны и регионы"
        },
        SECURITY: {
            title: "Безопасность, какой она должна быть",
            desc_1: "TokenPocket генерирует и хранит ключи и пароли только на вашем устройстве, только вы можете получить доступ к своей учетной записи и активам.",
            desc_2: "TokenPocket также разрабатывает аппаратный холодный кошелек и функцию кошелька с несколькими подписями для повышения безопасности по мере необходимости.",
            desc_3: "Поддержка BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, Cosmos, Polkadot, EOS, IOST и т. д."
        },
        EXCHANGE: {
            title: "Легкий обмен и транзакции",
            desc_1: "Вы можете торговать своей криптовалютой в любое время и в любом месте в TokenPocket.",
            desc_2: "Покупайте криптовалюту с помощью кредитных карт. С легкостью храните, отправляйте, пересекайте цепочки и обменивайте.",
            desc_3: "Swap",
            desc_4: "Мгновенно и легко",
            desc_5: "Bridge",
            desc_6: "Среди разных цепочек",
            desc_7: "Купить криптовалюту",
            desc_8: "Через 5 минут"
        },
        DAPPSTORE: {
            title: "Магазин децентрализованных приложений",
            desc_1: "Вы можете найти свои любимые децентрализованные приложения, открыть для себя последние и самые популярные и использовать их, не выходя из кошелька.",
            desc_2: "Браузер DApp интегрирован, вы всегда можете получить доступ к DApps по своим ссылкам.",
            desc_3: "DeFi",
            desc_4: "NFT",
            desc_5: "DApp",
            desc_6: "Supported"
        },
        COMMUNITY: {
            title: "Сообщество",
            desc_1: "Мы настаиваем на открытом технологическом сообществе и приглашаем всех разработчиков вместе создавать более удобный, безопасный и богатый блокчейн-мир",
            desc_2: "TP-Lab",
            desc_3: "Сообщество",
            desc_4: "Dev Docs"
        },
        DOWNLOAD: {
            title: "Получить кошелек TokenPocket прямо сейчас!",
            desc_1: "Ваш безопасный и надежный криптокошелек для изучения блокчейна"
        },
        FOLLOW: {
            title: "Подписывайтесь на нас",
            desc1: "Сотрудники TokenPocket не будут отправлять вам личные сообщения!",
            desc2: "Осторожность! Вы входите в сообщество TokenPocket, возможно, кто-то выдает себя за нас, чтобы отправлять вам личные сообщения! Имейте в виду, что любой, кто отправляет личные сообщения, может быть мошенником! Мы никогда не свяжемся с вами первыми!",
            desc3: "Понятно, вводите"
        },
        EXTENSIONMODAL: {
            title: "Расширение запущено!",
            desc1: "Ваша криптовалюта, DeFi и GameFi",
            desc2: "кошелек на компьютере",
            btnText: "Использовать сейчас",
            btnTextm: "Копировать ссылку",
            tips: "Скопируйте ссылку успешно, перейдите на компьютер, чтобы открыть"
        }
    },
    DAPP: {
        RULES: {
            platform: "Требуется платформа",
            title: "Требуется имя децентрализованного приложения",
            address: "Требуется контракт DApp",
            url: "Требуется веб-сайт DApp",
            desc: "Требуется описание",
            icon_url: "Требуется логотип децентрализованного приложения",
            rakeBackAccount: "Требуется контрактный аккаунт",
            email: "Требуется электронная почта",
            others: "Требуется другая контактная информация",
            tp_connect: "Этот элемент не может быть пустым"
        },
        title: "Имя децентрализованного приложения",
        address: "Смарт-контракт",
        url: "Веб-сайт децентрализованного приложения",
        desc: "Краткое описание",
        icon: "Логотип DApp (должен быть 200 x 200 – поддерживается формат JPG, PNG)",
        referral: "Реферал",
        hasReferral: "Есть ли реферальная система",
        referralReward: "Распределение реферального вознаграждения",
        reward_1: "Автоматически распределять по смарт-контракту (Live)",
        reward_2: "Необходимо запросить его в DApp вручную",
        hasInviteReward: "Нужно ли приглашающему совершить транзакцию в DApp, чтобы активировать реферальную ссылку",
        inviteAccount: "Смарт-контракт распределения рефералов",
        DAppRequirement: "Требование DApp",
        requirement_1: "1. DApp должен поддерживать мобильное приложение TokenPocket и расширение TokenPocket.",
        requirement_2: "2. Предоставленный веб-сайт доступен и стабилен.",
        requirement_3: "3. Смарт-контракты развернуты в основной сети, а конфиденциальная часть должна быть с открытым исходным кодом.",
        requirement_4: "4. Конфиденциальные контракты требуют аудиторских отчетов от сторонних агентств безопасности.",
        requirement_5: "5. Логика взаимодействия понятна и адаптирована к мобильному интерфейсу.",
        requirement_6: "6. Соблюдайте соответствующие законы и постановления без мошенничества и нарушений.",
        requirement_7: "7. Если вы нарушите соответствующие законы и постановления, вы добровольно возьмете на себя соответствующую юридическую ответственность.",
        dappInfo: "Информация о децентрализованном приложении:",
        necessary: "требуется",
        language: "Язык DApp",
        languageDesc: "(Пожалуйста, отправьте отдельно для нескольких языков)",
        en: "English",
        zh: "中文简体",
        zhTW: "中文繁体",
        descDesc: "Краткое описание проекта в одном предложении, которое появится в подзаголовке DApp",
        auditInfo: "Информация аудита:",
        hasAudit: "Проведен ли аудит договора",
        auditUrl: "URL отчета об аудите",
        auditUrlExample: "Например: https://auditlink.com",
        auditReport: "Аудиторский отчет",
        auditUpload: "Загрузить",
        contact: "Контактная информация",
        contactDesc: "Пожалуйста, не забудьте оставить контактную информацию службы поддержки, кроме почтового ящика, иначе она не пройдет проверку",
        emailAddr: "Электронная почта",
        emailExample: "Например: service@yourdomain.com",
        others: "Другое",
        othersExample: "Например: Telegram：@123456789",
        auditOptional: "Заполните хотя бы один пункт информации об аудите",
        oversize: "превышено допустимое количество символов",
        select: "Выбрать",
        tutorial_url: "https://help.tokenpocket.pro/developer-ru/",
        tutorial: "Просмотреть учебник>>"
    },
    DOWNLOAD: {
        TITLE: "Скачать TokenPocket",
        TITLE_Android: "TokenPocket для Android",
        TITLE_IOS: "TokenPocket для iOS",
        TITLE_Chrome: "TokenPocket для вашего браузера",
        TEXT: "TokenPocket — это простой и безопасный крипто-кошелек с несколькими цепочками, которому доверяют миллионы",
        TEXT_Chrome: "Расширение TokenPocket — это крипто-кошелек с несколькими цепочками, поддерживающий все цепочки, совместимые с EVM. Простое и безопасное использование, которому доверяют миллионы.",
        TEXT_PC: "TokenPocket Desktop — это крупнейший мультиблокчейн-кошелек на основе EOS ETH BOS TRON, мы стремимся предоставить пользователям мощное и безопасное управление цифровыми активами.",
        scanCode: "Сканировать для загрузки",
        installTutorial: "Учебник по установке",
        desc_1: "Скачайте приложение с официального сайта и проверьте его сертификат SSL",
        desc_2: "Защитите свою фразу восстановления (мнемонику) и закрытый ключ от утечки, никогда не передавайте их другим",
        desc_3: "Узнайте больше советов по безопасности",
        verifyText: "Последняя версия APK:",
        verifyText1: "Как проверить безопасность приложения",
        verifyText2: "Последняя версия:",
        verifyText3: "Последняя версия Google Play:",
        footerTitle: "Производите отличное первое впечатление",
        footerDesc_1: "Поддержка BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST и т. д.",
        footerDesc_2: "Многоуровневая защита",
        footerDesc_3: "Поддерживаются DeFi, DApp, GameFi и NFT",
        coming_soon: "Coming Soon",
        desc_tp_wallet: "TP Global Ltd официально назначена единственным издателем iOS-приложения TokenPocket",
        tp_wallet_version: "TP Wallet Версия:",
        token_pocket_version: "Token Pocket Версия:",
        delisted: "Исключен из списка"
    },
    TOKEN: {
        RULES: {
            email: "Требуется электронная почта",
            address: "Требуется адрес",
            owner_address: "Требуется адрес владельца",
            "символ": "Требуется символ",
            bl_symbol: "Требуется символ BL",
            total_supply: "Требуется общий запас",
            decimal: "Требуется десятичный",
            precision: "Требуется точность",
            gas: "Требуется ГАЗ",
            website: "Требуется веб-сайт",
            companyName: "Требуется название компании или имя",
            contact: "Требуется контакт",
            name: "Требуется представление проекта",
            icon_url: "Требуется логотип"
        },
        icon: "Логотип токена (200 * 200 пикселей, поддержка jpg, jpeg, png, формат именования файла изображения: имя токена, логотипу не нужны закругленные углы)",
        handleText: "Мы обработаем ваш запрос в течение 2 рабочих дней"
    },
    RECRUITING: {
        title: "План найма TP Man",
        text: "Присоединяйтесь к сообществу TokenPocket",
        text1: "Приверженность миру блокчейна и внесите свой вклад",
        text2: "Вместе мы строим мир Web3.0",
        joinUs: "Присоединяйтесь к нам",
        aboutTitle: "О ТП Мэн",
        aboutText: "TP Man — важная часть сообщества TokenPocket, и мы искренне приглашаем вас присоединиться к нам!",
        aboutText1: "Вы энтузиаст блокчейна и поддерживаете ценность отрасли.",
        aboutText2: "Наслаждайтесь удобством кошелька TokenPocket при изучении мира блокчейна.",
        missionTitle: "Миссия TP Man",
        missionText: "Помогите TokenPocket обслуживать больше пользователей блокчейна по всему миру. Мы надеемся, что вы соответствуете двум из следующих требований, чтобы подать заявку",
        missionText1: "Расширяйте и продвигайте сотрудничество TokenPocket с компаниями или популярными проектами в вашей стране по различным каналам.",
        missionText2: "Планируйте маркетинговые мероприятия, отвечающие потребностям местных пользователей",
        missionText3: "Иметь возможность работать с основными социальными сетями, такими как Twitter, Youtube, Telegram и Discord.",
        missionText4: "Свободное владение английским языком и умение выполнять переводческие работы",
        missionText5: "TokenPocket планирует обеспечить более широкое использование и техническую поддержку для глобальных пользователей блокчейна, поэтому мы надеемся, что у вас есть определенное понимание рынков блокчейнов не менее чем в одной стране и их пользователей (Индия, США, Турция, Россия, Южная Корея, Вьетнам, Филиппины и др.)",
        getTitle: "Что вы получите?",
        getText: "Опыт работы, непосредственно связанный с различными областями индустрии блокчейнов, и вы получите, помимо прочего, возможности общения с проектами DApp, влиятельными лицами и основными СМИ в отрасли..",
        getText1: "Получайте щедрые вознаграждения за свою работу, такую ​​как перевод твитов, создание видео, работа в сообществе и деловое сотрудничество..",
        getText2: "Получите самое профессиональное обучение по блокчейну и исследуйте мир Web3.0 вместе с командой.",
        getText3: "Официальные преимущества TokenPocket, включая TokenPocket Swag и аппаратные кошельки.",
        processTitle: "Процесс набора",
        processText: "Отправить резюме",
        processText1: "проверка резюме",
        processText2: "Онлайн-интервью ",
        processText3: "Результаты интервью",
        processText4: "Добро пожаловать на борт",
        applyTitle: "Кто может подать заявку",
        applyText: "Лицом к миру, независимо от страны",
        applyText1: "Будьте увлечены и любопытны в мире блокчейна",
        applyText2: "Заполните форму и прикрепите свое резюме, тогда мы свяжемся с вами в ближайшее время",
        footerTitle: "О TokenPocket",
        footerText: "над",
        footerText1_1: "20M",
        footerText1_2: "глобальные пользователи",
        footerText2_1: "3.5M",
        footerText2_2: "ежемесячно активных пользователей",
        footerText3_1: "200",
        footerText3_2: "страны и регионы",
        footerText4: "TokenPocket — ведущий в мире мультичейн-кошелек с самостоятельным хранением.",
        footerText5: "Вскоре"
    },
    NAVIGATION: {
        product: {
            title: "Товар",
            selfCustodyWallet: "Мобильный кошелек",
            selfCustodyWalletDesc: "Мобильный кошелек Crypto&DeFi на блокчейне.",
            hardwareWallet: "Аппаратный кошелек",
            hardwareWalletDesc: "Получите свой KeyPal, чтобы защитить свои активы.",
            extensionWallet: "Расширение кошелька",
            extensionWalletDesc: "Лучший кошелек на вашем компьютере.",
            transit: "Transit",
            transitDesc: "Многоцепочечный агрегатор DEX и торговая площадка NFT.",
            fiveDegrees: "5Degrees",
            fiveDegreesDesc: "Протокол социальной сети в Web3.0."
        },
        assets: {
            title: "Ресурсы"
        },
        collaborations: {
            title: "Сотрудничество"
        },
        community: {
            title: "Сообщество",
            developers: "Разработчик",
            recruiting: "ТП-мужчина"
        },
        helpCenter: {
            title: "Помощь"
        }
    },
    ABOUT: {
        title: "About Us",
        desc: "TokenPocket is a multi-chain decentralized wallet, it provides users with mobile wallet, extension wallet and hardware wallet, supporting public chains including Bitcoin, Ethereum, BNB Smart Chain, TRON, Aptos, Polygon, Solana, Polkadot, EOS and all EVM compatible chains. Serving over 20 millions users from more than 200 countries and regions. It is a world-wide leading crypto wallet that trusted by global users.",
        philosophy: {
            title: "Our philosophy",
            desc: "We insist on an open technology community, and we welcome all developers to build a more convenient, secure and richer blockchain world together",
            ambition: "Ambition",
            ambition_desc: "Make the blockchain happen everywhere",
            value: "Value",
            value_desc: "Let data return to users, make value belong to real owners",
            attitude: "Attitude",
            attitude_desc: "Open-minded, mutual collaboration"
        },
        milestones: {
            title: "Milestones",
            desc_2018_1: "TokenPocket Founded",
            desc_2018_2: "Invested by Huobi, Hofan, Byte Capital",
            desc_2019_1: "Released desktop wallet, supported TRON",
            desc_2019_2: "Google Play download exceeded 1,000,000",
            desc_2020_1: "HD wallet supported",
            desc_2020_2: "Supported BSC and DeFi tendency",
            desc_2020_3: "Supported Eth2.0 Staking",
            desc_2021_1: "Incubated Transit",
            desc_2021_2: "User base exceeded 20,000,000",
            desc_2021_3: "Incubated KeyPal hardware wallet",
            desc_2022_1: "Acquired dFox and rebranded to TokenPocket Extension",
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December"
        },
        contact_us: {
            title: "Contact Us",
            service: "Customer Service",
            service_desc: "service@tokenpocket.pro",
            bd: "Business Collaborations",
            bd_desc: "bd@tokenpocket.pro",
            developers: "Developers",
            developers_desc: "Discord"
        }
    },
    SEO: {
        title: "TokenPocket - Ваш универсальный цифровой кошелек | Кошелек TP - Кошелек ETH - Кошелек BTC - Кошелек BSC - Кошелек HECO - Кошелек OKExChain - Кошелек Polkadot - Кошелек Kusama - Кошелек DeFi - Кошелек Layer 2 - Кошелек EOS - Кошелек TRX",
        description: "TokenPocket — это ведущий в мире кошелек цифровой валюты, поддерживающий общедоступные блокчейны, включая BTC, ETH, BSC, HECO, TRON, OKExChain, Polkadot, Kusama, EOS и Layer 2.",
        keywords: "TokenPocket, Token Pocket, кошелек TP, кошелек Ethereum, биткойн, EOS, IOST, COSMOS, heco, bsc, layer2, DeFi, кошелек,крипто,блокчейн,web3"
    }

};
