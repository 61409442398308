
export default {
    demo: {
        demo: 'demo',
    },

    COMMON: {
        EMAIL: "Email",
        BATCH_SENDER: "BatchSender",
        YES: "Yes",
        NO: "No",
        HAS: "Yes",
        HAVENT: "No",
        BLOCKCHAIN: "Blockchain",
        MULTIPLE_CHOICE: "(Multiple choice)",
        IS_SUPPORT_TP_CONNECT: "Does it support TokenPocket connection? (Extension and Mobile app)",
        SUPPORT_BOTH: "Both are supported",
        SUPPORT_EXTENSION: "Only extension",
        SUPPORT_MOBILE: "Only mobile",
        SUPPORT_NONE: "None",
        blockchainWallet: "Blockchain Wallet",
        iostWallet: "IOST Wallet",
        tronWallet: "TRON Wallet",
        platformInfo: {
            eos: "EOS",
            moac: "MOAC",
            eth: "ETH",
            jt: "Jingtum ",
            enu: "ENU",
            bos: "BOS",
            iost: "IOST",
            cosmos: "COSMOS",
            bnb: "BNB",
            trx: "TRON",
            bsc: "BSC",
            heco: "HECO",
            okex: "OKExChain",
            dot: "Polkadot",
            hsc: "HSC",
            matic: "Polygon",
            klaytn: "Klaytn",
            arb: "Arbitrum One",
            arbn: "Arbirtum Nova",
            ftm: "Fantom",
            etc: "Ethereum Classic",
            cfx: "Conflux eSpace",
            solana: "Solana",
            avax: "AVAX",
            oe: "Optimistic Ethereum",
            xdai: "Gnosis Chain",
            harmony: "Harmony",
            wax: "WAX",
            aurora: "Aurora",
            ksm: "Kusama",
            mb: "Moonbeam",
            sbg: "SubGame",
            kcc: "KCC Mainnet",
            lat: "PlatON",
            bttc: "BTTC",
            gt: "GateChain",
            halo: "HALO",
            aptos: "Aptos"
        },
        submit: "Submit",
        symbol: "Token Name",
        bl_symbol: "BL Symbol",
        precision: "Token Precision",
        decimal: "Token Decimal",
        totalSupply: "Total Supply",
        contract: "Token Contract",
        website: "Official Website",
        introduction: "Token Introduction",
        example: "Example",
        submitTokenInfoAndLogo: "Update token logo and other Info",
        toGithubSubmit: "Go to Github",
        nftType: "NFT protocol standard",
        LAYOUT: {
            features: "Features",
            buyCrypto: "Buy Crypto",
            mobileWallet: "Mobile Wallet",
            hardwareWallet: "Hardware Wallet",
            extensionWallet: "Extension Wallet",
            desktop: "Desktop Wallet",
            fiveDegrees: "5Degrees",
            approvalDetector: "Approval Detector",
            tokenSecurity: "Token Security",
            keyGenerator: "Key Generator",
            information: "Information",
            blockchainGuide: "Blockchain Guide",
            tronWallet: "TRON Guide",
            iostWallet: "IOST Guide",
            tpMan: "TP Man",
            developers: "Developers",
            github: "Github (TP-Lab)",
            devCenter: "Dev Center",
            subToken: "Submit Token",
            subDApp: "Submit DApp",
            subNFT: "Submit NFT",
            subChain: "Submit Chain",
            company: "Company",
            about: "About",
            careers: "Careers",
            pressKit: "Press Kit",
            swagShop: "Swag Shop",
            support: "Support",
            helpCenter: "Help Center",
            contactUs: "Contact Us",
            legal: "Legal",
            privacyPolicy: "Privacy Policy",
            terms: "Terms of Use",
            toHome: "Home",
            defiWallet: "DeFi Wallet",
            ETHWallet: "Ethereum Wallet",
            ethWallet: "ETH Wallet"
        }
    },
    HOME: {
        download: "Download",
        downloadNow: "Download Now",
        HEADER: {
            title: "Your secure crypto wallet to explore blockchain",
            desc_1: "Easy and safe to buy, store, send, swap tokens and collect NFTs. Trusted by 20+ millions users from 200+ countries and regions."
        },
        INTRODUCTION: {
            title: "TokenPocket is trusted by global users",
            desc_1: "We are providing secure and easy crypto wallet service among 200+ countries and regions around the world",
            desc_2: "Serving users",
            desc_3: "Daily Transactions",
            desc_4: "Supporting countries and regions"
        },
        SECURITY: {
            title: "Security as it should be",
            desc_1: "TokenPocket generates and stores keys and passwords on your device only, only you can access your account and assets.",
            desc_2: "TokenPocket also develops hardware cold wallet and MultiSig wallet feature to enhance the security as you need.",
            desc_3: "Supporting BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, Cosmos, Polkadot, EOS, IOST and so on."
        },
        EXCHANGE: {
            title: "Exchange & Transact Easily",
            desc_1: "You can trade your crypto anytime, anywhere within TokenPocket.",
            desc_2: "Buy crypto with credit cards. Store, send, cross chain and exchange with ease.",
            desc_3: "Swap",
            desc_4: "Instantly and easily",
            desc_5: "Bridge",
            desc_6: "Among different chains",
            desc_7: "Buy Crypto",
            desc_8: "In 5 minutes"
        },
        DAPPSTORE: {
            title: "A DApp Store",
            desc_1: "You can find your favorite decentralized applications, discover the latest and hottest ones and use them without leaving the wallet.",
            desc_2: "DApp Browser integrated, you can always access the DApps with your links.",
            desc_3: "DeFi",
            desc_4: "NFT",
            desc_5: "DApp",
            desc_6: "Supported"
        },
        COMMUNITY: {
            title: "Community",
            desc_1: "We insist on an open technology community, and we welcome all developers to build a more convenient, secure and richer blockchain world together",
            desc_2: "TP-Lab",
            desc_3: "Community",
            desc_4: "Dev Docs"
        },
        DOWNLOAD: {
            title: "Get the TokenPocket Wallet now!",
            desc_1: "Your secure and trusted crypto wallet to explore blockchain"
        },
        FOLLOW: {
            title: "Follow Us",
            desc1: "TokenPocket Staffs won't send you private messages!",
            desc2: "Caution! You're entering TokenPocket community, there might be someone impersonating us to send you private messages! Please be aware that, anyone who sends private messages might be a scammer! We will never contact you first!",
            desc3: "Understood, enter"
        },
        EXTENSIONMODAL: {
            title: "Extension is now live!",
            desc1: "Your Crypto & DeFi & GameFi",
            desc2: "wallet on computer",
            btnText: "Use It Now",
            btnTextm: "Copy Link",
            tips: "Copy the link successfully, please go to the computer to open"
        }
    },
    DAPP: {
        RULES: {
            platform: "Platform is required",
            title: "DApp Name is required",
            address: "DApp Contract is required",
            url: "DApp Website is required",
            desc: "Desc is required",
            icon_url: "DApp Logo is required",
            rakeBackAccount: "Contract account is required",
            email: "Email is required",
            others: "Other contact information is required",
            tp_connect: "This item cannot be blank"
        },
        title: "DApp Name",
        address: "Smart Contract",
        url: "DApp Website",
        desc: "Short Description",
        icon: "DApp Logo (Must be 200x200 - supports JPG, PNG.)",
        referral: "Referral",
        hasReferral: "Does it have a referral system",
        referralReward: "The distribution of the referral reward",
        reward_1: "Automatically distribute by the smart contract (Live)",
        reward_2: "Need to claim it on the DApp Manually",
        hasInviteReward: "Does the inviter need to make a transaction in the DApp to activate the referral link",
        inviteAccount: "The smart contract of referral distribution",
        DAppRequirement: "DApp Requirement",
        requirement_1: "1. DApp needs to support TokenPocket mobile and TokenPocket extension.",
        requirement_2: "2. The provided website is accessible and stable.",
        requirement_3: "3. The smart contracts have deployed on the mainnet, and the sensitive part requires to be open source.",
        requirement_4: "4. The sensitive contracts require audit reports from third-party security agencies.",
        requirement_5: "5. The interaction logic is clear and has been adapted to the mobile UI.",
        requirement_6: "6. Obey the relevant laws and regulations, without fraud and infringement.",
        requirement_7: "7. If you violate relevant laws and regulations, you will voluntarily assume corresponding legal responsibilities.",
        dappInfo: "DApp Information:",
        necessary: "required",
        language: "DApp language",
        languageDesc: "(Please submit separately for multiple languages)",
        en: "English",
        zh: "中文简体",
        zhTW: "中文繁体",
        descDesc: "A brief description of the project in one sentence, which will appear in the DApp subtitle",
        auditInfo: "Audit information:",
        hasAudit: "Whether the contract audit has been carried out",
        auditUrl: "Audit report url",
        auditUrlExample: "For example: https://auditlink.com",
        auditReport: "Audit Report",
        auditUpload: "Upload",
        contact: "Contact details",
        contactDesc: "Please be sure to leave the customer service contact information other than the mailbox, otherwise it will not pass the review",
        emailAddr: "Email",
        emailExample: "For example: service@yourdomain.com",
        others: "Other",
        othersExample: "For example: Telegram：@123456789",
        auditOptional: "Fill in at least one item of audit information",
        oversize: " has exceeded the qualified characters",
        select: "Select",
        tutorial_url: "https://help.tokenpocket.pro/developer-en/",
        tutorial: "View tutorial>>"
    },
    DOWNLOAD: {
        TITLE: "Download TokenPocket",
        TITLE_Android: "TokenPocket for Android",
        TITLE_IOS: "TokenPocket for iOS",
        TITLE_Chrome: "TokenPocket for your browser",
        TEXT: "TokenPocket is a multi-chain crypto wallet, easy and secure to use that trusted by millions.",
        TEXT_Chrome: "TokenPocket Extension is a multi-chain crypto wallet, all EVM compatible chain supported. Easy and secure to use that trusted by millions.",
        TEXT_PC: "TokenPocket Desktop is the largest multi-blockchain wallet based EOS ETH BOS TRON, we strive to provide a powerful and secure digital asset management to users.",
        scanCode: "Scan to Download",
        installTutorial: "Install Tutorial",
        desc_1: "Download the app from the official website and check its SSL certification",
        desc_2: "Protect your Recovery Phrase (mnemonic) and Private Key from leaking, never share it to others",
        desc_3: "Learn more security tips",
        verifyText: "Latest APK Version:",
        verifyText1: "How to verify your app's security",
        verifyText2: "Latest Version:",
        verifyText3: "Latest Google Play Version:",
        footerTitle: "Make a great first impression",
        footerDesc_1: "Supporting BTC, ETH, BSC, TRON, Matic, Aptos, Solana, EOS, Polkadot, IOST and so on.",
        footerDesc_2: "Multi-layer security protections",
        footerDesc_3: "DeFi, DApp, GameFi and NFT supported",
        coming_soon: "Coming Soon",
        desc_tp_wallet: "TP Global Ltd has been officially appointed as the sole iOS App publisher of TokenPocket",
        tp_wallet_version: "TP Wallet Version:",
        token_pocket_version: "Token Pocket Version:",
        delisted: "Delisted"
    },
    TOKEN: {
        RULES: {
            email: "Email is required",
            address: "Address is required",
            owner_address: "Owner address is required",
            symbol: "Symbol is required",
            bl_symbol: "BL Symbol is required",
            total_supply: "Total supply is required",
            decimal: "Decimal is required",
            precision: "Precision is required",
            gas: "GAS is required",
            website: "Website is required",
            companyName: "Company or personal name is required",
            contact: "Contact is required",
            name: "Project introduction is required",
            icon_url: "Logo is required"
        },
        icon: "Token Logo (200 * 200 pixels, supporting jpg, jpeg, png, image file naming format: token name, logo does not need rounded corners)",
        handleText: "We will process your request in 2 workdays"
    },
    RECRUITING: {
        title: "TP Man Recruitment Plan",
        text: "Join the TokenPocket community",
        text1: "Committed to the blockchain world and contribute your part",
        text2: "We build a Web3.0 world together",
        joinUs: "Join Us",
        aboutTitle: "About TP Man",
        aboutText: "TP Man is an important part of the TokenPocket community, and we sincerely invite you to join us!",
        aboutText1: "You are a blockchain enthusiast and endorse the industry value.",
        aboutText2: "Enjoy the convenience brought by TokenPocket wallet when you explore the blockchain world.",
        missionTitle: "The Mission of TP Man",
        missionText: "Help TokenPocket to serve more blockchain users around the world. We hope you, meet two of the following requirements to apply.",
        missionText1: "Expand and promote TokenPocket cooperation with companies or hot projects in your country through various channels",
        missionText2: "Plan marketing activities that meet the needs of local users",
        missionText3: "Have the ability to operate mainstream social medias such as Twitter, Youtube, Telegram, and Discord",
        missionText4: "Fluent in English, and be able to complete translation work",
        missionText5: "TokenPocket plans to provide more usage and technical support for global blockchain users, so we hope that you have a certain understanding of the blockchain markets of no less than one country and their users (India, the United States, Turkey, Russia, South Korea, Vietnam, the Philippines, etc.)",
        getTitle: "What will you get?",
        getText: "A work experience directly involved with various fields of the blockchain industry, and you will get but not limited to communication opportunities with DApp projects, Influencers, and mainstream media in the industry.",
        getText1: "Get rich rewards from your work such as tweets translation, making video, community operation and business cooperation.",
        getText2: "Get the most professional blockchain knowledge training and explore the Web3.0 world with the team together.",
        getText3: "TokenPocket official benefits, including TokenPocket Swag and hardware wallets.",
        processTitle: "Recruitment process",
        processText: "Submit CV",
        processText1: "CV screening",
        processText2: "Online interview ",
        processText3: "Interview results",
        processText4: "Welcome aboard",
        applyTitle: "Who can apply",
        applyText: "Face the world, regardless of country",
        applyText1: "Be keen and curious about the blockchain world",
        applyText2: "Fill out the form and attach your resume, then we will contact you as soon as possible",
        footerTitle: "About TokenPocket",
        footerText: "over",
        footerText1_1: "20M",
        footerText1_2: "global users",
        footerText2_1: "3.5M",
        footerText2_2: "monthly active users",
        footerText3_1: "200",
        footerText3_2: "countries and regions",
        footerText4: "TokenPocket is the world's leading multi-chain self-custodial wallet",
        footerText5: "Coming soon"
    },
    NAVIGATION: {
        product: {
            title: "Product",
            selfCustodyWallet: "Mobile Wallet",
            selfCustodyWalletDesc: "Crypto&DeFi Mobile Wallet on Blockchain.",
            hardwareWallet: "Hardware Wallet",
            hardwareWalletDesc: "Get Your KeyPal, to Guard Your Assets.",
            extensionWallet: "Extension Wallet",
            extensionWalletDesc: "A Better Wallet on Your Computer.",
            transit: "Transit",
            transitDesc: "Multi-chain DEX Aggregator and NFT Marketplace Platform.",
            fiveDegrees: "5Degrees",
            fiveDegreesDesc: "Social Network Protocol in Web3.0."
        },
        assets: {
            title: "Assets"
        },
        collaborations: {
            title: "Collaborations"
        },
        community: {
            title: "Community",
            developers: "Developers",
            recruiting: "TP Man"
        },
        helpCenter: {
            title: "Help"
        }
    },
    ABOUT: {
        title: "About Us",
        desc: "TokenPocket is a multi-chain decentralized wallet, it provides users with mobile wallet, extension wallet and hardware wallet, supporting public chains including Bitcoin, Ethereum, BNB Smart Chain, TRON, Aptos, Polygon, Solana, Polkadot, EOS and all EVM compatible chains. Serving over 20 millions users from more than 200 countries and regions. It is a world-wide leading crypto wallet that trusted by global users.",
        philosophy: {
            title: "Our philosophy",
            desc: "We insist on an open technology community, and we welcome all developers to build a more convenient, secure and richer blockchain world together",
            ambition: "Ambition",
            ambition_desc: "Make the blockchain happen everywhere",
            value: "Value",
            value_desc: "Let data return to users, make value belong to real owners",
            attitude: "Attitude",
            attitude_desc: "Open-minded, mutual collaboration"
        },
        milestones: {
            title: "Milestones",
            desc_2018_1: "TokenPocket Founded",
            desc_2018_2: "Invested by Huobi, Hofan, Byte Capital",
            desc_2019_1: "Released desktop wallet, supported TRON",
            desc_2019_2: "Google Play download exceeded 1,000,000",
            desc_2020_1: "HD wallet supported",
            desc_2020_2: "Supported BSC and DeFi tendency",
            desc_2020_3: "Supported Eth2.0 Staking",
            desc_2021_1: "Incubated Transit",
            desc_2021_2: "User base exceeded 20,000,000",
            desc_2021_3: "Incubated KeyPal hardware wallet",
            desc_2022_1: "Acquired dFox and rebranded to TokenPocket Extension",
            January: "January",
            February: "February",
            March: "March",
            April: "April",
            May: "May",
            June: "June",
            July: "July",
            August: "August",
            September: "September",
            October: "October",
            November: "November",
            December: "December"
        },
        contact_us: {
            title: "Contact Us",
            service: "Customer Service",
            service_desc: "service@tokenpocket.pro",
            bd: "Business Collaborations",
            bd_desc: "bd@tokenpocket.pro",
            developers: "Developers",
            developers_desc: "Discord"
        }
    },
    SEO: {
        title: "TokenPocket - Your secure crypto & DeFi Wallet | TP wallet - ETH wallet - BTC wallet - BSC wallet - HECO wallet - OKXChain wallet - Web3 Wallet - Crypto Wallet - blockchain wallet - Polkadot wallet - Kusama wallet - DeFi wallet - Layer 2 wallet - Solana Wallet - EOS wallet - TRX wallet",
        description: "TokenPocket is a world-leading crypto wallet, supporting public blockchains including BTC, ETH, BSC, TRON, Aptos, Polygon, Solana, OKExChain, Polkadot, Kusama, EOS and Layer2.",
        keywords: "TokenPocket,Token Pocket,TP wallet,Ethereum wallet,Bitcoin,EOS,IOST,COSMOS,heco,bsc,layer2,DeFi,wallet,crypto,blockchain,web3,NFT"
    }

};